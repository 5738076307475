import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Formik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import multiMonthPlugin from "@fullcalendar/multimonth";
import {
  createRecord,
  fetchList,
  updateRecord,
} from "../../apis/services/CommonApiService";
import { ApiEndPoints } from "../../apis/ApiEndPoints";
import Loader from "../../layout/Loader";
import dayGridPlugin from "@fullcalendar/daygrid";

import { toast } from "react-toastify";
import DialogForm from "../../shared/components/DialogForm";
import { ImportBookingValidator } from "../../shared/validations/AdminValidator";
import SyncIcon from "@mui/icons-material/Sync";
import { fetchHotelDetail } from "../../apis/services/HotelApiService";
import ConfirmDialog from "../../shared/components/ConfirmDialog";
import { useDispatch } from "react-redux";
import { getLoadingFailed, getLoadingSuccess } from "../../Reducer/authSlice";
import FormikSelect from "../../shared/material-ui-formik/FormikSelect";
import ShowInputError from "../../shared/components/ShowInputError";
import allLocales from "@fullcalendar/core/locales-all";

const label = { inputProps: { "aria-label": "Size switch demo" } };

const OverView = (props) => {
  const { t } = useTranslation();
  // const [initialDate, setInitialDate] = useState(moment().format("YYYY-MM-DD"));
  const calendarRef = useRef(null);

  const {
    arg,
    setArg,
    loading,
    setLoading,
    handleGetAllBookingRate,
    eventss,
    resourcess,
    perMonth,
    calenderViewChange,
    calenderView,
    initialDate,
    roomRateId,
    roomRateCate,
    handleChangeRoomRate
  } = props;

  const [editedEvent, setEditedEvent] = useState(null);
  const [editedEventTitle, setEditedEventTitle] = useState("");
  const [editedEventStart, setEditedEventStart] = useState("");
  const [editedEventData, setEditedEventData] = useState([]);
  const [openDayRateChange, setOpenDayRateChange] = useState(false);
  const [openWebRezPro, setOpenWebRezPro] = useState(false);
  const [ocupancyRate, setOcupancyRate] = useState({});
  const [adultRate, setAdultRate] = useState({});
  const [childRate, setChildRate] = useState({});

  const [directBookingEngine, setDirectBookingEngine] = useState(0);
  const hotelId = JSON.parse(localStorage.getItem("userDetails"));
  // console.log(hotelId?.hotel_id, 64);

  const [openConfirmEngine, setConfirmEngin] = useState(false);

  const handleDirectBookingEngine = (e) => {
    setConfirmEngin(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmEngin(false);
  };

  const handleConfirmEngineAction = async () => {
    const result = await updateRecord(
      { booking_engine_activated: directBookingEngine === 0 ? 1 : 0 },
      hotelId?.hotel_id,
      ApiEndPoints.UPDATE_DIRECT_BOOKING_ENGINE
    );

    if (result?.status === 200) {
      toast.success(t(result.message));
      getHotelDetail(hotelId?.hotel_id);
      handleCloseConfirmDialog();
    } else {
      toast.error(t(result.message));
    }
  };

  const getHotelDetail = useCallback(async (hotelId) => {
    const result = await fetchHotelDetail(hotelId);
    if (result?.status === 200) {
      setDirectBookingEngine(result?.data?.booking_engine_activated);
    } else {
      setDirectBookingEngine(0);
    }
  }, []);

  useEffect(() => {
    getHotelDetail(hotelId?.hotel_id);
  }, [hotelId?.hotel_id, getHotelDetail]);

  // console.log(eventss, resourcess, 47);

  const handleEventClick = async (info) => {
    // console.log(info?.event?.extendedProps?.type, 55);

    if (
      info?.event?.title === "" ||
      info?.event?.extendedProps?.type === "availability"
    ) {
      return;
    }
    setEditedEvent(info.event?._def?.resourceIds[0]);
    setEditedEventTitle(info.event.title);

    const result = await fetchList(
      ApiEndPoints.GET_RATES_PER_PARTICULER_DATE +
        `?date=${moment(info.event.start).format("YYYY-MM-DD")}&category_id=${
          info.event?._def?.resourceIds[0]
        }&seasonal_rate_id=${info.event._def?.extendedProps?.sessionId}`
    );
    if (result?.status === 200) {
      setEditedEventData(result?.data);
      result?.data?.forEach((item) => {
        if (item?.accomodation_id === 1) {
          setOcupancyRate({ id: item?.id, rate: item?.rate });
        } else if (item?.accomodation_id === 2) {
          setAdultRate({ id: item?.id, rate: item?.rate });
        } else if (item?.accomodation_id === 3) {
          setChildRate({ id: item?.id, rate: item?.rate });
        }
      });
    } else {
      setEditedEventData([]);
      toast.error(t(result.message));
    }
    setEditedEventStart(moment(info.event.start).format("YYYY-MM-DD"));
    setOpenDayRateChange(true);
  };

  // const handleRateChange = (e,id) =>{

  //   // for(let i in editedEventData){
  //   //   if(editedEvent[i]?.accomodation_id === id){
  //       setEditedEventData((item)=>(item.accomodation_id === id)?({...item, rate: e.target.value }):item)
  //       // console.log([...editedEventData, {...editedEventData[i], rate: e.target.value}], 75)
  //     // }
  //   }

  // console.log(ocupancyRate, adultRate, childRate, 78)

  // console.log(eventss.filter((item)=>item?.start === "2023-10-19"), 47)

  const handleSaveEvent = async () => {
    if (editedEvent) {
      // const updatedEvents = eventss.map((event) =>
      //   ((event.resourceId === +editedEvent) && (editedEventStart === event.start))
      //     ? { ...event, title: (Number(editedEventTitle)).toFixed(2) }
      //     : event
      // );
      // setreservationEventss(updatedEvents);
      // console.log(updatedEvents?.filter((item)=>item?.start === "2023-10-19"))
      setEditedEvent(null);
      setOpenDayRateChange(false);

      const result = await createRecord(
        { pricing: [ocupancyRate, adultRate, childRate] },
        ApiEndPoints.UPDATE_RATES_PERTICULAR_DATE
      );
      if (result?.status === 200) {
        toast.success(t(result.message));
        setOcupancyRate({});
        setAdultRate({});
        setChildRate({});
        handleGetAllBookingRate(arg);
        // setEditedEventData({});
      } else {
        toast.error(t(result.message));
      }
    }
  };

  const handleCancelEdit = () => {
    // setEditedEvent(null);
    setOpenDayRateChange(false);
    setOcupancyRate({});
    setAdultRate({});
    setChildRate({});
  };

  const columns = [
    // {
    //   field: "title",
    //   headerContent: "Room",
    // },
    {
      field: "type",
      group: true,
      headerContent: t("Room Type"),
      cellContent: (args) => (t(args?.groupValue) || t(args?.resource?.extendedProps?.type))

    },
    {
      field: "rateType",
      headerContent: t("Type"),
      cellContent: (args) => (t(args?.resource?.extendedProps?.rateType))

    },
  ];

  const [roomRateIdData, setRoomRateIdData] = useState([]);
  const [roomLoading, setRoomLoading] = useState(false);

  const getRoomSeasonalRateList = useCallback(async () => {
    // console.log("Api Call");
    setRoomLoading(true);
  
    const result = await fetchList(
      ApiEndPoints.SEASONAL_ROOM_RATES_RESOURCE_ROUTE +
        `?page_no=${1}&items_per_page=${1000}`
    );
    if (result?.status === 200) {
      setRoomLoading(false);
      setRoomRateIdData(result.data.rows);
      
    } else {
      setRoomLoading(false);
      setRoomRateIdData([]);

    }
  }, []);

  useEffect(()=>{
    getRoomSeasonalRateList()
  },[getRoomSeasonalRateList]);

  // console.log(roomRateCate, 163);

  function renderEventContent(eventInfo) {
    // console.log(info?.event?.extendedProps?.type, 55)

    return (
      <div className="cale-event">
        {eventInfo?.event?.title && (
          <div
            className="d-flex justify-content-center w-90"
            style={{
              padding: "8px",
              cursor:
                eventInfo?.event?.extendedProps?.type === "availability"
                  ? "default"
                  : "pointer",
            }}
          >
            {/* {(editedEventStart===moment(eventInfo.event.start).format('YYYY-MM-DD') && editedEvent === eventInfo.event?._def?.resourceIds[0]) ? 
            (<div style={{width:"100%"}}><input style={{ color: "black",width:"100%", }} 
            onChange={(e)=>setEditedEventTitle(e.target.value)}
            value={editedEventTitle} /></div>) : */}
            <div style={{ color: "black", width: "100%", textAlign: "center" }}>
              {eventInfo.event.title}
            </div>
            {/*  } */}
          </div>
        )}
      </div>
    );
  }

  // useEffect(() => {
  //   // Check if the FullCalendar instance is available
  //   if (calendarRef.current) {
  //     // Get the current date
  //     const currentDateTime = new Date();

  //     // Navigate to the current date
  //     calendarRef.current.getApi().gotoDate(currentDateTime);
  //   }
  // }, []);

  const initialState_WebRezPro = {
    id_proof: null,
    season_id: 0,
  };

  const dispatch = useDispatch();

  const handleSubmit = async (values, resetForm) => {
    dispatch(getLoadingSuccess());
    const formData = new FormData();
    if (values.id_proof?.type === "text/csv") {
      formData.append("file", values.id_proof);
      formData.append("season_id", values.season_id);
      const result = await createRecord(
        formData,
        ApiEndPoints.UPLOAD_ROME_RATES
      );

      if (result?.status === 200) {
        // setLoading(true);
        toast.success(t(result.message));
        setOpenWebRezPro(false);
        dispatch(getLoadingFailed());
        handleGetAllBookingRate(arg);
        resetForm();
        calendarRef.current.getApi().removeAllEvents();
        calendarRef.current.getApi().addEventSource(eventss);

        // Refetch and render the updated events
        calendarRef.current.getApi().refetchEvents();
      } else {
        dispatch(getLoadingFailed());
        toast.error(t(result.message));
      }
    } else {
      toast.error(t("Please Upload CSV File Only"));
      dispatch(getLoadingFailed());
      resetForm();
    }
  };

  const renderError = (message) => (
    <p style={{ color: "red" }} className="help is-danger">
      {message}
    </p>
  );

  return (
    <div className="p-2" style={{ background: "#fff", width: "95%" }}>
      {/* <input style={{ color: "black",width:"100%" }} 
            onChange={(e)=>setEditedEventTitle(e.target.value)}
            value={editedEventTitle} /> */}
      {/* <div className="d-flex justify-content-between align-items-center"> */}

      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "space-between",
        }}
      >
        {" "}
        {/* <Typography>{t("Room Rates Overview")} </Typography>
         */}
         <Box>
         <Typography
              id={"font-600"}
              style={{
                marginBottom: "11px",
                color: "rgb(30, 30, 30)",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              {t("Select Rate")}
            </Typography>
         <FormControl sx={{ m: 0, marginRight: "10px" }} size="small">
         <InputLabel
                id="test-select-label"
                style={{
                  color: "#1E1E1E",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
              {t("Select Rate")}
                
              </InputLabel>
            <Select
              value={roomRateId}
              onChange={handleChangeRoomRate}
              sx={{ height: "34px", borderRadius: "12px", minWidth:"140px" }}
              // displayEmpty
              label={t("Select Rate")}
              // inputProps={{ "aria-label": "Without label" }}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label={t("Select Rate")}
                />
              }
            >
              <MenuItem disabled value={0}>Select Room Rate</MenuItem>
              {
                roomRateCate?.map((item)=>{
                  return (
                    <MenuItem key={item?.category_rate_type_id} value={item?.category_rate_type_id}>{item?.name}</MenuItem>
                  )
                })
              }
             
            </Select>
            {/* <FormHelperText>Without label</FormHelperText> */}
          </FormControl>
         </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
        >
          <Button
            variant="outlined"
            // className="m-1"
            sx={{
              border: "1px solid #BFBFBF",
              marginRight: "10px",
              padding: "7px",
              borderRadius: "12px",
            }}
            onClick={() => handleGetAllBookingRate(arg)}
          >
            <SyncIcon
              //  className="ml-1"
              sx={{ fontSize: "18px" }}
            />
          </Button>
          <Grid container spacing={2} sx={{ width: "fit-content" }}>
            <Grid item lg={12} md={12} style={{ marginRight: "10px" }}>
              <FormControl size="small">
                <TextField
                  size="small"
                  inputProps={{
                    style: {
                      padding: "3px",
                    },
                  }}
                  type="date"
                  sx={{
                    width: "fit-content",
                    input: { padding: "3px" },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px !important",
                    },
                  }}
                  value={initialDate}
                  onChange={(e) =>
                    calenderViewChange({
                      ...calenderView,
                      initialDate: moment(e?.target?.value).format(
                        "YYYY-MM-DD"
                      ),
                    })
                  }
                  onKeyDown={(e) => e.preventDefault()}
                />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl sx={{ m: 0, marginRight: "10px" }} size="small">
            <Select
              value={perMonth}
              onChange={(e) => calenderViewChange({ ...calenderView, perMonth: e.target.value })}
              sx={{ height: "34px", borderRadius: "12px" }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={7}>{t("For One Week")}</MenuItem>
              <MenuItem value={14}>{t("For Two weeks")}</MenuItem>
              <MenuItem value={31}>{t("For One Month")}</MenuItem>
              <MenuItem value={60}>{t("For Two Month")}</MenuItem>
              <MenuItem value={90}>{t("For Three Months")}</MenuItem>
            </Select>
            {/* <FormHelperText>Without label</FormHelperText> */}
          </FormControl>
          <Button
            size="large"
            variant="contained"
            disableElevation
            // type="submit"
            className="comman-button"
            color="primary"
            // style={{
            //   justifyContent: "center",
            //   width: "fit-content",
            //   height: "35px",
            //   fontSize: "15px",
            //   marginLeft: "auto",
            //   textTransform: "capitalize",
            //   background: "#1C3A66",
            //   borderRadius: "4px",
            // }}
            onClick={() => setOpenWebRezPro(true)}
          >
            {t("Import Room Rates")}
          </Button>
        </Box>
      </Box>
      {/* <div>
        <Button
        size="large"
        variant="contained"
        disableElevation
        // type="submit"
        color="primary"
        style={{
          justifyContent: "center",
          width: "fit-content",
          height: "34px",
          textTransform: "capitalize",
          background: "#1C3A66",
          borderRadius: "4px",
        }}
        onClick={()=>setValidRange((pre)=>({...pre, start: pre?.start===''?new Date():''}))}>{validRange?.start===''?'Today':'Past Days'}</Button>
    </div>
      </div> */}
      {/* <Grid container spacing={3} sx={{marginTop: "0px"}}>
        <Grid
          item
          lg={12}
          sm={12}
          xs={12}
          md={12}
          style={{
            // marginTop: "20px",
            alignItems: "end",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              style={{
                // marginBottom: "5px",
                color: "rgb(30, 30, 30)",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              {"Direct Booking Engine"}
            </Typography>
            <Switch
              {...label}
              checked={+directBookingEngine === 1}
              onChange={handleDirectBookingEngine}
            />
            <Typography>
              {+directBookingEngine === 1 ? "Active" : "Not Active"}
            </Typography>
          </Stack>
        </Grid>
      </Grid> */}

      <div className="mt-3">
        {(loading || roomLoading )&& <Loader />}
        <FullCalendar
          ref={calendarRef}
          initialDate={initialDate}
          key={initialDate}
          schedulerLicenseKey={"CC-Attribution-NonCommercial-NoDerivatives"} // Will need to purchase a license
          plugins={[
            interactionPlugin,
            resourceTimelinePlugin,
            multiMonthPlugin,
            dayGridPlugin,
          ]}
          eventClick={handleEventClick}
          // timeZone="UTC"
          locale={localStorage.getItem("lng") || "en"}
          locales={allLocales}

          events={eventss}
          eventContent={renderEventContent}
          resources={resourcess}
          resourceAreaColumns={columns}
          initialView="resourceTimelineWeek"
          views={{
            resourceTimelineWeek: {
              duration: { days: perMonth },
              slotDuration: "24:00:00",
              slotLabelFormat: [{ weekday: "short", day: "2-digit" }],
              titleFormat: { day: "2-digit", month: "short" },
            },
          }}
          slotLabelContent={(slotInfo) => {
            const dayOfWeek = slotInfo.date.toLocaleString(localStorage.getItem("lng") || "en", {
              weekday: "short",
            });
            const dayOfMonth = slotInfo.date.toLocaleString(localStorage.getItem("lng") || "en", {
              day: "numeric",
            });
            const month = slotInfo.date.toLocaleString(localStorage.getItem("lng") || "en", {
              month: "short",
            });

            return (
              <div style={{ lineHeight: "18px", paddingInline: "15px" }}>
                <div>{dayOfWeek}</div>
                <div>{dayOfMonth}</div>
                <div>{month}</div>
              </div>
            );
          }}
          datesSet={(argO) => {
            if (
              !(
                argO?.startStr === arg?.startStr &&
                argO?.endStr === arg?.endStr
              )
            ) {
              setArg(argO);
              calenderViewChange({
                ...calenderView,
                initialDate: moment(argO?.startStr).format("YYYY-MM-DD"),
              });
            }
          }}
          dragScroll
          resourceAreaWidth="25%"
          height={"auto"}
        />
      </div>
      <DialogForm
        scroll="paper"
        maxWidth="md"
        title={`${t("Update Rate for")} ${editedEventStart}`}
        openDialog={openDayRateChange}
        handleDialogClose={handleCancelEdit}
        bodyContent={
          <div className="p-2">
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item sm={4} md={4}>
                <Typography
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {t("Min Occupancy Rate")}
                </Typography>
                <TextField
                  onChange={(e) => {
                    setEditedEventTitle(e.target.value);
                    setOcupancyRate((item) => ({
                      ...item,
                      rate: e.target.value,
                    }));
                  }}
                  value={editedEventTitle}
                  variant="outlined"
                  type="number"
                  placeholder={t("Enter Amount")}
                />
              </Grid>
              <Grid item sm={4} md={4}>
                <Typography
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {t("Extra Adult")}
                </Typography>
                <TextField
                  onChange={(e) =>
                    setAdultRate((item) => ({ ...item, rate: e.target.value }))
                  }
                  value={adultRate?.rate}
                  variant="outlined"
                  type="number"
                  placeholder={t("Enter Amount")}
                />
              </Grid>
              <Grid item sm={4} md={4}>
                <Typography
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {t("Child Rate")}
                </Typography>
                <TextField
                  onChange={(e) =>
                    setChildRate((item) => ({ ...item, rate: e.target.value }))
                  }
                  value={childRate?.rate}
                  variant="outlined"
                  type="number"
                  placeholder={t("Enter Amount")}
                />
              </Grid>
              <Grid item sm={12} md={12}>
                <Button
                  className="mt-1 comman-button"
                  size="large"
                  variant="contained"
                  onClick={handleSaveEvent}
                  disableElevation
                  color="primary"
                >
                  {t("Submit")}
                </Button>
              </Grid>
            </Grid>
          </div>
        }
      />

      <DialogForm
        scroll="paper"
        maxWidth="lg"
        title={t("Import Rates From WebRezPro")}
        openDialog={openWebRezPro}
        handleDialogClose={() => setOpenWebRezPro(false)}
        bodyContent={
          <div className=" pl-2 pr-2 pb-2 mt-3" style={{ height: "auto" }}>
            <div className="tab-heading ">{t("Import Rates From WebRezPro")}</div>
            <Formik
              initialValues={initialState_WebRezPro}
              onSubmit={async (values, { resetForm }) =>
                handleSubmit(values, resetForm)
              }
              validateOnBlur={false}
              validateOnChange={true}
              enableReinitialize={true}
              validationSchema={()=>ImportBookingValidator(t)}
            >
              {(props) => {
                const { handleSubmit } = props;
                return (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={6}>
                        <Typography
                        id="font-600"
                          style={{
                            marginBottom: "11px",
                            color: "rgb(30, 30, 30)",
                            fontSize: "16px",
                          }}
                        >
                          {t("Season Rate")}
                        </Typography>
                        <Field
                          label={t("Rate Plan ID")}
                          placeholder={t("Rate Plan ID")}
                          name={`season_id`}
                          id={`season_id`}
                          component={FormikSelect}
                          defaultOption
                          options={roomRateIdData?.map((data) => ({
                            title: data?.name,
                            value: data?.id,
                          }))}
                        />
                        <ErrorMessage
                          name={`season_id`}
                          component={ShowInputError}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={6} sx={{ paddingX: 2 }}>
                        <Typography  id="font-600">{t("Upload Room Rates (Csv File)")} *</Typography>
                        <FormControl
                          fullWidth
                          //   className={controlClassName}
                          //   sx={sx}
                        >
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="id_proof"
                            type="file"
                            onChange={(event) => {
                              props.setFieldValue(
                                "id_proof",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                          <ErrorMessage name="id_proof" render={renderError} />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginRight: "20px",
                        marginBottom: "20px",
                        borderTop: "2px solid",
                        marginTop: "20px",
                      }}
                    >
                      <Grid item xs={2}>
                        <Button
                          className="mt-1 comman-button"
                          size="large"
                          variant="contained"
                          type="submit"
                          color="primary"
                          // style={{
                          //   justifyContent: "center",
                          //   width: "98px",
                          //   height: "44px",
                          //   textTransform: "capitalize",
                          //   background:
                          //     "linear-gradient(180deg, #255480 0%, #173450 100%)",
                          // }}
                        >
                          {t("Save")}
                        </Button>
                      </Grid>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        }
      />

      {/* Confirm engine */}

      <ConfirmDialog
        scroll="paper"
        maxWidth="md"
        title={t("Confirm The Action")}
        message={t(
          `${t("Do you really want to")} ${
            directBookingEngine === 0 ? t("Activate") : t("Deactivate")
          } ${("Direct Booking Engine?")}`
        )}
        cancelButtonText={t("Cancel")}
        confirmButtonText={
          directBookingEngine === 0 ? t("Activate") : t("Deactivate")
        }
        openDialog={openConfirmEngine}
        handleDialogClose={handleCloseConfirmDialog}
        handleDialogAction={handleConfirmEngineAction}
      />
    </div>
  );
};

export default OverView;

// {
//   /* <Formik>
//         <Grid container spacing={2} alignItems="end">
//             <Grid item lg={2}>
//               <Field
//                 label=""
//                 type="email"
//                 name="userName"
//                 id="userName"
//                 placeHolder="Search by guest, email, room or reservation ID"
//                 maxLength={150}
//                 component={FormikInput1}
//               />
//             </Grid>
//             <Grid item lg={2}>
//               <Field
//                 label="Meal Price"
//                 type="email"
//                 name="userName"
//                 id="userName"
//                 placeHolder="Search by guest, email, room or reservation ID"
//                 maxLength={150}
//                 component={FormikSelect}
//               />
//             </Grid>
//             <Grid item lg={2}>
//               <Field
//                 label="Cancellation"
//                 type="email"
//                 name="userName"
//                 id="userName"
//                 placeHolder="Search by guest, email, room or reservation ID"
//                 maxLength={150}
//                 component={FormikSelect}
//               />
//             </Grid>
//             <Grid item lg={2}>
//               <Field
//                 label="Source"
//                 type="email"
//                 name="userName"
//                 id="userName"
//                 placeHolder="Search by guest, email, room or reservation ID"
//                 maxLength={150}
//                 component={FormikSelect}
//               />
//             </Grid>
//             <Grid item lg={2}>
//               <Field
//                 label="Cleaning Fees"
//                 type="email"
//                 name="userName"
//                 id="userName"
//                 placeHolder="Search by guest, email, room or reservation ID"
//                 maxLength={150}
//                 component={FormikSelect}
//               />
//             </Grid>
//         </Grid>
//       </Formik>

//       <TableContainer className='mt-2'>
//         <Table>
//           <TableHead>
//               <TableRow>
//                 <TableCell style={{
//                   minWidth:"200px"
//                 }}>
//                   Room Rate details
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   26 Dec
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   27 Dec
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   28 Dec
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   29 Dec
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   30 Dec
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   31 Dec
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   1 Jan
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   2 Jan
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   3 Jan
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   4 Jan
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   5 Jan
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   6 Jan
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   7 Jan
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   8 Jan
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   9 Jan
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   10 Jan
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   11 Jan
//                 </TableCell>
//               </TableRow>
//           </TableHead>
//           <TableBody>
//               <TableRow>
//                 <TableCell style={{
//                   minWidth:"200px"
//                   }}>
//                   DBL
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   20
//                 </TableCell>
//               </TableRow>
//               <TableRow>
//                 <TableCell style={{
//                   minWidth:"200px"
//                   }}>
//                   Standard
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//                 <TableCell style={{
//                   width:"50px"
//                 }}>
//                   500
//                 </TableCell>
//               </TableRow>
//           </TableBody>
//         </Table>
//       </TableContainer> */
// }
