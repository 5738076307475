import {
  Avatar,
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  PaperProps,
  TextField,
  // Typography,
} from "@mui/material";
import React from "react";
import images from "../../assets/images";

interface IDialogFormProps {
  scroll?: "body" | "paper";
  maxWidth?: Breakpoint | false;
  openDialog: boolean;
  title?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  className?: string;
  message?: React.ReactNode;
  paperProps?: Partial<PaperProps>;
  handleDialogClose: () => void;
  handleDialogAction: () => void;
  handleInputText?: () => void;
  handleDialogCloseAction?: () => void;
  inputName?: string;
  inputNameValue?: string;
}

const ConfirmDialog: React.FC<IDialogFormProps> = ({
  scroll,
  maxWidth,
  openDialog,
  handleDialogClose,
  handleDialogAction,
  handleDialogCloseAction,
  title,
  className,
  message,
  paperProps,
  cancelButtonText,
  confirmButtonText,
  handleInputText,
  inputName,
  inputNameValue,
}) => {
  return (
    <Dialog
      open={openDialog}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleDialogClose();
        }
      }}
      className={`${className}`}
      scroll={scroll}
      maxWidth={maxWidth}
      PaperProps={paperProps}
    >
      {title && (
        <DialogTitle id="font-600" sx={{ backgroundColor: "#382DAE", color: "#ffffff" }}>
          {/* <Typography>{title}</Typography> */}
          {title}
          <IconButton
            size="small"
            className="close-action"
            onClick={handleDialogClose}
          >
            <Avatar
              sx={{ width: 22, height: 22 }}
              style={{ color: "#000" }}
              src={images.CloseWhite}
            />
          </IconButton>
        </DialogTitle>
      )}

      <DialogContent style={{ backgroundColor: "#FFF" }}>
        <DialogContentText  className="px-2" id="alert-dialog-description">
          <span id="font-600">

          {message}
          </span>
        </DialogContentText>
      </DialogContent>

      {inputName && (
        <TextField
          className="px-2 w-auto"
          sx={{ width: "auto" }}
          onChange={handleInputText}
          value={inputNameValue}
          variant="outlined"
          type="text"
          multiline
          rows={3}
          placeholder={inputName}
        />
      )}

      <DialogActions>
        <Button
          className="comman-cancel-button"
          onClick={handleDialogCloseAction ?
             handleDialogCloseAction :
            handleDialogClose}
          variant="contained"
        >
          {cancelButtonText}
        </Button>
        <Button
          className="comman-button"
          onClick={handleDialogAction}
          variant="contained"
          autoFocus
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
