import {
  Tabs,
  Box,
  Tab,
  Typography,
  Button,
  DialogActions,
  // Paper,
} from "@mui/material";
import "react-phone-input-2/lib/material.css";
import React, { useCallback, useEffect, useState } from "react";
import { BookingTabs } from "../../shared/enums/new-booking";
// import { makeStyles } from "@mui/styles";
import { getPermission } from "../../utils/helper";
import moment from "moment";
import {
  createRecord,
  fetchList,
  getRecord,
} from "../../apis/services/CommonApiService";
import { ApiEndPoints } from "../../apis/ApiEndPoints";

import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../layout/Loader";
import { useTranslation } from "react-i18next";
import NotExist from "../About/NotExist";
import { AddInfo } from "../NewBooking/AddInfo";
import { Confirm } from "../NewBooking/Confirm";
import { BookARoom } from "./BookARoom";
import ConfirmDialog from "../../shared/components/ConfirmDialog";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
// const useStyles = makeStyles(() => ({
//   table: {
//     borderTop: "1px solid #f0f0f0 !important",
//     borderBottom: "2px solid #B6BBCA !important",
//     background: "#FFFFFF",
//     borderRadius: "8px",
//   },
//   tableHeaderCell: {
//     padding: "15px 2px 15px 14px",
//     color: "#1E1E1E !important",
//     backgroundColor: " #f0f0f0 !important",
//     fontWeight: "500 !important",
//     fontSize: "16px !important",
//     borderRight: "1px solid #f0f0f0 !important",
//   },
//   tableCell: {
//     borderRight: "1px solid #FFFFFF !important",
//   },
// }));

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: string;
//   value: string;
// }
// const IOSSwitch = styled((props: SwitchProps) => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//   width: 42,
//   height: 26,
//   padding: 0,
//   "& .MuiSwitch-switchBase": {
//     padding: 0,
//     margin: 2,
//     transitionDuration: "300ms",
//     "&.Mui-checked": {
//       transform: "translateX(16px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         backgroundColor:
//           theme.palette.mode === "dark" ? "#2ECA45" : "rgb(28, 58, 102)",
//         opacity: 1,
//         border: 0,
//       },
//       "&.Mui-disabled + .MuiSwitch-track": {
//         opacity: 0.5,
//       },
//     },
//     "&.Mui-focusVisible .MuiSwitch-thumb": {
//       color: "#33cf4d",
//       border: "6px solid #fff",
//     },
//     "&.Mui-disabled .MuiSwitch-thumb": {
//       color:
//         theme.palette.mode === "light"
//           ? theme.palette.grey[100]
//           : theme.palette.grey[600],
//     },
//     "&.Mui-disabled + .MuiSwitch-track": {
//       opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxSizing: "border-box",
//     width: 22,
//     height: 22,
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 26 / 2,
//     backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
//     opacity: 1,
//     transition: theme.transitions.create(["background-color"], {
//       duration: 500,
//     }),
//   },
// }));

interface INewBookingProps {
  handleDialogClose: () => void;
}

const NewBooking: React.FC<INewBookingProps> = ({ handleDialogClose }) => {
  // const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  //Book Room Component start

  const { state } = useLocation();

  const [createPermission, setCreatePermission] = useState(null);
  const [viewPermission, setViewPermission] = useState(null);
  const auth = useSelector((state:any) => state.auth);

  const getPermissions = async () => {
    const AddPermission = await getPermission("Booking", 1);
    const ViewPermission = await getPermission("Booking", 2);
    setCreatePermission(AddPermission);
    setViewPermission(ViewPermission);
  };
  const [hotelId, setHotelId] = useState(null);
  const [loginUserId, setLoginUserId] = useState(0);

  useEffect(() => {
    getPermissions();
    const items = JSON.parse(localStorage.getItem("userDetails"));
    if (items) {
      setHotelId(items.hotel_id);
      setLoginUserId(items?.id);
    }
  }, [auth]);

  const [bookingType, setBookingType] = React.useState(1);
  const [paymentType, setPaymentType] = React.useState(1);
  const [iataNumber, setIataNumber] = React.useState("");
  const [bookingRates, setBookingRates] = useState([]);
  const [roomRateId, setRoomRateId] = useState(0);

  const [loading, setLoading] = React.useState(false);
  const [guestInfo, setGuestInfo] = useState<any>({
    user_id: "",
    name: "",
    phone: "",
    phone_code: "+1",
    email: "",
    id_number: "",
    id_proof_type: "",
    id_proof: "",
    image: "",
    address: "",
    tax_exempt: 0,
    tat_id: "",
    iata_number: "",
    company_profile_name: "",
    corporate_id: 0,
    country_id: "",
    state_id: "",
    city_id: "",
    postal_code: "",
  });

  const [card_present, setCard_Present] = useState(false);
  const handaleCardPresent = (e) => {
    setCard_Present(e.target.checked);
    setVerifyToken("");
  };

  const [searchFilters, setSearchFilters] = useState<any>({
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
    noOfGuest: 1,
  });

  // console.log(
  //   searchFilters,
  //   moment(searchFilters.startDate).format("YYYY-MM-DD"),
  //   176
  // );

  const [bookingDetails, setBookingDetails] = useState<any[]>([]);
  const bookingDetailsInitialState = {
    bookingDetailsInfo: bookingDetails,
  };

  // console.log(bookingDetails,231)

  const [bookingDetailsStats, setBookingDetailsStats] = useState({
    noOfRooms: 0,
    noOfAdults: 0,
    noOfExtraAdult: 0,
    noOfChild: 0,
    noOfExtraChild: 0,
    totalAmount: 0,
    discountedTotal: 0,
    taxAmount: 0,
    totalTax: 0,
    addOnTotal: 0,
    finslTotal: 0,
    extraAdult: 0,
    extraChild: 0,
  });
  const idProofTypes = [
    { id: 1, value: "Passport" },
    { id: 2, value: "Driving License" },
  ];
  // console.log(bookingDetailsStats,252)
  const [bookingLoading, setBookingLoading] = useState(false);
  const [calcuLoading, setCalcuLoading] = useState(false);

  const getBookingRates = async () => {
    // console.log(searchFilters, 209);
    setBookingLoading(true);
    await fetchList(
      ApiEndPoints.GET_RATE_FOR_BOOKING +
        `?hotel_id=${hotelId}&no_of_adult=${searchFilters?.noOfGuest}&start_date=${searchFilters.startDate}&end_date=${searchFilters.endDate}`
    )
      .then((res) => {
        if (res?.status === 200) {
          setBookingRates(res?.data);
          if (
            res?.data?.length > 0 &&
            (roomRateId === 0 ||
              res?.data[0]?.rateDetails?.filter(
                (el) => +el?.category_rate_type_id === +roomRateId
              )?.length === 0)
          ) {
            setRoomRateId(
              res?.data[0]?.rateDetails?.length > 0
                ? res?.data[0]?.rateDetails[0].category_rate_type_id
                : 0
            );
          }
          // console.log(state,res.data, searchFilters,248)
        } else {
          setBookingRates([]);
        }
        setBookingLoading(false);
        setCalcuLoading(true);
      })
      .catch((err) => {
        setBookingLoading(false);
        console.log(err, "get rate err");
      });
  };

  const walkin = window.location.pathname === "/walk-in";

  // useEffect(() => {

  //   // console.log(searchFilters, 272)
  //   getBookingRates();

  // }, [walkin]);

  useEffect(() => {
    if (hotelId) {
      // console.log(searchFilters, 239);
      getBookingRates();
      // if (
      //   searchFilters?.startDate === moment(new Date()).format("YYYY-MM-DD")
      // ) {
      //   getBookingRates();
      // } else {
      //   getBookingRates();
      //   getBookingRates();
      // }
    }
  }, [searchFilters, hotelId]);

  // const findCalendarRate = (state) => {
  //   setSearchFilters({
  //     startDate: moment(state?.selectedDate[0]?.start).format("YYYY-MM-DD"),
  //     endDate: moment(state?.selectedDate[0]?.end).format("YYYY-MM-DD"),
  //     noOfGuest: 2,
  //   });
  // };

  useEffect(() => {
    if (state !== null) {
      // findCalendarRate(state);
      setSearchFilters({
        startDate: moment(state?.selectedDate[0]?.start).format("YYYY-MM-DD"),
        endDate: moment(state?.selectedDate[0]?.end).format("YYYY-MM-DD"),
        noOfGuest: 2,
      });
      setRoomRateId(state?.selectedDate[0]?.room_rate_id);
      // console.log(state, 293);
    }
  }, [state?.selectedDate]);

  const handleChangeBookingType = (event, newAlignment) => {

    setBookingType(newAlignment);

  };

  const handalepaymentType = (e) => {
    setVerifyToken("");
    setCard_Present(false);
    setPaymentType(e.target.value);
  };

  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [cancelPolicy, setCancelPolicy] = useState(1);
  const [guaranteePolicy, setGuaranteePolicy] = useState(1);
  const [discountedRate, setDiscountedRate] = useState([]);

  useEffect(() => {
    if (hotelId) {
      getDiscountedRate();
      getAddOnList();
      getTaxMaster("test");
      getAllTaxList();
      getCompaniesList();
    }
  }, [hotelId]);

  const getDiscountedRate = async () => {
    setLoading(true);
    const result = await fetchList(
      ApiEndPoints.ROOM_RATES_RESOURCE_ROUTE +
        `/get-discounted-room-rate-list-for-select`
    );
    if (result?.status === 200) {
      setLoading(false);
      setDiscountedRate(result.data);
    } else {
      setLoading(false);
    }
  };

  function daysBetween(one, another) {
    return Math.round(Math.abs(+one - +another) / 8.64e7);
  }

  function addOneDay(date = new Date()) {
    date.setDate(date.getDate() + 1);

    return date;
  }

  // function getAveragePrice(details, dayDiff, startDate) {
  //   // console.log(details, 403)
  //   // let bio:any = ""
  //   if (details) {
  //     if (dayDiff > 1) {
  //       let count = 0;
  //       let date = startDate;
  //       // console.log(date, 403);
  //       for (let i = 0; i < dayDiff; i++) {
  //         // count = count + Number(getRateByDay(details, date));
  //         for (let j in details) {
  //           let rateArray = details[j]?.dateWiseRateDetail?.filter(
  //             (pItem) =>
  //               moment(pItem?.date)?.format("YYYY-MM-DD") ===
  //                 moment(date)?.format("YYYY-MM-DD") &&
  //               +pItem?.accomodation_id === 1
  //           );
  //           count =
  //             rateArray?.length > 0
  //               ? count + Number(rateArray[0]?.rate)
  //               : count;
  //             }
  //             date = moment(date).add(1,"days").format("YYYY-MM-DD");

  //         // date = addOneDay(new Date(date));
  //         // console.log(i, date, 403);
  //       }
  //       return (count / dayDiff).toFixed(2);
  //       // return date
  //     } else {
  //       return +((Number(
  //         details[0]?.dateWiseRateDetail?.filter(
  //           (pItem) =>
  //             moment(pItem?.date)?.format("YYYY-MM-DD") ===
  //               moment(startDate)?.format("YYYY-MM-DD") &&
  //             pItem?.accomodation_id === 1
  //         )[0]?.rate
  //       )).toFixed(2));
  //     }
  //     //  bio = getRateByDay(details,startDate)
  //   }
  //   // return bio;
  // }

  function getAveragePrice(detail, dayDiff, startDate, rateId) {
    let details = detail?.filter(
      (item) => +item?.category_rate_type_id === +rateId
    );

    if (details) {
      if (dayDiff > 1) {
        let count = 0;
        let date = new Date(startDate); // Parse startDate as a Date object
        for (let i = 0; i < dayDiff; i++) {
          for (let j in details) {
            let rateArray = details[j]?.dateWiseRateDetail?.filter(
              (pItem) =>
                new Date(pItem?.date).toDateString() === date.toDateString() && // Standardize date comparison
                +pItem?.accomodation_id === 1
            );
            // if (rateArray?.length > 0) {
            //   // console.log(
            //   //   "momentDate:",
            //   //   moment(date)?.format("YYYY-MM-DD"),
            //   //   "rate:",
            //   //   rateArray[0]?.rate,
            //   //   "category id:",
            //   //   rateArray[0]?.category_id,
            //   //   "rateArry[0]:",
            //   //   rateArray[0],
            //   //   "date:",
            //   //   date.toDateString()
            //   // );
            // } else {
            //   // console.log("rateObject:", details[j]?.dateWiseRateDetail);
            // }
            count += rateArray?.length > 0 ? Number(rateArray[0]?.rate) : 0;
          }
          date.setDate(date.getDate() + 1); // Increment date by one day
        }
        // console.log(
        //   "days:",
        //   dayDiff,
        //   "totalPrice:",
        //   count,
        //   "avgPrice:",
        //   (count / dayDiff).toFixed(2)
        // );
        return (count / dayDiff).toFixed(2);
      } else {
        return +Number(
          details[0]?.dateWiseRateDetail?.filter(
            (pItem) =>
              new Date(pItem?.date).toDateString() ===
                new Date(startDate).toDateString() && // Standardize date comparison
              pItem?.accomodation_id === 1
          )[0]?.rate
        ).toFixed(2);
      }
    }
  }

  function getAdultAveragePrice(detail, dayDiff, startDate, rateId) {
    // let bio:any = ""
    let details = detail?.filter(
      (item) => +item?.category_rate_type_id === +rateId
    );
    if (details) {
      if (dayDiff > 1) {
        let count = 0;
        // let date = startDate;
        let date = new Date(startDate); // Parse startDate as a Date object
        for (let i = 0; i < dayDiff; i++) {
          for (let j in details) {
            let rateArray = details[j]?.dateWiseRateDetail?.filter(
              (pItem) =>
                // moment(pItem?.date)?.format("YYYY-MM-DD") ===
                //   moment(date)?.format("YYYY-MM-DD") &&
                new Date(pItem?.date).toDateString() === date.toDateString() && // Standardize date comparison
                pItem?.accomodation_id === 2
            );
            // count =
            //   rateArray?.length > 0
            //     ? count + Number(rateArray[0]?.rate)
            //     : count;
            count += rateArray?.length > 0 ? Number(rateArray[0]?.rate) : 0;
          }
          // date = moment(date).add(1,"days").format("YYYY-MM-DD")
          date.setDate(date.getDate() + 1); // Increment date by one day

          // date = addOneDay(new Date(date));
          // console.log(i, count, 403);
        }
        return (count / dayDiff).toFixed(2);
        // return date
      } else {
        return Number(
          details[0]?.dateWiseRateDetail?.filter(
            (pItem) =>
              // moment(pItem?.date)?.format("YYYY-MM-DD") ===
              //   moment(startDate)?.format("YYYY-MM-DD") &&
              new Date(pItem?.date).toDateString() ===
                new Date(startDate).toDateString() && // Standardize date comparison
              pItem?.accomodation_id === 2
          )[0]?.rate
        );
      }
      //  bio = getRateByDay(details,startDate)
    }
    // return bio;
  }

  function getChildAveragePrice(detail, dayDiff, startDate, rateId) {
    // let bio:any = ""
    let details = detail?.filter(
      (item) => +item?.category_rate_type_id === +rateId
    );

    if (details) {
      if (dayDiff > 1) {
        let count = 0;
        // let date = startDate;
        let date = new Date(startDate); // Parse startDate as a Date object
        for (let i = 0; i < dayDiff; i++) {
          for (let j in details) {
            let rateArray = details[j]?.dateWiseRateDetail?.filter(
              (pItem) =>
                // moment(pItem?.date)?.format("YYYY-MM-DD") ===
                //   moment(date)?.format("YYYY-MM-DD") &&
                new Date(pItem?.date).toDateString() === date.toDateString() && // Standardize date comparison
                pItem?.accomodation_id === 3
            );
            // count =
            //   rateArray?.length > 0
            //     ? count + Number(rateArray[0]?.rate)
            //     : count;
            count += rateArray?.length > 0 ? Number(rateArray[0]?.rate) : 0;
          }
          // date = moment(date).add(1,"days").format("YYYY-MM-DD")
          date.setDate(date.getDate() + 1); // Increment date by one day

          // date = addOneDay(new Date(date));
          // console.log(i, count, 403);
        }
        return (count / dayDiff).toFixed(2);
        // return date
      } else {
        return Number(
          details[0]?.dateWiseRateDetail?.filter(
            (pItem) =>
              // moment(pItem?.date)?.format("YYYY-MM-DD") ===
              //   moment(startDate)?.format("YYYY-MM-DD") &&
              new Date(pItem?.date).toDateString() ===
                new Date(startDate).toDateString() && // Standardize date comparison
              pItem?.accomodation_id === 3
          )[0]?.rate
        );
      }
      //  bio = getRateByDay(details,startDate)
    }
    // return bio;
  }

  // console.log(daysBetween(new Date(searchFilters.startDate), new Date(searchFilters.endDate)), 390)

  const getbookingRate = () => {
    // console.log("bookingRates", bookingRates);
    setCalcuLoading(true);
    let dayDiff = daysBetween(
      new Date(searchFilters.startDate),
      new Date(searchFilters.endDate)
    );

    const roomListDet: any = bookingRates?.map((category: any, index) => {
      if (category?.rateDetails?.length === 0) {
        toast.error(t("rateDetails is empty"));
        setCalcuLoading(false);

        return;
      }

      const max = [];
      for (
        let i = category.default_occupancy;
        i < category.max_occupancy;
        i++
      ) {
        max.push(i);
      }

      const defaultPerson = [];
      for (let i = 0; i < category.default_occupancy; i++) {
        defaultPerson.push(i);
      }

      // console.log(category, 414);
      setCancelPolicy(category.rateDetails?.length > 0
        ? category?.rateDetails?.find(
            (el) => el?.category_rate_type_id === +roomRateId
          )?.rateTypeDetail?.cancellationPolicyDetails?.id
        : 0);

      return {
        category_id: category.id,
        roomTypeId: category.id,
        category_name: category.roomcategory_name,
        availableRooms: category.availableRoomsArray,
        selectedRoom: "",
        max_occupancy: category.max_occupancy,
        available_rooms:
          +category?.availableRooms -
          bookingDetails?.filter((item) => item?.roomTypeId === category?.id)
            ?.length +
          bookingDetails?.filter(
            (item) => item?.bookeng_item_id && item?.roomTypeId === category?.id
          )?.length,
        start_date: searchFilters.startDate,
        end_date: searchFilters.endDate,
        cancelation_policy_id: category.rateDetails?.length > 0
        ? category?.rateDetails?.find(
            (el) => el?.category_rate_type_id === +roomRateId
          )?.rateTypeDetail?.cancellationPolicyDetails?.id
        : 0,
        cancelation_policy:
          category.rateDetails?.length > 0
            ? category?.rateDetails?.find(
                (el) => el?.category_rate_type_id === +roomRateId
              )?.rateTypeDetail?.cancellationPolicyDetails?.name
            : "none",
        cancelation_policy_desc:
          category.rateDetails?.length > 0
            ? category?.rateDetails?.find(
                (el) => el?.category_rate_type_id === +roomRateId
              )?.rateTypeDetail?.cancellationPolicyDetails?.description
            : "none",
        discount: 0,
        discountType: 1,
        discountValue: 0,
        discountOnePrice: 0,
        discounted_rate: "",
        discounted_selected: 0,
        discount_percentage: 0,
        discounted_rate_id: 0,
        discountedPrice: 0,
        extraAdult: max,
        extraChild: max,
        defaultAdult: defaultPerson,
        defaultChild: defaultPerson,
        // minRoomCapacity: category.default_occupancy,
        rateTypeId:
          category.rateDetails?.length > 0
            ? category.rateDetails?.find(
                (el) => el?.category_rate_type_id === +roomRateId
              )?.category_rate_type_id
            : 0,
        extraTotalPrice: getAveragePrice(
          category.rateDetails,
          dayDiff,
          searchFilters.startDate,
          category.rateDetails?.length > 0
            ? category.rateDetails?.find(
                (el) => el?.category_rate_type_id === +roomRateId
              )?.category_rate_type_id
            : 0
        ),
        PriceAdult: 0,
        PriceChild: 0,
        discountAdult: 0,
        discountChild: 0,
        defaultAdultArr: defaultPerson,
        defaultChildArr: defaultPerson,
        Adult: max,
        Child: max,
        selectAdult: 0,
        selectChild: 0,
        selectDefaultAdult: 0,
        selectDefaultChild: 0,
        selectCommonAdult: 0,
        selectCommonChild: 0,
        discountRoom: 0,
        discountOneAdult: 0,
        discountOneChild: 0,
        QtPrice: getAveragePrice(
          category.rateDetails,
          dayDiff,
          searchFilters.startDate,
          category.rateDetails?.length > 0
            ? category.rateDetails?.find(
                (el) => el?.category_rate_type_id === +roomRateId
              )?.category_rate_type_id
            : 0
        ),
        min_Occupancy: category.default_occupancy,
        discountPrice: getAveragePrice(
          // category.rateDetails[index]?.categoryRateDetail
          category.rateDetails,
          dayDiff,
          searchFilters.startDate,
          category.rateDetails?.length > 0
            ? category.rateDetails?.find(
                (el) => el?.category_rate_type_id === +roomRateId
              )?.category_rate_type_id
            : 0
        ),

        price: getAveragePrice(
          category.rateDetails,
          dayDiff,
          searchFilters.startDate,
          category.rateDetails?.length > 0
            ? category.rateDetails?.find(
                (el) => el?.category_rate_type_id === +roomRateId
              )?.category_rate_type_id
            : 0
        ),
        // price: 100,
        AdultOnePrice: 0,
        ChildOnePrice: 0,
        rateDetails: category?.rateDetails,

        defaultSelectedRateId: category.rateDetails?.filter(
          (item: any) => item.isActivatedRate === 1
        )[0]?.id,
        defaultSelectedRoom: 1,
        RoomQuantity: 1,
        addOnPrice: 0,
        addOnTotal: 0,
        mealOption: [],
        totalRateOfRoom: 0,
        totalRateWithTax: 0,
      };
    });

    // roomListDet.totalRateOfRoom=roomListDet?.discountPrice
    for (let i in roomListDet) {
      roomListDet[i].totalRateOfRoom = +(
        dayDiff * roomListDet[i]?.discountPrice
      ).toFixed(2);
      roomListDet[i].totalRateWithTax = +(
        ((dayDiff * roomListDet[i]?.discountPrice) / 100) *
          (Number(taxRateData) - Number(taxExemptRateData)) +
        dayDiff * roomListDet[i]?.discountPrice
      ).toFixed(2);
    }
    // console.log(463, roomListDet);
    setCategoryList(roomListDet);
    setCalcuLoading(false);
  };

  const [taxRateData, setTaxRateData] = useState<any>(0);
  const [taxExemptRateData, setTaxExemptRateData] = useState<any>(0);

  useEffect(() => {
    getbookingRate();
  }, [
    bookingRates,
    // searchFilters,
    taxRateData,
    taxExemptRateData,
  ]);

  const bookRoomInitialState = {
    bookingRateInfo: categoryList,
  };

  // console.log(categoryList, 667);
  // const [cancelationPolicy, setCancelationPolicy] = useState("")

  const handleChangeRoomRate = (e) => {
    setRoomRateId(e.target.value);

    let dayDiff = daysBetween(
      new Date(searchFilters.startDate),
      new Date(searchFilters.endDate)
    );
    setCategoryList(
      categoryList?.map((data) => {
        let disRate = data.discount_percentage;
        let flat = 0;
        if (+data?.discountType === 2) {
          disRate = 0;
          flat = +data?.discount;
        }
        // console.log(disRate, flat, data, categoryList, 753);
        data.price = +getAveragePrice(
          data?.rateDetails,
          dayDiff,
          searchFilters.startDate,
          e?.target.value
        );
        let QtPrice =
          +getAveragePrice(
            data?.rateDetails,
            dayDiff,
            searchFilters.startDate,
            e?.target.value
          ) * +data?.RoomQuantity;
        let AdultOnePrice =
          +getExtraAdultById(data.rateDetails, data?.rateId, e?.target.value) *
          +data?.selectAdult;

        let ChildOnePrice =
          +getExtraChildById(
            data.rateDetails,
            data.defaultSelectedRateId,
            e?.target.value
          ) * +data?.selectChild;
        data.PriceAdult = Number(AdultOnePrice) * data?.RoomQuantity;
        data.PriceChild = Number(ChildOnePrice) * data?.RoomQuantity;
        data.extraTotalPrice = +(
          QtPrice +
          data.PriceAdult +
          data.PriceChild
        ).toFixed(2);
        data.discountPrice = data.extraTotalPrice;
        data.totalRateOfRoom = +(
          dayDiff *
          (data.extraTotalPrice + data.addOnTotal)
        ).toFixed(2);
        data.totalRateWithTax = +(
          ((dayDiff * (data.extraTotalPrice + data.addOnTotal)) / 100) *
            (Number(taxRateData) - Number(taxExemptRateData)) +
          dayDiff * (data.extraTotalPrice + data.addOnTotal)
        ).toFixed(2);

        const totaldiscount =
          +((+data.price * disRate) / 100).toFixed(2) + flat;
        const Price = +((data.price * (100 - disRate)) / 100 - flat).toFixed(2);
        const DISCPrice = +(
          (data.extraTotalPrice * (100 - disRate)) /
          100
        ).toFixed(2);
        const PriceAdults = +(
          (data.PriceAdult * (100 - disRate)) /
          100
        ).toFixed(2);
        const PriceChilds = +(
          (data.PriceChild * (100 - disRate)) /
          100
        ).toFixed(2);
        data.discountAdult = PriceAdults;

        data.discountChild = PriceChilds;

        const totalAdultdiscount = +((AdultOnePrice * disRate) / 100).toFixed(
          2
        );
        data.discountOneAdult = +totalAdultdiscount;
        const totalChilddiscount = +((ChildOnePrice * disRate) / 100).toFixed(
          2
        );

        data.discountOneChild = +totalChilddiscount;
        data.discountedPrice = Price;
        data.discountRoom = +(+Price * data.RoomQuantity).toFixed(2);
        data.discountPrice = DISCPrice - flat;
        data.totalRateOfRoom = +(
          dayDiff * (DISCPrice + data.addOnTotal) -
          flat
        ).toFixed(2);
        data.totalRateWithTax = +(
          ((dayDiff * (DISCPrice + data.addOnTotal) - flat) / 100) *
            (Number(taxRateData) - Number(taxExemptRateData)) +
          (dayDiff * (DISCPrice + data.addOnTotal) - flat)
        ).toFixed(2);
        data.extraTotalPrice = DISCPrice - flat;
        setCancelPolicy(data?.rateDetails?.find(
          (el) => +el?.category_rate_type_id == +e?.target?.value
        )?.rateTypeDetail?.cancellationPolicyDetails?.id);
        
        return {
          ...data,
          discountedPrice: Price,
          extraTotalPrice: data.extraTotalPrice,
          discountOnePrice: +totaldiscount,
          discountOneAdult: +totalAdultdiscount,
          discountOneChild: +totalChilddiscount,
          AdultOnePrice: +AdultOnePrice,
          PriceAdult: +data.PriceAdult,
          ChildOnePrice: +ChildOnePrice,
          PriceChild: +data.PriceChild,
          discountAdult: +data.discountAdult,
          discountChild: +data.discountChild,
          price: +data.price,
          QtPrice: +QtPrice,
          discount:
            flat === 0
              ? +(+totaldiscount * data.RoomQuantity).toFixed(2)
              : +totaldiscount.toFixed(2),
          discountPrice: DISCPrice - flat,
          totalRateOfRoom: +(
            dayDiff *
            (DISCPrice - flat + data.addOnTotal)
          ).toFixed(2),
          totalRateWithTax: +(
            ((dayDiff * (DISCPrice - flat + data.addOnTotal)) / 100) *
              (Number(taxRateData) - Number(taxExemptRateData)) +
            dayDiff * (DISCPrice - flat + data.addOnTotal)
          ).toFixed(2),
          rateTypeId: e?.target?.value,
          cancelation_policy_id: data?.rateDetails?.find(
            (el) => +el?.category_rate_type_id == +e?.target?.value
          )?.rateTypeDetail?.cancellationPolicyDetails?.id,
          cancelation_policy: data?.rateDetails?.find(
            (el) => +el?.category_rate_type_id == +e?.target?.value
          )?.rateTypeDetail?.cancellationPolicyDetails?.name,
          cancelation_policy_desc: data?.rateDetails?.find(
            (el) => +el?.category_rate_type_id == +e?.target?.value
          )?.rateTypeDetail?.cancellationPolicyDetails?.description,
        };
      })
    );

    //  setBookingDetails
    setBookingDetails(
      bookingDetails?.map((item) => {
        let data = item?.data;
        data.mealPrice = 0;
        let disRate = data.discount_percentage;
        let flat = 0;
        if (+data?.discountType === 2) {
          disRate = 0;
          flat = +data?.discount;
        }
        data.price = +getAveragePrice(
          data?.rateDetails,
          dayDiff,
          searchFilters.startDate,
          e?.target.value
        );
        let QtPrice =
          +getAveragePrice(
            data?.rateDetails,
            dayDiff,
            searchFilters.startDate,
            e?.target.value
          ) * +data?.RoomQuantity;
        let AdultOnePrice =
          +getExtraAdultById(data.rateDetails, data?.rateId, e?.target.value) *
          +data?.selectAdult;
        let ChildOnePrice =
          +getExtraChildById(
            data.rateDetails,
            data.defaultSelectedRateId,
            e?.target.value
          ) * +data?.selectChild;
        data.PriceAdult = Number(AdultOnePrice) * data?.RoomQuantity;
        data.PriceChild = Number(ChildOnePrice) * data?.RoomQuantity;
        data.extraTotalPrice = +(
          QtPrice +
          data.PriceAdult +
          data.PriceChild
        ).toFixed(2);
        data.discountPrice = ++data.extraTotalPrice;
        data.totalRateOfRoom = +(
          dayDiff *
          (data.extraTotalPrice + data.addOnTotal)
        ).toFixed(2);
        data.totalRateWithTax = +(
          ((dayDiff * (data.extraTotalPrice + data.addOnTotal)) / 100) *
            (Number(taxRateData) - Number(taxExemptRateData)) +
          dayDiff * (data.extraTotalPrice + data.addOnTotal)
        ).toFixed(2);

        const totaldiscount =
          +((+data.price * disRate) / 100).toFixed(2) + flat;
        const Price = +((data.price * (100 - disRate)) / 100 - flat).toFixed(2);
        const DISCPrice = +(
          (data.extraTotalPrice * (100 - disRate)) /
          100
        ).toFixed(2);
        const PriceAdults = +(
          (data.PriceAdult * (100 - disRate)) /
          100
        ).toFixed(2);
        const PriceChilds = +(
          (data.PriceChild * (100 - disRate)) /
          100
        ).toFixed(2);
        data.discountAdult = +PriceAdults;

        data.discountChild = +PriceChilds;

        const totalAdultdiscount = +((AdultOnePrice * disRate) / 100).toFixed(
          2
        );
        data.discountOneAdult = +totalAdultdiscount;
        const totalChilddiscount = +((ChildOnePrice * disRate) / 100).toFixed(
          2
        );

        data.discountOneChild = +totalChilddiscount;
        data.discountedPrice = Price;
        data.discountRoom = +(+Price * data.RoomQuantity).toFixed(2);
        data.discountPrice = DISCPrice - flat;
        data.totalRateOfRoom = +(
          dayDiff * (DISCPrice + data.addOnTotal) -
          flat
        ).toFixed(2);
        data.totalRateWithTax = +(
          ((dayDiff * (DISCPrice + data.addOnTotal) - flat) / 100) *
            (Number(taxRateData) - Number(taxExemptRateData)) +
          (dayDiff * (DISCPrice + data.addOnTotal) - flat)
        ).toFixed(2);
        data.extraTotalPrice = DISCPrice - flat;
        // console.log(875, data.price, data.mealPrice, dayDiff )

        return {
          ...item,
          roomRateName: data?.rateDetails?.find(
            (el) => +el?.category_rate_type_id == +e.target.value
          )?.name,
          rateId: e.target.value,
          discountAdult: +(+dayDiff * +AdultOnePrice).toFixed(2),
          discountChild: +(+dayDiff * +ChildOnePrice).toFixed(2),
          discountedPrice: data.discountedPrice,
          discountPrice: +(
            +dayDiff *
            (+data.discountPrice + +data.mealPrice)
          ).toFixed(2),
          discountedTotalPrice: +(
            +dayDiff *
            (+data.price +
              +data.mealPrice +
              +data.addOnPrice -
              (+totaldiscount + +totalAdultdiscount + +totalChilddiscount) +
              AdultOnePrice +
              ChildOnePrice)
          ).toFixed(2),
          discountedTotalPriceWithTax: +(
            ((+dayDiff *
              (+data.price +
                +data.mealPrice +
                +data.addOnPrice -
                (+totaldiscount + +totalAdultdiscount + +totalChilddiscount) +
                +AdultOnePrice +
                +ChildOnePrice)) /
              100) *
              (+taxRateData - +taxExemptRateData) +
            +dayDiff *
              (+data.price +
                +data.mealPrice +
                +data.addOnPrice -
                (+totaldiscount + +totalAdultdiscount + +totalChilddiscount) +
                +AdultOnePrice +
                +ChildOnePrice)
          ).toFixed(2),
          price: +(+dayDiff * (+data.price + +data.mealPrice)).toFixed(2),
          AvgPricePerRoom: +data.price,
          discount: +(
            +dayDiff *
            (+totaldiscount + +totalAdultdiscount + +totalChilddiscount)
          ).toFixed(2),
          addOnTotal: +dayDiff * +data.addOnTotal,
          addOnPrice: +dayDiff * +data.addOnPrice,
          discountOnePrice: +(
            +dayDiff *
            (+totaldiscount + +totalAdultdiscount + +totalChilddiscount)
          ).toFixed(2),
          cancelationPolicy: data?.rateDetails?.find(
            (el) => +el?.category_rate_type_id == +e.target.value
          )?.rateTypeDetail?.cancellationPolicyDetails?.name,
        };
      })
    );
    // console.log(categoryList, bookingDetails , 736);
  };

  const handleCheckOutDate = (event) => {
    const { value } = event.target;
    // setEndDate(value);
    setSearchFilters((prevState) => ({
      ...prevState,
      endDate: value,
    }));
    for (var i in categoryList) {
      categoryList[i]["end_date"] = value;
    }
    // handleClearBookings();
    setBookingDetails([]);
  };

  const handleGuestChange = (event) => {
    const { value } = event.target;
    // setNoOfGuest(value);
    setSearchFilters((prevState) => ({
      ...prevState,
      noOfGuest: value,
    }));
    // for (var i in categoryList) {
    //   categoryList[i]["end_date"] = value;
    // }
    // handleClearBookings();
    // setBookingDetails([]);
  };

  const [selectedGuest, setSelectedGuest] = useState({
    user_id: "",
    name: "",
  });
  const handleCheckInDate = async (event) => {
    const { value } = event.target;
    // setStartDate(value);
    setSearchFilters((prevState) => ({
      ...prevState,
      startDate: value,
      endDate: moment(moment(value, "YYYY-MM-DD").add({ days: 1 })).format(
        "YYYY-MM-DD"
      ),
    }));
    for (var i in categoryList) {
      categoryList[i]["start_date"] = value;
      categoryList[i]["end_date"] = moment(
        moment(value, "YYYY-MM-DD").add({ days: 1 })
      ).format("YYYY-MM-DD");
    }
    // handleClearBookings();
    setBookingDetails([]);
  };

  // console.log(searchFilters, 764);
  const [searchUserResult, setSearchUserResult] = useState<any[]>([]);
  // const [searchUserText, setSearchUserText] = useState("");
  const searchUserApi = async (searchUserText) => {
    const result = await fetchList(
      ApiEndPoints.USER_AUTOCOMPLETE + `?search_text=${searchUserText}`
    );
    if (result?.status === 200) {
      setSearchUserResult(result.data);
    } else {
      setSearchUserResult([]);
    }
  };
  const onSearchInputChange = (event) => {
    if (event === null) {
      setSearchUserResult([]);
    } else {
      const { value } = event.target;
      if (value) {
        searchUserApi(value);
      } else {
        setSearchUserResult([]);
      }
    }
  };
  // const CustomPaper = (props) => {
  //   return <Paper elevation={8} {...props} />;
  // };
  const handleUserSelected = (user) => {
    if (user !== null) {
      getCradsDetail(user?.id);
      setSelectedGuest({
        user_id: user?.id,
        name: user?.name,
      });
      setGuestInfo({
        user_id: user?.id,
        name: user?.name,
        phone: user?.mobile,
        email: user?.email,
        phone_code: user?.phone_code,
        id_number: user?.idNumber,
        id_proof_type: user?.id_proof_type,
        id_proof: "",
        image: user.idProofImage?.image,
        address: user?.address,
        tax_exempt: user?.tax_exempt,
        tat_id: user?.tax_id,
        country_id: user?.userDetail?.country_id,
        state_id: user?.userDetail?.state_id,
        city_id: user?.userDetail?.city_id,
        postal_code: user?.userDetail?.postal_code,
      });
    } else {
      setCardDetail([]);
      setVerifyToken("");
      setCard({
        id: 0,
        user_id: "",
        card_holder_name: "",
        card_number: "",
        yyy: "",
        mm: "",
        cvv: "",
        card_type: "",
      });
      setSelectedCard(0);
      setSelectedGuest({
        user_id: "",
        name: "",
      });
      setGuestInfo({
        user_id: "",
        name: "",
        phone: "",
        phone_code: "+1",
        email: "",
        id_number: "",
        id_proof_type: "",
        id_proof: "",
        image: "",
        address: "",
        tax_exempt: 0,
        tat_id: "",
        iata_number: "",
        company_profile_name: "",
        corporate_id: 0,
        country_id: "",
        state_id: "",
        city_id: "",
        postal_code: "",
      });
    }
  };

  const [selectedGroup, setSelectedGroup] = useState({
    user_id: "",
    group_name: "",
  });

  const handaleGroupinfo = async (Data) => {
    if (Data !== null) {
      const result = await getRecord(Data.id, ApiEndPoints.GROUP_INFORMATION);
      getCradsDetail(result.data?.user_id);
      setSelectedGroup({
        user_id: result.data?.user_id,
        group_name: result.data?.contact_name,
      });
      setGuestInfo({
        user_id: result.data?.user_id,
        name: result.data?.contact_name,
        phone: result.data?.phone,
        email: result.data?.email,
        phone_code: result.data?.phone_code,
        address: result.data?.groupMasterDetail?.address,
        company_profile_name: result.data?.company_profile_name,
        corporateID: result.data?.corporate_id,
        iata_number: result.data?.iata_number,
        country_id: result.data?.groupMasterDetail?.country_id,
        state_id: result.data?.groupMasterDetail?.state_id,
        postal_code: result.data?.groupMasterDetail?.postal_code,
        city_id: result.data?.groupMasterDetail?.city,
      });
      setIataNumber(result.data.iata_number);
    } else {
      setCardDetail([]);
      setVerifyToken("");
      setCard({
        id: 0,
        user_id: "",
        card_holder_name: "",
        card_number: "",
        yyy: "",
        mm: "",
        cvv: "",
        card_type: "",
      });
      setSelectedCard(0);
      setSelectedGroup({
        user_id: "",
        group_name: "",
      });
      setGuestInfo({
        user_id: "",
        name: "",
        phone: "",
        phone_code: "+1",
        email: "",
        id_number: "",
        id_proof_type: "",
        id_proof: "",
        image: "",
        address: "",
        tax_exempt: 0,
        tat_id: "",
        iata_number: "",
        company_profile_name: "",
        corporate_id: 0,
        country_id: "",
        state_id: "",
        city_id: "",
        postal_code: "",
      });
    }
  };

  // const getBookingRateInfoById = async (rateArr, rateId) => {
  //   console.log(rateArr, rateId, 733);
  //   const arr = rateArr.filter((item, index) => {
  //     return item.id === rateId;
  //   });
  //   return arr[0];
  // };

  const bookRoomHandleSubmit = (values) => {};

  const roomAvailableData = [
    {
      Booking: "King",
      Room: "Oct 28, 2022 - Oct 30, 2022",
      Guests: "2",
      Chat: "Standard",
      Nights: "No meals",
      Roomrate: "Free cancellation policy",
      Cancellationpolicy: "$200.00",
      Priceforroom: "1",
      RoomNo: "2",
    },
  ];

  const handleAddRoomItem = async (
    data,
    index,
    setFieldValue,
    price,
    discountPrice,
    quantity,
    rateId,
    mealOption,
    discountType,
    discount,
    discountValue,
    discountOnePrice,
    discounted_rate,
    addOnTotal,
    discountedPrice,
    addOnPrice,
    discounted_selected,
    selectAdult,
    selectDefaultAdult,
    selectChild,
    selectDefaultChild,
    discountAdult,
    discountChild,
    QtPrice,
    PriceAdult,
    AdultOnePrice,
    ChildOnePrice,
    discountOneAdult,
    discountOneChild,
    min_Occupancy,
    availableRooms,
    discount_percentage,
    rateTypeId
  ) => {
    let dayDiff = daysBetween(
      new Date(searchFilters.startDate),
      new Date(searchFilters.endDate)
    );
    if (quantity === 0) {
      toast.error(t("Plese select Room Quantity"));
      return;
    }

    if (selectDefaultChild === 0 && selectDefaultAdult === 0) {
      toast.error(t("Plese select No Of Adults or Children"));
      return;
    }

    // console.log(searchFilters, 1234);
    getBookingRates();
    if(bookingDetails?.length === 1 && bookingType === 1){
      handleChangeBookingType("",2)
    }
    const Quantity = roomQutSelectedValue;
    // const rateDet = await getBookingRateInfoById(data.rateDetails, rateId);
    const addOn = addOnList.filter((item) => mealOption.includes(item.name));
    const addOnLists = [];
    for (var j in addOn) {
      addOnLists.push({
        name: addOn[j].name,
        amount:
          // addOn[j].name === "Meal"
          //   ? dayDiff *
          //     Number(parseFloat(addOn[j].amount) + parseFloat(addOn[j].amount))
          //   :
          dayDiff * Number(addOn[j].amount),
        id: addOn[j].id,
      });
    }
    // const mealOption =
    //   bookRoomInitialState["bookingRateInfo"][`${index}`]["mealOption"];
    // console.log("mealOption", mealOption);

    const Selectedrooms = [];
    const catid = state?.selectedDate?.filter(
      (item) => item.type_id === data.category_id
    );
    const bookingDetail = bookingDetails.filter(
      (item) => item.roomTypeId === data.category_id
    );
    if (state?.selectedDate !== undefined) {
      for (var y in bookingDetail) {
        if (bookingDetail[y].roomNo === 0) {
        } else {
          catid.splice(
            catid.findIndex((a) => a.room_id === bookingDetail[y].roomNo),
            1
          );
        }
      }
    }
    for (var y in bookingDetails) {
      if (bookingDetails[y]?.roomNo === 0) {
      } else {
        Selectedrooms.push(bookingDetails[y].roomNo);
      }
    }
    setSelectedRoom(Selectedrooms);

    var date1 = moment(searchFilters.startDate);
    var date2 = moment(searchFilters.endDate);
    var days = date2.diff(date1, "days");
    var mealPrice = 0;
    // parseFloat(+addOnTotal == 1 ? rateDet.meal_price : 0);
    // console.log("mealPrice", mealPrice);
    // console.log("mealPrice", rateDet.meal_price);
    if (Quantity > 1) {
      if(bookingDetails?.length === 0 && bookingType === 1){
        handleChangeBookingType("",2);
      }
      let discountFlat = discountOnePrice;
      if (discountType === 2) {
        discountFlat = +(+data?.discountOnePrice / Quantity).toFixed(2);
      }

      // console.log(discountFlat, discountType,discountOnePrice,Quantity,
      //   data?.discountOnePrice/Quantity,932);
      let discountedTotalPrice = +(
        +days *
        (+price +
          mealPrice +
          addOnPrice -
          (discountFlat + +discountOneAdult + +discountOneChild) +
          AdultOnePrice +
          ChildOnePrice)
      ).toFixed(2);

      for (var i = 1; i <= Quantity; i++) {
        const item: any = {
          roomQty: 1,
          noOfAdult: selectAdult,
          noOfDefaultAdult: selectDefaultAdult,
          noOfChild: selectChild,
          noOfDefaultChild: selectDefaultChild,
          min_adult: min_Occupancy,
          startDate: searchFilters.startDate,
          endDate: searchFilters.endDate,
          nights: days,
          data: data,
          roomRateName: data?.rateDetails?.find(
            (el) => +el?.category_rate_type_id == +rateTypeId
          )?.name,
          // roomRateName: rateDet.name,
          discountAdult: +(+days * AdultOnePrice).toFixed(2),
          discountChild: +(+days * ChildOnePrice).toFixed(2),
          // discountAdult: discountAdult,
          // discountChild: discountChild,
          seasonId: data?.rateDetails?.find(
            (el) => +el?.category_rate_type_id == +rateTypeId
          )?.id,
          rateId: rateTypeId,
          discountedPrice: discountedPrice,
          discountPrice: +(+days * (+discountPrice + mealPrice)).toFixed(2),
          discountedTotalPrice: +(
            +days *
            (+price +
              mealPrice +
              addOnPrice -
              (discountFlat + +discountOneAdult + +discountOneChild) +
              AdultOnePrice +
              ChildOnePrice)
          ).toFixed(2),
          discountedTotalPriceWithTax: +(
            ((+days *
              (+price +
                mealPrice +
                addOnPrice -
                (discountFlat + +discountOneAdult + +discountOneChild) +
                AdultOnePrice +
                ChildOnePrice)) /
              100) *
              (Number(taxRateData) - Number(taxExemptRateData)) +
            +days *
              (+price +
                mealPrice +
                addOnPrice -
                (discountFlat + +discountOneAdult + +discountOneChild) +
                AdultOnePrice +
                ChildOnePrice)
          ).toFixed(2),
          price: +(+days * (+price + mealPrice)).toFixed(2),
          AvgPricePerRoom: +(+price).toFixed(2),
          discount_type: discountType,
          discount: +(
            +days *
            (discountFlat + +discountOneAdult + +discountOneChild)
          ).toFixed(2),
          discountValue: discountValue,
          discountOnePrice:
            +days * (discountFlat + +discountOneAdult + +discountOneChild),
          discounted_rate: discounted_rate,
          mealPrice: mealPrice,
          addOnTotal: +days * addOnTotal,
          addOnPrice: +days * addOnPrice,
          discounted_selected: discounted_selected,
          discount_percentage: discount_percentage,
          cancelationPolicy: data?.rateDetails?.find(
            (el) => +el?.category_rate_type_id == +rateTypeId
          )?.rateTypeDetail?.cancellationPolicyDetails?.name,
          cancelationPolicyId: data?.rateDetails?.find(
            (el) => +el?.category_rate_type_id == +rateTypeId
          )?.rateTypeDetail?.cancellationPolicyDetails?.id,
          //  +rateDet.cancelation_policy,
          roomType: data.category_name,
          roomTypeId: data.category_id,
          roomNo:
            // availableRooms[i-1]?.id,
            state?.selectedDate === undefined
              ? availableRooms[i - 1]?.id
              : catid?.length !== 0
              ? catid?.length >= i
                ? catid[catid?.length - i]?.room_id
                : availableRooms[i - 1]?.id
              : catid?.length === 0
              ? availableRooms[i - 1]?.id
              : catid[catid?.length - i]?.room_id,
          availableRooms: availableRooms,
          availableRoomsNew: availableRooms,
          withMeal: addOnLists,
          primaryGuest: 1,
          guestDialog: false,
          groupGuestInfo: { 
            user_id: "",
            name: "",
            phone: "",
            phone_code: "+1",
            email: "",
            id_number: "",
            id_proof_type: "",
            id_proof: "",
            image: "",
            address: "",
            tax_exempt: 0,
            tax_id: "",
            state_id: "",
            country_id: "",
            city_id: "",
            postal_code: "",
            iata_number:"",
            tax_id_data:[],
            tax_exempt_data: exemptTaxData?.map((tax)=>({
              tax_exempt_id: tax?.id,
              tax_exempt_amount : ((discountedTotalPrice/100)* +tax?.tax_rate)
            })),
            tax_exempt_num: 12345,
          },

        };
        setBookingDetails((oldArray) => [...oldArray, item]);
      }
      toast.success(t("Rooms Added Successfully"));
    } else {
      // console.log("SELECT ELSE PART");
      let discountedTotalPrice = +(
        +days *
        (+price +
          mealPrice +
          addOnPrice -
          (discountOnePrice + +discountOneAdult + +discountOneChild) +
          AdultOnePrice +
          ChildOnePrice)
      ).toFixed(2);

      const item: any = {
        roomQty: Quantity,
        noOfAdult: selectAdult,
        noOfDefaultAdult: selectDefaultAdult,
        noOfChild: selectChild,
        noOfDefaultChild: selectDefaultChild,
        noOfExtraAdult: selectAdult,
        min_adult: min_Occupancy,
        startDate: searchFilters.startDate,
        endDate: searchFilters.endDate,
        nights: days,
        data: data,
        roomRateName: data?.rateDetails?.find(
          (el) => +el?.category_rate_type_id == +rateTypeId
        )?.name,
        // data?.rateDetails?.map(
        //   (item, i) =>
        //     item?.rateTypeDetail?.name +
        //     (i === data?.rateDetails?.length - 1 ? "" : ", ")
        // ),
        // rateDet.name,
        seasonId: data?.rateDetails?.find(
          (el) => +el?.category_rate_type_id == +rateTypeId
        )?.id,
        rateId: rateTypeId,
        // discountAdult: discountAdult,
        // discountChild: discountChild,
        discountAdult: +(+days * AdultOnePrice).toFixed(2),
        discountChild: +(+days * ChildOnePrice).toFixed(2),
        discountedPrice: discountedPrice,
        discountPrice: +(+days * (+discountPrice + mealPrice)).toFixed(2),
        discountedTotalPrice: +(
          +days *
          (+price +
            mealPrice +
            addOnPrice -
            (discountOnePrice + +discountOneAdult + +discountOneChild) +
            AdultOnePrice +
            ChildOnePrice)
        ).toFixed(2),
        discountedTotalPriceWithTax: +(
          ((+days *
            (+price +
              mealPrice +
              addOnPrice -
              (discountOnePrice + +discountOneAdult + +discountOneChild) +
              AdultOnePrice +
              ChildOnePrice)) /
            100) *
            (+taxRateData - +taxExemptRateData) +
          +days *
            (+price +
              mealPrice +
              addOnPrice -
              (discountOnePrice + +discountOneAdult + +discountOneChild) +
              AdultOnePrice +
              ChildOnePrice)
        ).toFixed(2),
        price: +(+days * (+price + mealPrice)).toFixed(2),
        AvgPricePerRoom: +price,
        discount_type: discountType,
        discount: +(
          +days *
          (discountOnePrice + +discountOneAdult + +discountOneChild)
        ).toFixed(2),
        discountValue: discountValue,
        addOnTotal: +days * addOnTotal,
        addOnPrice: +days * addOnPrice,
        discountOnePrice: +(
          +days *
          (+discountOnePrice + +discountOneAdult + +discountOneChild)
        ).toFixed(2),
        discounted_rate: discounted_rate,
        mealPrice: mealPrice,
        discounted_selected: discounted_selected,
        discount_percentage: discount_percentage,

        cancelationPolicy: data?.rateDetails?.find(
          (el) => +el?.category_rate_type_id == +rateTypeId
        )?.rateTypeDetail?.cancellationPolicyDetails?.name,
        cancelationPolicyId: data?.rateDetails?.find(
          (el) => +el?.category_rate_type_id == +rateTypeId
        )?.rateTypeDetail?.cancellationPolicyDetails?.id,
        //  +rateDet.cancelation_policy,
        roomType: data.category_name,
        roomTypeId: data.category_id,
        roomNo:
          // availableRooms[0]?.id,
          state?.selectedDate === undefined
            ? availableRooms[
                bookingDetail?.filter(
                  (item) => item?.roomTypeId === data.category_id
                )?.length
              ]?.id
            : catid?.length !== 0
            ? catid[0]?.room_id
            : availableRooms[
                bookingDetail?.filter(
                  (item) => item?.roomTypeId === data.category_id
                )?.length
              ]?.id,
        // : availableRooms[0]?.id,
        availableRooms: availableRooms,
        availableRoomsNew: availableRooms,
        withMeal: addOnLists,
        primaryGuest: 1,
        guestDialog: false,
        groupGuestInfo: { 
          user_id:"",
          name: "",
          phone: "",
          email: "",
          phone_code: "+1",
          id_number: "",
          id_proof_type: "",
          id_proof: "",
          image: "",
          address: "",
          tax_exempt: 0,
          tax_id: "",
          state_id: "",
          country_id: "",
          city_id: "",
          postal_code: "",
          iata_number:"",
          tax_id_data:[],
          tax_exempt_data: exemptTaxData?.map((tax)=>({
            tax_exempt_id: tax?.id,
            tax_exempt_amount : ((discountedTotalPrice/100)* +tax?.tax_rate)
          })),
          tax_exempt_num: 12345,
        },
      };

      setBookingDetails((oldArray) => [...oldArray, item]);
      toast.success(t("Room Added Successfully"));
    }

    setRoomQutSelectedValue(1);
  };

  const handleRemoveRoomItem = (data, index) => {
    const temp = [...bookingDetails];
    temp.splice(index, 1);
    setBookingDetails(temp);
    if(temp?.length === 1 && bookingType === 2){
      handleChangeBookingType("",1)
    }
    setCategoryList(
      categoryList.map((item, index1) =>
        item.category_id === data.roomTypeId
          ? {
              ...item,
              available_rooms:
                categoryList[index1]["available_rooms"] + data.roomQty,
            }
          : item
      )
    );
    toast.success(t("Rooms Removed Successfully"));
  };

  const handleClearBookings = () => {
    setBookingDetails([]);
    getBookingRates();
  };

  const handleChangeBooking = () => {
    setTabValue(BookingTabs.Confirm);
  };

  // const getRateById = (rateArr, id) => {
  //   const arr = rateArr.filter((item, index) => {
  //     return item.id === id;
  //   });
  //   let dayDiff = daysBetween(
  //     new Date(searchFilters.startDate),
  //     new Date(searchFilters.endDate)
  //   );
  //   let price = getAveragePrice(
  //     arr[0]["categoryRateDetail"],
  //     dayDiff,
  //     searchFilters.startDate
  //   );
  //   return price;
  // };

  const getExtraAdultById = (rateArr, id, rateId) => {
    // console.log(rateArr,id, 1081)
    // const arr = rateArr.filter((item, index) => {
    //   return item.id == id;
    // });
    let dayDiff = daysBetween(
      new Date(searchFilters.startDate),
      new Date(searchFilters.endDate)
    );
    let price = getAdultAveragePrice(
      rateArr,
      dayDiff,
      searchFilters.startDate,
      rateId
    );
    return price;
  };

  const getExtraChildById = (rateArr, id, rateId) => {
    // const arr = rateArr.filter((item, index) => {
    //   return item.id == id;
    // });
    let dayDiff = daysBetween(
      new Date(searchFilters.startDate),
      new Date(searchFilters.endDate)
    );
    let price = getChildAveragePrice(
      rateArr,
      dayDiff,
      searchFilters.startDate,
      rateId
    );
    return price;
  };

  useEffect(()=>{
    if(+bookingDetailsStats?.noOfAdults + +bookingDetailsStats?.noOfChild > searchFilters?.noOfGuest){
    setSearchFilters((prevState) => (({
      ...prevState,
      noOfGuest: +bookingDetailsStats?.noOfAdults + +bookingDetailsStats?.noOfChild})));}
  },[bookingDetailsStats?.noOfAdults,bookingDetailsStats?.noOfChild ])

  useEffect(() => {
    var noOfRoom = 0;
    var noOfAdults = 0;
    var noOfExtraAdult = 0;
    var noOfChild = 0;
    var noOfExtraChild = 0;
    var totalAmount = 0;
    var discountedTotal = 0;
    var addOnTotal = 0;
    var finslTotal = 0;
    var extraAdult = 0;
    var extraChild = 0;
    // var taxAmount = 0;
    var totalTax = 0;
    let taxList = [];
    // console.log(bookingDetails, 1205)
    bookingDetails.forEach((item: any) => {
      noOfRoom += item.roomQty;
      noOfAdults += +item?.noOfAdult + +item?.noOfDefaultAdult;
      noOfChild += +item?.noOfChild + +item?.noOfDefaultChild;
      noOfExtraAdult += item?.noOfAdult;
      noOfExtraChild += item?.noOfChild;
      totalAmount += Number(item.roomQty) * Number(item.price);
      discountedTotal += +item.discountOnePrice;
      addOnTotal += +item.addOnPrice;
      extraAdult += +item.discountAdult;
      extraChild += +item.discountChild;
      totalTax =
        ((+totalAmount +
          +extraAdult +
          +addOnTotal +
          +extraChild -
          +discountedTotal) /
          100) *
        Number(taxRateData);
      finslTotal =
        Number(totalAmount) +
        Number(extraAdult) +
        extraChild -
        discountedTotal +
        totalTax;
      // bookingDetailsStats.taxAmount;
      // console.log("totalTax:", totalTax, "finslTotal:", finslTotal, 1841 )
    });

    for (var j in exemptTaxData) {
      taxList.push({
        tax_exempt_id: exemptTaxData[j].id,
        tax_exempt_amount:
          ((totalAmount +
            extraAdult +
            addOnTotal +
            extraChild -
            discountedTotal) /
            100) *
          exemptTaxData[j].tax_rate,
      });
    }
    setSelectedTax(taxList);

    var sum = 0;
    // taxList.forEach((item) => {
    //   sum += +item.tax_exempt_amount;
    // });
    bookingDetails?.forEach((item) => {
      item?.groupGuestInfo?.tax_exempt_data?.forEach((tax)=>{
        sum += +tax?.tax_exempt_amount
      })
    })
    // console.log("sum:", sum, 1841)

    setBookingDetailsStats((prev) => ({ ...prev, noOfRooms: noOfRoom }));
    setBookingDetailsStats((prev) => ({ ...prev, noOfAdults: noOfAdults }));
    setBookingDetailsStats((prev) => ({ ...prev, noOfChild: noOfChild }));
    setBookingDetailsStats((prev) => ({
      ...prev,
      noOfExtraAdult: noOfExtraAdult,
    }));
    setBookingDetailsStats((prev) => ({
      ...prev,
      noOfExtraChild: noOfExtraChild,
    }));
    setBookingDetailsStats((prev) => ({ ...prev, totalAmount: totalAmount }));
    setBookingDetailsStats((prev) => ({ ...prev, addOnTotal: addOnTotal }));
    setBookingDetailsStats((prev) => ({ ...prev, extraAdult: extraAdult }));
    setBookingDetailsStats((prev) => ({ ...prev, extraChild: extraChild }));

    setBookingDetailsStats((prev) => ({ ...prev, taxAmount: sum }));
    setBookingDetailsStats((prev) => ({
      ...prev,
      discountedTotal: discountedTotal,
    }));
    setBookingDetailsStats((prev) => ({
      ...prev,
      totalTax: totalTax - sum < 0 ? 0 : totalTax - sum,
    }));
    setBookingDetailsStats((prev) => ({
      ...prev,
      finslTotal: finslTotal + addOnTotal - (totalTax - sum < 0 ? 0 : sum),
    }));
  }, [bookingDetails]);
  //Book Room Component end

  // useEffect(()=>{
  //   console.log(bookingDetailsStats);
  // },[bookingDetailsStats]);

  //add info component start
  const addGuestInitialState = {
    user_id: 0,
    name:
      guestInfo.name === null ||
      guestInfo.name === "undefined" ||
      guestInfo.name === undefined
        ? ""
        : guestInfo.name,
    phone:
      guestInfo.phone === null ||
      guestInfo.phone === "undefined" ||
      guestInfo.phone === undefined
        ? ""
        : guestInfo.phone,
    phone_code:
      guestInfo.phone_code === null ||
      guestInfo.phone_code === "undefined" ||
      guestInfo.phone_code === undefined
        ? ""
        : guestInfo.phone_code,
    email:
      guestInfo.email === null ||
      guestInfo.email === "undefined" ||
      guestInfo.email === undefined
        ? ""
        : guestInfo.email,
    id_number:
      guestInfo.id_number === null ||
      guestInfo.id_number === "undefined" ||
      guestInfo.id_number === undefined
        ? ""
        : guestInfo.id_number,
    id_proof_type:
      guestInfo.id_proof_type === null ||
      guestInfo.id_proof_type === "undefined" ||
      guestInfo.id_proof_type === undefined
        ? ""
        : guestInfo.id_proof_type,
    id_proof:
      guestInfo.id_proof === null ||
      guestInfo.id_proof === "undefined" ||
      guestInfo.id_proof === undefined
        ? ""
        : guestInfo.id_proof,
    image:
      guestInfo.image === null ||
      guestInfo.image === "undefined" ||
      guestInfo.image === undefined
        ? ""
        : guestInfo.image,
    address:
      guestInfo.address === null ||
      guestInfo.address === "undefined" ||
      guestInfo.address === undefined
        ? ""
        : guestInfo.address,
    tax_exempt:
      guestInfo.tax_exempt === null ||
      guestInfo.tax_exempt === "undefined" ||
      guestInfo.tax_exempt === undefined
        ? ""
        : guestInfo.tax_exempt,
    tax_id:
      guestInfo.tax_id === null ||
      guestInfo.tax_id === "undefined" ||
      guestInfo.tax_id === undefined
        ? ""
        : guestInfo.tax_id,
    iata_number:
      guestInfo.iata_number === null ||
      guestInfo.iata_number === "undefined" ||
      guestInfo.iata_number === undefined
        ? ""
        : guestInfo.iata_number,
    company_profile_name:
      guestInfo.company_profile_name === null ||
      guestInfo.company_profile_name === "undefined" ||
      guestInfo.company_profile_name === undefined
        ? ""
        : guestInfo.company_profile_name,
    corporateID:
      guestInfo.corporateID === null ||
      guestInfo.corporateID === "undefined" ||
      guestInfo.corporateID === undefined
        ? ""
        : guestInfo.corporateID,
    country_id:
      guestInfo.country_id === null ||
      guestInfo.country_id === "undefined" ||
      guestInfo.country_id === undefined
        ? ""
        : guestInfo.country_id,
    state_id:
      guestInfo.state_id === null ||
      guestInfo.state_id === "undefined" ||
      guestInfo.state_id === undefined
        ? ""
        : guestInfo.state_id,
    city_id:
      guestInfo.city_id === null ||
      guestInfo.city_id === "undefined" ||
      guestInfo.city_id === undefined
        ? ""
        : guestInfo.city_id,
    postal_code:
      guestInfo.postal_code === null ||
      guestInfo.postal_code === "undefined" ||
      guestInfo.postal_code === undefined
        ? ""
        : guestInfo.postal_code,
    tax_exempt_num: 12345,
    // guestInfo.tax_exempt_num === null ||
    // guestInfo.tax_exempt_num === "undefined" ||
    // guestInfo.tax_exempt_num === undefined
    //   ? ""
    //   : guestInfo.tax_exempt_num,
    tax_id_data:
      guestInfo.tax_exempt_num === null ||
      guestInfo.tax_exempt_num === "undefined" ||
      guestInfo.tax_exempt_num === undefined
        ? []
        : guestInfo.tax_id_data,
    
};

  const addGuestHandleSubmit = async (values) => {
    let bodyFormData = {
      name: values.name,
      phone: values.phone,
      email: values.email,
      phone_code: values.phone_code,
      id_number: values.id_number,
      id_proof_type: values.id_proof_type,
      id_proof: values.id_proof,
      image: values.id_proof,
      address: values.address,
      tax_exempt: values.tax_exempt,
      tax_id: values.tax_id,
      state_id: values.state_id,
      country_id: values.country_id,
      city_id: values.city_id,
      postal_code: values.postal_code,
    };
    setGuestInfo(bodyFormData);
  };

  const [selectedRoom, setSelectedRoom] = useState([]);

  const handleRoomNumber = (e, setFieldValue, indexs, data) => {
    const data1 = bookingDetailsInitialState?.bookingDetailsInfo?.find(
      (item, index) => index === indexs
    );
    // const data2 = bookingDetailsInitialState?.bookingDetailsInfo?.filter(
    //   (item, index) => item.roomType === data1.roomType
    // );
    data1.roomNo = parseInt(e.target.value);
    const selectedRooms = [];

    for (var j in bookingDetailsInitialState?.bookingDetailsInfo) {
      if (bookingDetailsInitialState?.bookingDetailsInfo[j].roomNo !== 0) {
        selectedRooms.push(
          bookingDetailsInitialState?.bookingDetailsInfo[j].roomNo
        );
      }
      setSelectedRoom(selectedRooms);
    }

    for (var t in selectedRoom) {
      if (selectedRoom[t] === e.target.value) {
        data1.roomNo = 0;
        setFieldValue(`bookingDetailsInfo.${indexs}.roomNo`, 0);
        toast.error(
          ` This Room is Already selected for this Romm Type ${data?.roomType}`
        );
        return;
      } else {
        data1.roomNo = parseInt(e.target.value);
        setFieldValue(`bookingDetailsInfo.${indexs}.roomNo`, e.target.value);
      }
    }
  };



  const [openSendSmsEmailDialog, setOpenSendSmsEmailDialog] = useState(false);

  const createBooking = async (send_sms) => {
    setOpenSendSmsEmailDialog(false);
    // console.log(bookingDetails.length, 2060);

    if (bookingDetails.length === 0) {
      toast.error(
        t("To confirm the reservation, you must select at least one room in the first step")
      );
      return;
    };

    if(bookingType === 2 && bookingDetails.length < 2){
      toast.error(
        t("To confirm the reservation For Group Booking, you must select at least two room in the first step")
      );
      return;
    }

    if (
      +bookingDetailsStats?.noOfAdults + +bookingDetailsStats?.noOfChild <
      +searchFilters?.noOfGuest
    ) {
      toast.error(
        `${t("To confirm the reservation for")} ${
          searchFilters?.noOfGuest
        } ${t("Guests, you must Add More Rooms Or Add")} ${
          +searchFilters?.noOfGuest -
          (+bookingDetailsStats?.noOfAdults + +bookingDetailsStats?.noOfChild)
        } Adults`
      );
      return;
    }

    if (guestInfo.name === "") {
      toast.error(
        t("To confirm the reservation, you must Add User in the Second step")
      );
      return;
    }

    if (!guestInfo.name?.includes(" ")) {
      toast.error(
        t("To confirm the reservation, you must add last name in the Second step")
      );
      return;
    }

    if(guestInfo?.phone=== ""){
      toast.error(
        t("To confirm the reservation, you must Add User Phone in the Second step")
      );
      return;
    }

    if(guestInfo?.email=== ""){
      toast.error(
        t("To confirm the reservation, you must Add User email in the Second step")
      );
      return;
    }
    if(!(guestInfo?.email.includes("@") && guestInfo?.email.includes("."))){
      toast.error(
        t("To confirm the reservation, you must Add User correct email in the Second step")
      );
      return;
    }

    if(bookingDetailsInitialState?.bookingDetailsInfo?.filter((item)=>item?.primaryGuest === 1)?.length === 0){
      toast.error(t("To confirm the reservation, you must need to select aleast one primary user"));
      return;
    }
    let validationError = false
    bookingDetailsInitialState?.bookingDetailsInfo?.filter((item)=>item?.primaryGuest === 0)?.forEach((item)=> {
      if(item?.groupGuestInfo?.name === ""){
        toast.error(
         ` ${t("To confirm the reservation, you must Add Name in the Secondary user of")} ${item?.availableRooms?.find((sitem)=>sitem?.id === item?.roomNo)?.room_name}`
        );
        validationError = true;
        return;
      }
     
      if(!item?.groupGuestInfo?.name.includes(" ")){
        toast.error(
          `${t("To confirm the reservation, you must Add Last Name in the Secondary user of")} ${item?.availableRooms?.find((sitem)=>sitem?.id === item?.roomNo)?.room_name}`
        );
        validationError = true;

        return;
      }
      if(item?.groupGuestInfo?.phone === ""){
        toast.error(
          `${t("To confirm the reservation, you must Add Phone in the Secondary user of")} ${item?.availableRooms?.find((sitem)=>sitem?.id === item?.roomNo)?.room_name}`
        );
        validationError = true;

        return;
      }
      if(item?.groupGuestInfo?.email === ""){
        toast.error(
          `${t("To confirm the reservation, you must Add Email in the Secondary user of")} ${item?.room_name}`
        );
        validationError = true;

        return;
      }

      if(!(item?.groupGuestInfo?.email.includes("@") && item?.groupGuestInfo?.email.includes("."))){
        toast.error(
          t("To confirm the reservation, you must Add User correct email in the Second step")
        );
        validationError = true;
        return;
      }
    })

    if(validationError){
      return;
    }

    if (card_present === false) {
      if (paymentType === 2 && verifyToken === "") {
        toast.error(t("To confirm the reservation, you must Verify Card"));
        return;
      }
    }

    if (card_present === true && selectPinpadDevices === 0) {
      toast.error(t("To confirm the reservation, you must select Pinpad Device"));
      return;
    }

    for (var j in bookingDetailsInitialState?.bookingDetailsInfo) {
      if (bookingDetailsInitialState?.bookingDetailsInfo[j].roomNo === 0) {
        toast.error(t("To confirm the reservation, you must select Room Number"));
        return;
      }
    }

    var userData = new FormData();
    userData.append("name", guestInfo?.name);
    userData.append("phone", guestInfo?.phone);
    userData.append("email", guestInfo?.email);
    userData.append("id_number", guestInfo?.id_number);
    userData.append("id_proof_type", guestInfo?.id_proof_type || 0);
    userData.append("id_proof", guestInfo?.id_proof);
    userData.append("address", guestInfo?.address);
    userData.append("phone_code", guestInfo?.phone_code);
    userData.append("country", guestInfo?.country_id);
    userData.append("state", guestInfo?.state_id);
    userData.append("city", guestInfo?.city_id);
    userData.append("pin_code", guestInfo?.postal_code);
    // let userData = {
    //   name: guestInfo?.name,
    //   phone: guestInfo?.phone,
    //   email: guestInfo?.email,
    //   id_number: guestInfo?.id_number,
    // };
    setLoading(true);
    const result = await createRecord(userData, ApiEndPoints.ADD_USER);
    if (result?.status === 200){
      // toast.success(t(result.message));
      if(bookingType === 1){
      const roomItems: any =
        bookingDetailsInitialState?.bookingDetailsInfo?.map(
          (item: any, index) => {
            return {
              user_id: result.data.id,
              room_category_id: item.roomTypeId,
              number_of_room: 1,
              no_of_adults:
                item?.noOfDefaultAdult === "" ? 0 : item?.noOfDefaultAdult,
              no_of_extra_adults: item?.noOfAdult,
              no_of_children:
                item?.noOfDefaultChild === "" ? 0 : item?.noOfDefaultChild,
              no_of_extra_children: item?.noOfChild,
              no_of_pets: 0,
              including_meal: 0,
              room_rate_id: item.rateId,
              season_id: item?.seasonId,
              discounted_rate_id: String(item?.discounted_selected)?.includes(
                "-"
              )
                ? 0
                : Math.trunc(+item.discounted_selected),
              // +item.discount_type === 1
              //   ? Math.trunc(+item.discounted_selected)
              //   : 0,
              discount_type: item.discount_type,
              discount: item.discountOnePrice,
              room_no: item.roomNo,
              addons: item.withMeal,
              discount_percentage: item.discount_percentage,
              is_tax_exempt: guestInfo.tax_exempt,
              tax_exempt_data: item?.groupGuestInfo?.tax_exempt_data,
            };
          }
        );
      let bookingData = {
        user_id: result.data.id,
        hotel_id: hotelId,
        login_user_id: loginUserId,
        booking_type: bookingType,
        payment_type: paymentType,
        token: verifyToken,
        credit_card_id:
          card_present === true && paymentType === 2 ? "0" : card?.id,
        bank_name: card?.card_holder_name,
        iata_number: iataNumber,
        no_of_adults: bookingDetailsStats.noOfAdults,
        no_of_children: bookingDetailsStats.noOfChild,
        no_of_pets: 0,
        no_of_extra_persons: 0,
        // is_tax_exempt: guestInfo.tax_exempt,
        card_present: card_present === true ? 1 : 0,
        device_id: selectPinpadDevices,
        guarantee_policy: guaranteePolicy,
        booking_source: 1,
        cancel_policy: cancelPolicy,
        checkin_date: searchFilters.startDate,
        checkout_date: searchFilters.endDate,
        room_details: roomItems,
        // tax_exempt_data: selectedTax,
        send_sms: send_sms,
        season_id: bookingRates[0]?.rateDetails?.find(
          (el) => +el?.category_rate_type_id == +roomRateId
        )?.id,
      };
      const bookingResult = await createRecord(
        bookingData,
        ApiEndPoints.CREATE_BOOKING + `?hotel_id=${hotelId}`
      );
      if (bookingResult?.status === 200) {
        setLoading(false);
        setVerifyToken("");
        toast.success(t(bookingResult?.message));
        navigate("/dashboard");
      } else if (bookingResult?.status === 400) {
        setLoading(false);
        toast.error(t(bookingResult?.message));
      } else if (bookingResult === undefined) {
        setLoading(false);
        toast.error(t(bookingResult?.message));
      } else {
        setLoading(false);
        toast.error(t(bookingResult?.message));
      }
    } else if(bookingType === 2){

      const roomItems: any = bookingDetailsInitialState?.bookingDetailsInfo?.filter((item)=>item?.primaryGuest === 1)?.map(
        (item: any, index) => {
          return {
            user_id: result.data.id,
            room_category_id: item.roomTypeId,
            number_of_room: 1,
            no_of_adults:
              item?.noOfDefaultAdult === "" ? 0 : item?.noOfDefaultAdult,
            no_of_extra_adults: item?.noOfAdult,
            no_of_children:
              item?.noOfDefaultChild === "" ? 0 : item?.noOfDefaultChild,
            no_of_extra_children: item?.noOfChild,
            no_of_pets: 0,
            including_meal: 0,
            room_rate_id: item.rateId,
            season_id: item?.seasonId,
            discounted_rate_id: String(item?.discounted_selected)?.includes(
              "-"
            )? 0
              : Math.trunc(+item.discounted_selected),
            discount_type: item.discount_type,
            discount: item.discountOnePrice,
            room_no: item.roomNo,
            addons: item.withMeal,
            discount_percentage: item.discount_percentage,
            is_tax_exempt: guestInfo.tax_exempt,
            tax_exempt_data: item?.groupGuestInfo?.tax_exempt_data,
          };
        }
      );
      if(roomItems?.length === 0){
        toast.error(t("To confirm the reservation, you must need to select aleast one primary user"));
        return;
      }
      let useraddedUser = true;
     
        const promises = bookingDetailsInitialState?.bookingDetailsInfo?.filter((item)=>item?.primaryGuest === 0)?.map(async (item)=>{
          var userData = new FormData();
          userData.append("name", item?.groupGuestInfo?.name);
          userData.append("phone", item?.groupGuestInfo?.phone);
          userData.append("email", item?.groupGuestInfo?.email);
          userData.append("id_number", item?.groupGuestInfo?.id_number);
          userData.append("id_proof_type", item?.groupGuestInfo?.id_proof_type || 0);
          userData.append("id_proof", item?.groupGuestInfo?.id_proof);
          userData.append("address", item?.groupGuestInfo?.address);
          userData.append("phone_code", item?.groupGuestInfo?.phone_code);
          userData.append("country", item?.groupGuestInfo?.country_id);
          userData.append("state", item?.groupGuestInfo?.state_id);
          userData.append("city", item?.groupGuestInfo?.city_id);
          userData.append("pin_code", item?.groupGuestInfo?.postal_code);
          const resultGuest = await createRecord(userData, ApiEndPoints.ADD_USER);
          if (resultGuest?.status === 200){
            roomItems?.push({
              user_id: resultGuest.data.id,
              room_category_id: item.roomTypeId,
              number_of_room: 1,
              no_of_adults:
                item?.noOfDefaultAdult === "" ? 0 : item?.noOfDefaultAdult,
              no_of_extra_adults: item?.noOfAdult,
              no_of_children:
                item?.noOfDefaultChild === "" ? 0 : item?.noOfDefaultChild,
              no_of_extra_children: item?.noOfChild,
              no_of_pets: 0,
              including_meal: 0,
              room_rate_id: item.rateId,
              season_id: item?.seasonId,
              discounted_rate_id: String(item?.discounted_selected)?.includes(
                "-"
              )? 0
                : Math.trunc(+item.discounted_selected),
              discount_type: item.discount_type,
              discount: item.discountOnePrice,
              room_no: item.roomNo,
              addons: item.withMeal,
              discount_percentage: item.discount_percentage,
              is_tax_exempt: item?.groupGuestInfo?.tax_exempt,
              tax_exempt_data: item?.groupGuestInfo?.tax_exempt_data,
            })
          } else {
            setLoading(false);
            useraddedUser = false;
            toast.error(t(t(result.message)));
            return;
           }
        });

        try {
          await Promise.all(promises);
          if (!useraddedUser){
            return;
          }
          let bookingData = {
            user_id: result.data.id,
            hotel_id: hotelId,
            login_user_id: loginUserId,
            booking_type: bookingType,
            payment_type: paymentType,
            token: verifyToken,
            credit_card_id:
              card_present === true && paymentType === 2 ? "0" : card?.id,
            bank_name: card?.card_holder_name,
            iata_number: iataNumber,
            no_of_adults: bookingDetailsStats.noOfAdults,
            no_of_children: bookingDetailsStats.noOfChild,
            no_of_pets: 0,
            no_of_extra_persons: 0,
            // is_tax_exempt: guestInfo.tax_exempt,
            card_present: card_present === true ? 1 : 0,
            device_id: selectPinpadDevices,
            guarantee_policy: guaranteePolicy,
            booking_source: 1,
            cancel_policy: cancelPolicy,
            checkin_date: searchFilters.startDate,
            checkout_date: searchFilters.endDate,
            room_details: roomItems,
            // tax_exempt_data: selectedTax,
            send_sms: send_sms,
            season_id: bookingRates[0]?.rateDetails?.find(
              (el) => +el?.category_rate_type_id == +roomRateId
            )?.id,
          };
          const bookingResult = await createRecord(
            bookingData,
            ApiEndPoints.CREATE_BOOKING + `?hotel_id=${hotelId}`
          );
          if (bookingResult?.status === 200) {
            setLoading(false);
            setVerifyToken("");
            toast.success(t(bookingResult?.message));
            navigate("/dashboard");
          } else if (bookingResult?.status === 400) {
            setLoading(false);
            toast.error(t(bookingResult?.message));
          } else if (bookingResult === undefined) {
            setLoading(false);
            toast.error(t(bookingResult?.message));
          } else {
            setLoading(false);
            toast.error(t(bookingResult?.message));
          }
        } catch (error) {
          setLoading(false);
          console.log(error);

        }
 
    }
    } else {
      setLoading(false);
      toast.error(t(result.message));
    }
  };

  //add info component end
  const [tabValue, setTabValue] = React.useState(BookingTabs.BookRoom);
  const handleTabChange = (
    event: React.SyntheticEvent,
    newTabValue: BookingTabs
  ) => setTabValue(newTabValue);

  const handleNextTabChange = (newTabValue: BookingTabs) => {
    if (newTabValue === "book-a-room") {
      setTabValue(BookingTabs.AddInfo);
    } else if (newTabValue === "add-info") {
      setTabValue(BookingTabs.Confirm);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling animation
    });
  };
  const handlePreviousTabChange = (newTabValue: BookingTabs) => {
    if (newTabValue === "add-info") {
      setTabValue(BookingTabs.BookRoom);
    } else if (newTabValue === "confirm") {
      setTabValue(BookingTabs.AddInfo);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling animation
    });
  };

  // Add On API and Funcnality
  // useEffect(() => {
  //   if (hotelId) {
  //     getAddOnList();
  //   }
  // }, [hotelId]);
  // const [mealOption, setVariantName] = React.useState([]);
  const [addOnList, setAddOnList] = React.useState([
    {
      name: "",
      amount: "",
      id: 0,
    },
  ]);

  const getAddOnList = async () => {
    setLoading(true);
    const result = await fetchList(
      ApiEndPoints.ROOMS_RESOURCE_ROUTE +
        `/get_room_addon_lists?hotel_id=${hotelId}`
    );
    const addOnLists = [];
    if (result?.status === 200) {
      for (var j in result.data) {
        addOnLists.push({
          name: result.data[j].name,
          amount: result.data[j].amount,
          id: result.data[j].id,
        });
      }
      setAddOnList(addOnLists);
    } else {
      setAddOnList([]);
      setLoading(false);
    }
  };

  const handleMaleOption = (event, setFieldValue, index, dataOld) => {
    // debugger
    const {
      target: { value },
    } = event;
    // debugger
    let dayDiff = daysBetween(
      new Date(searchFilters.startDate),
      new Date(searchFilters.endDate)
    );

    setFieldValue(
      `bookingRateInfo[${index}]['mealOption']`,
      typeof value === "string" ? value.split(",") : value
    );

    // setVariantName(typeof value === "string" ? value.split(",") : value);
    const data = dataOld;

    // bookRoomInitialState?.bookingRateInfo?.find(
    //   (item, index) => index === parseInt(event.target.name.split(".")[1])
    // );

    data.mealOption = typeof value === "string" ? value.split(",") : value;
    const data1 = addOnList.filter((item) =>
      data.mealOption.includes(item.name)
    );
    // const Meals = data1.find((item) => item.name);
    const amount = data1.map((item) => item.amount);

    // console.log(value, data, data1, Meals, amount, 1579)

    // if(data1.length>0){
    //   data.addOnTotal = Add * roomQutSelectedValue;
    // }

    if (amount.length > 0) {
      var sum = 0;
      var Add = 0;
      amount.forEach((item) => {
        sum += parseFloat(item);
      });
      amount.forEach((item) => {
        Add = parseFloat(item) + Add;
      });
      data.addOnTotal = Add * roomQutSelectedValue;
      data.addOnPrice = sum;
      data.totalRateOfRoom =
        dayDiff * (Number(data.discountPrice) + Number(data.addOnTotal));
      data.totalRateWithTax =
        ((dayDiff * (Number(data.discountPrice) + Number(data.addOnTotal))) /
          100) *
          (Number(taxRateData) - Number(taxExemptRateData)) +
        dayDiff * (Number(data.discountPrice) + Number(data.addOnTotal));
      setFieldValue(
        `bookingRateInfo[${index}]['totalRateOfRoom']`,
        data.totalRateOfRoom.toFixed(2)
      );
      setFieldValue(
        `bookingRateInfo[${index}]['totalRateWithTax']`,
        data.totalRateWithTax.toFixed(2)
      );
      setFieldValue(`bookingRateInfo.${index}.addOnTotal`, data.addOnTotal);
      //addOnPrice
      setFieldValue(`bookingRateInfo.${index}.addOnPrice`, data.addOnPrice);
    } else {
      data.addOnTotal = 0;
      data.addOnPrice = 0;
      data.totalRateOfRoom = dayDiff * data.discountPrice;
      data.totalRateWithTax =
        ((dayDiff * data.discountPrice) / 100) *
          (Number(taxRateData) - Number(taxExemptRateData)) +
        dayDiff * data.discountPrice;
      setFieldValue(
        `bookingRateInfo[${index}]['totalRateOfRoom']`,
        dayDiff * data.discountPrice
      );
      setFieldValue(
        `bookingRateInfo[${index}]['totalRateWithTax']`,
        data.totalRateWithTax
      );
      setFieldValue(`bookingRateInfo.${index}.addOnTotal`, data.addOnTotal);
      setFieldValue(`bookingRateInfo.${index}.addOnPrice`, data.addOnPrice);
    }

    //addOnTotal
  };

  // handel discount
  // const [discountType, setDiscountType] = React.useState(2);
  const [discount, setDiscount] = React.useState(0);

  // room quntity
  const [roomQutSelectedValue, setRoomQutSelectedValue] = useState(1);

  const handledefaultSelectedRoom = (event, setFieldValue, index, dataNew) => {
    let dayDiff = daysBetween(
      new Date(searchFilters.startDate),
      new Date(searchFilters.endDate)
    );
    setRoomQutSelectedValue(event.target.value);
    const value = event.target.value;
    const data = dataNew;
    // bookRoomInitialState?.bookingRateInfo?.find(
    //   (item, index) => index === parseInt(event.target.name.split(".")[1])
    // );

    data.QtPrice = +data.price * value;
    let disRate = dataNew.discount_percentage;
    let flat = 0;
    if (+dataNew?.discountType === 2) {
      disRate = 0;
      flat = +dataNew?.discount;
    }

    setFieldValue(`bookingRateInfo.${index}.RoomQuantity`, event.target.value);

    // console.log(1569,disRate, dataNew.discount_type,+dataNew?.discount, ((+data.QtPrice * (100 - disRate)) / 100) - flat  )
    if (dataNew?.discount !== 0) {
      const data1 = addOnList.filter((item) =>
        data.mealOption.includes(item.name)
      );
      const amount = data1.map((item) => item.amount);
      var sum = 0;
      amount.forEach((item) => {
        sum = +item * value;
      });
      const addOn = data1.length > 0 ? data.addOnPrice * value : sum;
      data.addOnTotal = addOn;
      const Price = (
        (data.price * value * (100 - disRate)) / 100 -
        flat
      ).toFixed(2);
      data.discountRoom = +Price;

      const totaldiscount = (+data.QtPrice * (100 - disRate)) / 100 - flat;
      const total = ((data.price * value * disRate) / 100 + flat).toFixed(2);
      data.PriceAdult = Number(data.AdultOnePrice) * value;
      data.PriceChild = Number(data.ChildOnePrice) * value;
      const PriceAdults = (data.PriceAdult * (100 - disRate)) / 100;
      const PriceChilds = (data.PriceChild * (100 - disRate)) / 100;
      data.discountAdult =
        data.PriceAdult === 0 ? data.PriceAdult : PriceAdults;
      data.discountChild =
        data.PriceChild === 0 ? data.PriceChild : PriceChilds;

      const totalAdultdiscount = ((data.AdultOnePrice * disRate) / 100).toFixed(
        2
      );
      data.discountOneAdult = totalAdultdiscount;
      const totalChilddiscount = ((data.ChildOnePrice * disRate) / 100).toFixed(
        2
      );
      data.discountOneChild = totalChilddiscount;

      data.RoomQuantity = value;
      data.discount = total;
      data.discountPrice =
        totaldiscount + Number(data.discountAdult) + Number(data.discountChild);

      data.totalRateOfRoom =
        dayDiff *
        (totaldiscount +
          Number(data.discountAdult) +
          Number(data.discountChild) +
          addOn);
      data.totalRateWithTax =
        ((dayDiff *
          (totaldiscount +
            Number(data.discountAdult) +
            Number(data.discountChild) +
            addOn)) /
          100) *
          (Number(taxRateData) - Number(taxExemptRateData)) +
        dayDiff *
          (totaldiscount +
            Number(data.discountAdult) +
            Number(data.discountChild) +
            addOn);
      setFieldValue(
        `bookingRateInfo.${index}.discountPrice`,
        data.discountPrice
      );
      setFieldValue(
        `bookingRateInfo.${index}.totalRateOfRoom`,
        data.totalRateOfRoom
      );
      setFieldValue(
        `bookingRateInfo.${index}.totalRateWithTax`,
        data.totalRateWithTax
      );

      // console.log(1568, data.totalRateOfRoom);
    } else {
      // console.log(1567, data.totalRateOfRoom);

      const data1 = addOnList.filter((item) =>
        data.mealOption.includes(item.name)
      );
      const amount = data1.map((item) => item.amount);
      var sum = 0;
      amount.forEach((item) => {
        sum = +item * value;
      });
      data.RoomQuantity = value;
      const addOn = data1.length > 0 ? data.addOnPrice * value : sum;
      data.addOnTotal = addOn;
      data.discount = data.discountValue * value;
      const Adult_child =
        value === 1
          ? Number(data.AdultOnePrice) + Number(data.ChildOnePrice)
          : (+data.ChildOnePrice + +data.AdultOnePrice) * value;
      data.PriceAdult = Number(data.AdultOnePrice) * value;
      data.PriceChild = Number(data.ChildOnePrice) * value;
      data.discountPrice = data.price * value + Adult_child;
      data.totalRateOfRoom =
        dayDiff * (data.price * value + Adult_child + addOn);
      data.totalRateWithTax =
        ((dayDiff * (data.price * value + Adult_child + addOn)) / 100) *
          (Number(taxRateData) - Number(taxExemptRateData)) +
        dayDiff * (data.price * value + Adult_child + addOn);
      data.extraTotalPrice = data.price * value + Adult_child;
      data.discountAdult = Number(data.PriceAdult);
      data.discountChild = Number(data.PriceChild);
      // console.log(1568, "else", data.totalRateOfRoom);

      setFieldValue(
        `bookingRateInfo.${index}.discountPrice`,
        data.discountPrice
      );
      setFieldValue(
        `bookingRateInfo.${index}.totalRateOfRoom`,
        data.totalRateOfRoom
      );
      setFieldValue(
        `bookingRateInfo.${index}.totalRateWithTax`,
        data.totalRateWithTax
      );
    }
  };

  // discount rate
  const handleDiscountRate = (e, setFieldValue, index, dataOld) => {
    let dayDiff = daysBetween(
      new Date(searchFilters.startDate),
      new Date(searchFilters.endDate)
    );
    const type = e.target.value;
    // setDiscountType(type);
    setFieldValue(`bookingRateInfo[${index}]['discounted_selected']`, type);

    const data = dataOld;
    // bookRoomInitialState?.bookingRateInfo?.find(
    //   (item, index) => index === parseInt(e.target.name.split(".")[1])
    // );
    // let newBookingRate = bookingRates;
    // console.log(e.target.name, 1967);

    data.discounted_selected = e.target.value;
    let disRate = 0;
    let flat = 0;
    let combainText = "";
    // let categoryData = newBookingRate?.filter((item)=>item?.id === +data?.roomTypeId)[0];
    let seletedValue = e.target.value;

    if (e.target.value === "0") {
      seletedValue = "0";
      disRate = 0;
      combainText = "Select";
      flat = 0;
    } else if (typeof e.target.value === "string") {
      let totalPrice =
        (+getExtraAdultById(data.rateDetails, data?.rateId, data?.rateTypeId) *
          +data?.selectAdult +
          +getExtraChildById(
            data?.rateDetails,
            data?.rateId,
            data?.rateTypeId
          ) *
            +data?.selectChild +
          +getAveragePrice(
            data?.rateDetails,
            dayDiff,
            searchFilters.startDate,
            data?.rateTypeId
          )) *
        dayDiff;
      // console.log(totalPrice,data?.selectAdult,data?.selectChild,newBookingRate,data?.roomTypeId,  1929)
      if (e.target.value.split("-")[0] === "F") {
        if (+e.target.value.split("-")[1] > +totalPrice) {
          toast.error(t("Flat discount can't be greater than total price"));

          seletedValue = 0;
          disRate = 0;
          combainText = t("Flat - 0");
          flat = 0;
        } else {
          disRate = 0;
          combainText =t ("Flat -") + "$" + e.target.value.split("-")[1];
          flat = +e.target.value.split("-")[1];
        }
      } else {
        if (+e.target.value.split("-")[1] > 100) {
          seletedValue = 0;
          toast.error(t("Discount rate can't be greater than 100%"));

          disRate = 0;
          combainText = t("Custom - 0");
        } else {
          disRate = +e.target.value.split("-")[1];
          combainText = t("Custom - ") + e.target.value.split("-")[1] + "%";
        }
      }
    } else {
      disRate =
        discountedRate.filter((item) => item?.id === e.target.value).length > 0
          ? discountedRate.filter((item) => item?.id === e.target.value)[0]
              ?.percentage
          : 0;
      combainText = discountedRate.filter(
        (item) => item?.id === e.target.value
      )[0]?.combine_text;
    }

    // const data1 = discountedRate.find((item) => item.id === e.target.value);

    // console.log(disRate,combainText,flat , 1176);

    // if (data1) {
    data.extraTotalPrice = +(
      +data.QtPrice +
      data.PriceAdult +
      data.PriceChild
    ).toFixed(2);
    // console.log(data, 1625)
    data.discountPrice = +data.extraTotalPrice;
    data.totalRateOfRoom = +(
      dayDiff *
      (data.extraTotalPrice + data.addOnTotal)
    ).toFixed(2);
    data.totalRateWithTax = +(
      ((dayDiff * (data.extraTotalPrice + data.addOnTotal)) / 100) *
        (Number(taxRateData) - Number(taxExemptRateData)) +
      dayDiff * (data.extraTotalPrice + data.addOnTotal)
    ).toFixed(2);
    data.discountOnePrice = 0;
    data.discountValue = 0;
    data.discount = 0;
    data.discountedPrice = 0;
    data.discountAdult = 0;
    data.discountChild = 0;
    data.discountOneAdult = 0;
    data.discountOneChild = 0;
    // if (discountType === 2) {
    setDiscount(disRate);
    const totaldiscount = +((+data.price * disRate) / 100).toFixed(2) + flat;

    const Price = +((data.price * (100 - disRate)) / 100 - flat).toFixed(2);
    const DISCPrice = +((data.extraTotalPrice * (100 - disRate)) / 100).toFixed(
      2
    );
    const PriceAdults = +((data.PriceAdult * (100 - disRate)) / 100).toFixed(2);
    const PriceChilds = +((data.PriceChild * (100 - disRate)) / 100).toFixed(2);

    data.discountAdult =
      data.PriceAdult === 0
        ? +data.PriceAdult
        : +(PriceAdults + data.discountChild).toFixed(2);

    data.discountChild =
      data.PriceChild === 0
        ? +data.PriceChild
        : +(PriceChilds + data.discountAdult).toFixed(2);

    const totalAdultdiscount = +((data.AdultOnePrice * disRate) / 100).toFixed(
      2
    );
    data.discountOneAdult = +totalAdultdiscount;
    const totalChilddiscount = +((data.ChildOnePrice * disRate) / 100).toFixed(
      2
    );
    data.discountOneChild = +totalChilddiscount;

    data.discountValue = disRate;
    data.discount_percentage = disRate;
    data.discountType = flat === 0 ? 1 : 2;
    data.discountOnePrice = +totaldiscount;

    data.discountedPrice = Price;
    data.discountRoom = +(+Price * data.RoomQuantity).toFixed(2);
    data.discount = +(+totaldiscount * data.RoomQuantity).toFixed(2);
    data.discountPrice = DISCPrice - flat;
    data.totalRateOfRoom = +(
      dayDiff * (DISCPrice + data.addOnTotal) -
      flat
    ).toFixed(2);
    data.totalRateWithTax = +(
      ((dayDiff * (DISCPrice + data.addOnTotal) - flat) / 100) *
        (Number(taxRateData) - Number(taxExemptRateData)) +
      (dayDiff * (DISCPrice + data.addOnTotal) - flat)
    ).toFixed(2);
    data.extraTotalPrice = DISCPrice - flat;
    data.discounted_rate = combainText;
    setFieldValue(`bookingRateInfo[${index}]['discountedPrice']`, Price);
    // setFieldValue(`bookingRateInfo[${index}]['discountedPrice']`, Price)
    setFieldValue(
      `bookingRateInfo[${index}]['discountOnePrice']`,
      +totaldiscount
    );
    setFieldValue(
      `bookingRateInfo[${index}]['discountOneAdult']`,
      +totalAdultdiscount
    );
    setFieldValue(
      `bookingRateInfo[${index}]['discountOneChild']`,
      +totalChilddiscount
    );
    setFieldValue(`bookingRateInfo[${index}]['discounted_rate']`, combainText);
    setFieldValue(
      `bookingRateInfo[${index}]['discount']`,
      flat === 0
        ? +(+totaldiscount * data.RoomQuantity).toFixed(2)
        : +totaldiscount.toFixed(2)
    );
    setFieldValue(
      `bookingRateInfo[${index}][discountType]`,
      flat === 0 ? 1 : 2
    );
    setFieldValue(`bookingRateInfo[${index}]['discountValue']`, disRate);
    // console.log(disRate,2578 )
    setFieldValue(
      `bookingRateInfo[${index}]['discounted_selected']`,
      seletedValue
    );
    setFieldValue(`bookingRateInfo[${index}]['discount_percentage']`, +disRate);

    setFieldValue(
      `bookingRateInfo[${index}]['discountPrice']`,
      // data.discountValue === 0 ? data.price :
      DISCPrice - flat
    );
    setFieldValue(
      `bookingRateInfo[${index}]['totalRateOfRoom']`,
      // data.discountValue === 0
      //   ? dayDiff * (data.price + data.addOnTotal)
      //   :
      +(dayDiff * (DISCPrice - flat + data.addOnTotal)).toFixed(2)
    );
    setFieldValue(
      `bookingRateInfo[${index}]['totalRateWithTax']`,
      +(
        // data.discountValue === 0
        // ? dayDiff * (data.price + data.addOnTotal)
        // :
        (
          ((dayDiff * (DISCPrice - flat + data.addOnTotal)) / 100) *
            (Number(taxRateData) - Number(taxExemptRateData)) +
          // data.discountValue === 0
          // ? dayDiff * (data.price + data.addOnTotal)
          // :
          dayDiff * (DISCPrice - flat + data.addOnTotal)
        ).toFixed(2)
      )
    );

    // console.log(1591, type);
    // } else {

    //   // console.log(data,  1625 , "else")

    //   data.extraTotalPrice =
    //     +data.QtPrice + data.PriceAdult + data.PriceChild;
    //   data.discountPrice = data.extraTotalPrice;
    //   data.totalRateOfRoom = dayDiff * (data.extraTotalPrice + data.addOnTotal);
    //   data.totalRateWithTax =
    //     ((dayDiff * (data.extraTotalPrice + data.addOnTotal)) / 100) *
    //       (Number(taxRateData) - Number(taxExemptRateData)) +
    //     dayDiff * (data.extraTotalPrice + data.addOnTotal);
    //   data.discountOnePrice = 0;
    //   data.discountValue = 0;
    //   data.discount = 0;
    //   data.discountedPrice = 0;
    //   data.discountAdult = 0;
    //   data.discountChild = 0;
    //   data.discountOneAdult = 0;
    //   data.discountOneChild = 0;

    //   setDiscount(0);
    //   const totaldiscount = 0;

    //   const Price = +((data.price * (100 - 0)) / 100).toFixed(2);
    //   const DISCPrice = +(((data.extraTotalPrice * (100 - 0)) / 100).toFixed(2));
    //   const PriceAdults = +(((data.PriceAdult * (100 - 0)) / 100).toFixed(2));
    //   const PriceChilds = +(((data.PriceChild * (100 - 0)) / 100).toFixed(2));

    //   data.discountAdult =
    //     data.PriceAdult === 0
    //       ? +data.PriceAdult
    //       : +((PriceAdults + data.discountChild).toFixed(2));

    //   data.discountChild =
    //     data.PriceChild === 0
    //       ? +data.PriceChild
    //       : +((PriceChilds + data.discountAdult).toFixed(2));

    //   const totalAdultdiscount = +(((data.AdultOnePrice * 0) / 100).toFixed(2));
    //   data.discountOneAdult = totalAdultdiscount;
    //   const totalChilddiscount = +(((data.ChildOnePrice * 0) / 100).toFixed(2));
    //   data.discountOneChild = +totalChilddiscount;

    //   data.discountValue = 0;
    //   data.discountOnePrice = +totaldiscount;

    //   data.discountedPrice = Price;
    //   data.discountRoom = +((+Price * data.RoomQuantity).toFixed(2));
    //   data.discount = +((totaldiscount * data.RoomQuantity).toFixed(2));
    //   data.discountPrice =
    //     data.discountValue === 0 ? data.extraTotalPrice : DISCPrice;
    //   data.totalRateOfRoom =
    //     data.discountValue === 0
    //       ? dayDiff * (data.extraTotalPrice + data.addOnTotal)
    //       : dayDiff * (DISCPrice + data.addOnTotal);
    //   data.totalRateWithTax =
    //     +(((data.discountValue === 0
    //       ? dayDiff * (data.extraTotalPrice + data.addOnTotal)
    //       : dayDiff * (DISCPrice + data.addOnTotal)) /
    //       100) *
    //       (Number(taxRateData) - Number(taxExemptRateData)) +
    //     (data.discountValue === 0
    //       ? dayDiff * (data.extraTotalPrice + data.addOnTotal)
    //       : dayDiff * (DISCPrice + data.addOnTotal)).toFixed(2));
    //   data.extraTotalPrice =
    //     // data.discountValue === 0 ? data.extraTotalPrice :
    //     +DISCPrice;
    //   setFieldValue(`bookingRateInfo[${index}]['discountedPrice']`, Price);
    //   // setFieldValue(`bookingRateInfo[${index}]['discountedPrice']`, Price)

    //   setFieldValue(
    //     `bookingRateInfo[${index}]['discountOnePrice']`,
    //     +totaldiscount
    //   );
    //   setFieldValue(
    //     `bookingRateInfo[${index}]['discountOneAdult']`,
    //     +totalAdultdiscount
    //   );
    //   setFieldValue(
    //     `bookingRateInfo[${index}]['discountOneChild']`,
    //     +totalChilddiscount
    //   );
    //   setFieldValue(`bookingRateInfo[${index}]['discounted_rate']`, 0);
    //   setFieldValue(
    //     `bookingRateInfo[${index}]['discount']`,
    //     +((+totaldiscount * data.RoomQuantity).toFixed(2))
    //   );
    //   setFieldValue(`bookingRateInfo[${index}]['discountValue']`, 0);
    //   setFieldValue(
    //     `bookingRateInfo[${index}]['discountPrice']`,
    //     // data.discountValue === 0 ? data.price :
    //     DISCPrice
    //   );
    //   setFieldValue(
    //     `bookingRateInfo[${index}]['totalRateOfRoom']`,
    //     // data.discountValue === 0
    //     //   ? dayDiff * (data.price + data.addOnTotal)
    //     //   :
    //     +((dayDiff * (DISCPrice + data.addOnTotal)).toFixed(2))
    //   );
    //   setFieldValue(
    //     `bookingRateInfo[${index}]['totalRateWithTax']`,
    //     +(
    //       // data.discountValue === 0
    //       // ? dayDiff * (data.price + data.addOnTotal)
    //       // :
    //       ((dayDiff * (DISCPrice + data.addOnTotal)) / 100) *
    //         (Number(taxRateData) - Number(taxExemptRateData)) +
    //       // data.discountValue === 0
    //       // ? dayDiff * (data.price + data.addOnTotal)
    //       // :
    //       dayDiff * (DISCPrice + data.addOnTotal)
    //     ).toFixed(2)
    //   );
    // }
  };

  // Tax Extempt  API
  const [searchTaxResult, setSearchTaxResult] = useState<any[]>([]);
  // const [tax_exempt_num, setTax_exempt_num] = useState(0);

  // console.log(bookingDetailsStats,taxRateData , 1238)

  const getTaxMaster = async (searchTaxText) => {
    const result = await fetchList(
      ApiEndPoints.GET_TAXES + `?hotel_id=${hotelId}`
      // &search_text=${searchTaxText}`
    );
    if (result?.status === 200) {
      setSearchTaxResult(result.data);
      // console.log(1791, result.data)
    } else {
      setSearchTaxResult([]);
    }
  };

  // useEffect(() => {
  //   if (hotelId) {
  //     getTaxMaster("test");
  //     getAllTaxList();
  //   }
  // }, [hotelId]);

  const getAllTaxList = async () => {
    const result = await fetchList(
      ApiEndPoints.GET_TAXES + `?hotel_id=${hotelId}`
    );
    // console.log(result, 1790)
    if (result?.status === 200) {
      // setTaxRateData(result.data);
      setTaxRateData(
        result.data.reduce((n, { tax_rate }) => +n + +tax_rate, 0)
      );
    } else {
      setTaxRateData(0);
    }
  };

  const onSearchInputChangeTaxt = (event) => {
    const { value } = event.target;
    // console.log("value:", value, 1790)
    if (value) {
      getTaxMaster(value);
    } else {
      setSearchTaxResult([]);
    }
  };

  const handleTaxSelected = (value) => {
    const taxList = [];
    // console.log(value, 1841);
    setExemptTaxData(value);
    for (var j in value) {
      taxList.push({
        tax_exempt_id: value[j].id,
        tax_exempt_amount:
          ((bookingDetailsStats.totalAmount +
            bookingDetailsStats.extraAdult +
            bookingDetailsStats.addOnTotal +
            bookingDetailsStats.extraChild -
            bookingDetailsStats.discountedTotal) /
            100) *
          value[j].tax_rate,
      });
    }
    setSelectedTax(taxList);
    var sum = 0;
    taxList.forEach((item) => {
      sum += parseFloat(item.tax_exempt_amount);
    });
    let totalCalTax =
      ((bookingDetailsStats?.totalAmount +
        bookingDetailsStats?.extraAdult +
        bookingDetailsStats?.addOnTotal +
        bookingDetailsStats?.extraChild -
        bookingDetailsStats?.discountedTotal) /
        100) *
        Number(taxRateData) -
      sum;
    setBookingDetailsStats((prev) => ({ ...prev, taxAmount: sum }));
    setBookingDetailsStats((prev) => ({
      ...prev,
      totalTax: totalCalTax < 0 ? 0 : totalCalTax,
    }));
    setBookingDetailsStats((prev) => ({
      ...prev,
      finslTotal:
        bookingDetailsStats.totalAmount +
        bookingDetailsStats.extraAdult +
        bookingDetailsStats.addOnTotal +
        bookingDetailsStats.extraChild -
        bookingDetailsStats.discountedTotal +
        (totalCalTax < 0 ? 0 : totalCalTax),
    }));
  };

  const handleTaxSelectedNew = (value) => {
    setExemptTaxData(value);
    
    var exemptSum = 0;
    value?.forEach((item) => {
      exemptSum += +item.tax_rate;
    });

    setTaxExemptRateData(exemptSum);
    let newBookingDetail = bookingDetails?.map((item)=>(item?.primaryGuest === 1 ? {
      ...item,
      discountedTotalPriceWithTax :  +(((+item.discountedTotalPrice/100) * (+taxRateData - exemptSum)) + +item.discountedTotalPrice).toFixed(2),
      groupGuestInfo: {...item?.groupGuestInfo, 
        tax_id_data :value, 
        tax_exempt_data: value?.map((tax)=>({
          tax_exempt_id: tax?.id,
          tax_exempt_amount : ((+item.discountedTotalPrice/100)* +tax?.tax_rate)
        }))
      },

    } :{...item}));

    setBookingDetails(newBookingDetail);
    
    let sum = 0;
    newBookingDetail?.forEach((item) => {
      item?.groupGuestInfo?.tax_exempt_data?.forEach((tax)=>{
        sum += +tax?.tax_exempt_amount
      })
    })

    let totalCalTax =
      ((bookingDetailsStats?.totalAmount +
        bookingDetailsStats?.extraAdult +
        bookingDetailsStats?.addOnTotal +
        bookingDetailsStats?.extraChild -
        bookingDetailsStats?.discountedTotal) /
        100) *
        Number(taxRateData) -
      sum;
      setBookingDetailsStats((prev) => ({ ...prev, taxAmount: sum }));
      setBookingDetailsStats((prev) => ({
        ...prev,
        totalTax: totalCalTax < 0 ? 0 : totalCalTax,
      }));
      setBookingDetailsStats((prev) => ({
        ...prev,
        finslTotal:
          bookingDetailsStats.totalAmount +
          bookingDetailsStats.extraAdult +
          bookingDetailsStats.addOnTotal +
          bookingDetailsStats.extraChild -
          bookingDetailsStats.discountedTotal +
          (totalCalTax < 0 ? 0 : totalCalTax),
      }));

  }
  

  const [selectedTax, setSelectedTax] = useState([
    {
      tax_exempt_id: "",
      tax_exempt_amount: "",
    },
  ]);
  const [exemptTaxData, setExemptTaxData] = useState([]);

  // useEffect(() => {
  //   // console.log(bookingDetails, 1886)
  //   var exemptSum = 0;
  //   exemptTaxData.forEach((item) => {
  //     exemptSum += parseFloat(item.tax_rate);
  //   });
  //   setTaxExemptRateData(exemptSum);
  //   setBookingDetails((current) =>
  //     current.map((obj) => {
  //       return {
  //         ...obj,
  //         discountedTotalPriceWithTax: +(
  //           (obj?.discountedTotalPrice / 100) *
  //             (Number(taxRateData) - Number(exemptSum)) +
  //           obj?.discountedTotalPrice
  //         ).toFixed(2),
  //       };
  //     })
  //   );
  // }, [exemptTaxData]);

  // group primary cuest API
  const [searchGroupResult, setSearchGroupResult] = useState<any[]>([]);
  const getGroupMaster = async (searchGroupText) => {
    const result = await fetchList(
      ApiEndPoints.GROUP_AUTOCOMPLETE + `?search_text=${searchGroupText}`
    );
    if (result?.status === 200) {
      setSearchGroupResult(result.data);
    } else {
      setSearchGroupResult([]);
    }
  };
  const onSearchInputChangeGroup = (event) => {
    if (event === null) {
      setSearchUserResult([]);
    } else {
      const { value } = event.target;
      if (value) {
        getGroupMaster(value);
      } else {
        setSearchGroupResult([]);
      }
    }
  };

  // Pinpad Devices
  const [pinpadDevicesList, setPinpadDevicesList] = useState([]);
  const [selectPinpadDevices, setSelectPinpadDevices] = useState(0);

  const handlePinpadDevice = (e) => {
    setSelectPinpadDevices(e.target.value);
  };
  useEffect(() => {
    getPinpadDevicesDetail();
  }, []);
  const getPinpadDevicesDetail = async () => {
    setLoading(true);
    const result = await fetchList(ApiEndPoints.GET_ALL_PINPAD_DEVICE);
    if (result?.status === 200) {
      setPinpadDevicesList(result?.data);
      setLoading(false);
    } else {
      setPinpadDevicesList([]);
      setLoading(false);
    }
  };

  const [verifyToken, setVerifyToken] = useState("");
  const [cardDetail, setCardDetail] = useState([]);
  const [card, setCard] = useState({
    id: 0,
    user_id: "",
    card_holder_name: "",
    card_number: "",
    yyy: "",
    mm: "",
    cvv: "",
    card_type: "",
  });
  const handeleCardChange = (e) => {
    setVerifyToken("");
    setCard({ ...card, [e.target.name]: e.target.value });
  };
  const getCradsDetail = async (user_id) => {
    const result = await getRecord(user_id, ApiEndPoints.GET_USER_CARD_DETAIL);
    const CardList = [];

    if (result?.status === 200) {
      for (var j in result.data) {
        CardList.push({
          id: result.data[j].id,
          user_id: result.data[j].user_id,
          card_holder_name: result.data[j].card_holder_name,
          card_number: result.data[j].card_number,
          expiry_date: result.data[j].expiry_date,
          token: result.data[j].token,
          card_type: result.data[j].card_type,
        });
      }
      // console.log(1914, CardList);
      setCardDetail(CardList);
    } else {
      setCardDetail([]);
    }
  };

  const [selectedCard, setSelectedCard] = useState(0);
  const setCradsDetail = (e) => {
    setSelectedCard(e.target.value);
    setVerifyToken("");
    if (e.target.value === 0) {
      setCard({
        id: 0,
        user_id: "",
        card_holder_name: "",
        card_number: "",
        yyy: "",
        mm: "",
        cvv: "",
        card_type: "",
      });
    } else {
      const data = cardDetail.find((item) => item.id === e.target.value);
      // console.log(data, 2137, 1938);
      setCard({
        id: parseInt(data?.id),
        user_id: data?.user_id,
        card_holder_name: data?.card_holder_name,
        card_number: data?.card_number,
        yyy: data?.expiry_date.split("-")[0],
        mm: data?.expiry_date.split("-")[1],
        cvv: data?.cvv,
        card_type: data?.card_type,
      });
      setVerifyToken(data?.token);
    }
  };

  const addCardDetail = async () => {
    if (guestInfo.address === "") {
      toast.error(t("You Must Add Address"));
      return;
    }
    if (guestInfo.postal_code === "") {
      toast.error(t("You Must Add Postel Code"));
      return;
    }

    if (
      card?.card_holder_name === "" ||
      card?.yyy === "" ||
      card?.card_number === "" ||
      card?.mm === ""
      // ||
      // card?.cvv === ""
    ) {
      toast.error(t("You Must Add Card Detail"));
      return;
    }
    setLoading(true);

    if (guestInfo?.user_id === "") {
      if (guestInfo?.name === "") {
        toast.error(
          t("To confirm the Add Card, you must Add User in the Second step")
        );
        return;
      }

      var userData = new FormData();
      userData.append("name", guestInfo?.name);
      userData.append("phone", guestInfo?.phone);
      userData.append("email", guestInfo?.email);
      userData.append("id_number", guestInfo?.id_number);
      userData.append("id_proof_type", guestInfo?.id_proof_type);
      userData.append("id_proof", guestInfo?.id_proof);
      userData.append("address", guestInfo?.address);
      userData.append("phone_code", guestInfo?.phone_code);
      userData.append("country", guestInfo?.country_id);
      userData.append("state", guestInfo?.state_id);
      userData.append("city", guestInfo?.city_id);
      userData.append("pin_code", guestInfo?.postal_code);
      // console.log(card,userData, 2092)

      const UserResult = await createRecord(userData, ApiEndPoints.ADD_USER);
      // console.log(card,userData,UserResult, 2092)
      if (UserResult?.status === 200) {
        const name = card?.card_holder_name.split(" ");
        const expiry_date_yyy = card?.yyy.split("");

        const bodyFormData = {
          card_number: parseInt(card?.card_number),
          expiry_date: parseFloat(
            `${card?.mm}${expiry_date_yyy[expiry_date_yyy.length - 2]}${
              expiry_date_yyy[expiry_date_yyy.length - 1]
            }`
          ),
          first_name: name[0],
          last_name: name[1] ? name[1] : "",
          security_code: card?.cvv,
          address: guestInfo.address,
          postel_code: guestInfo.postel_code,
        };
        const result = await createRecord(
          bodyFormData,
          ApiEndPoints.VERIFY_USER_CARD_DETAIL + `?hotel_id=${hotelId}`
        );

        if (result?.status === 200) {
          if (
            // result.data.result[0]?.card?.securityCode.valid === "Y" &&
            result.data.result[0]?.transaction?.responseCode === "A"
          ) {
            // setVerifyToken(result.data.result[0]?.card?.token.value);
            toast.success(t("Card Verify Success"));
            const bodyFormData = {
              user_id: parseInt(UserResult?.data?.id),
              card_holder_name: card?.card_holder_name,
              card_number: result.data.result[0]?.card?.number,
              expiry_date: moment(`${card?.yyy}-${card?.mm}-01`)
                .endOf("month")
                .format("YYYY-MM-DD"),
              // cvv: card?.cvv,
              token: result.data.result[0]?.card?.token.value,
              card_type: result.data.result[0]?.card?.type,
            };
            // console.log("addCardDetail", bodyFormData);
            const results = await createRecord(
              bodyFormData,
              ApiEndPoints.ADD_USER_CARD_DETAIL
            );
            if (results?.status === 200) {
              // console.log(results?.data, 2137, "if");
              getCradsDetail(UserResult?.data?.id);
              // setCradsDetail({target: results?.data?.id})
              setCard({
                id: parseInt(results?.data?.id),
                user_id: results?.data?.user_id,
                card_holder_name: results?.data?.card_holder_name,
                card_number: results?.data?.card_number,
                yyy: results?.data?.expiry_date.split("-")[0],
                mm: results?.data?.expiry_date.split("-")[1],
                cvv: results?.data?.cvv,
                card_type: results?.data?.card_type,
              });
              setVerifyToken(results?.data?.token);
              toast.success(t(results.message));
              setLoading(false);
            } else if (results === undefined) {
              setLoading(false);
              toast.error(t(results.message));
            } else {
              setLoading(false);
              toast.error(t(results.message));
            }
          } else {
            setLoading(false);
            toast.error(t("Please check card"));
          }
        } else if (result?.status === 400) {
          setLoading(false);
          toast.error(t(result.message));
          setVerifyToken("");
        } else if (result?.status === 500) {
          setLoading(false);
          toast.error(t("SYSTEM ERROR"));
          setVerifyToken("");
        } else if (result === undefined) {
          setVerifyToken("");
          setLoading(false);
          toast.error(t(result.message));
        } else {
          setVerifyToken("");
          setLoading(false);
          toast.error(t(result.message));
        }
      } else {
        setLoading(false);
        toast.error(UserResult?.message);
      }
    } else {
      if (guestInfo?.user_id === "") {
        toast.error(
          t("To confirm the Add Card, you must Select User in the Second step")
        );
        return;
      }

      const name = card?.card_holder_name.split(" ");
      const expiry_date_yyy = card?.yyy.split("");

      const bodyFormData = {
        card_number: parseInt(card?.card_number),
        expiry_date: parseFloat(
          `${card?.mm}${expiry_date_yyy[expiry_date_yyy.length - 2]}${
            expiry_date_yyy[expiry_date_yyy.length - 1]
          }`
        ),
        first_name: name[0],
        last_name: name[1] ? name[1] : "",
        security_code: card?.cvv,
        address: guestInfo.address,
        postel_code: guestInfo.postel_code,
      };
      const result = await createRecord(
        bodyFormData,
        ApiEndPoints.VERIFY_USER_CARD_DETAIL + `?hotel_id=${hotelId}`
      );

      if (result?.status === 200) {
        if (
          // result.data.result[0]?.card?.securityCode.valid === "Y" &&
          result.data.result[0]?.transaction?.responseCode === "A"
        ) {
          toast.success(t("Card Verify Success"));
          const bodyFormData = {
            user_id: parseInt(guestInfo?.user_id),
            card_holder_name: card?.card_holder_name,
            card_number: result.data.result[0]?.card?.number,
            expiry_date: moment(`${card?.yyy}-${card?.mm}-01`)
              .endOf("month")
              .format("YYYY-MM-DD"),
            // cvv: card?.cvv,
            token: result.data.result[0]?.card?.token.value,
            card_type: result.data.result[0]?.card?.type,
          };

          // console.log("addCardDetail", bodyFormData);
          const results = await createRecord(
            bodyFormData,
            ApiEndPoints.ADD_USER_CARD_DETAIL
          );
          if (results?.status === 200) {
            // console.log(results?.data, 2137);
            getCradsDetail(guestInfo?.user_id);

            setCard({
              id: parseInt(results?.data?.id),
              user_id: results?.data?.user_id,
              card_holder_name: results?.data?.card_holder_name,
              card_number: results?.data?.card_number,
              yyy: results?.data?.expiry_date.split("-")[0],
              mm: results?.data?.expiry_date.split("-")[1],
              cvv: results?.data?.cvv,
              card_type: results?.data?.card_type,
            });
            setVerifyToken(results?.data?.token);
            toast.success(t(results.message));
            setLoading(false);
          } else if (results === undefined) {
            setLoading(false);
            toast.error(t(results.message));
          } else {
            setLoading(false);
            toast.error(t(results.message));
          }
        } else {
          setLoading(false);
          toast.error(t("Please check card"));
        }
      } else if (result?.status === 400) {
        setLoading(false);
        toast.error(t(result.message));
        setVerifyToken("");
      } else if (result?.status === 500) {
        setLoading(false);
        toast.error(t("SYSTEM ERROR"));
        setVerifyToken("");
      } else if (result === undefined) {
        setVerifyToken("");
        setLoading(false);
        toast.error(t(result.message));
      } else {
        setVerifyToken("");
        setLoading(false);
        toast.error(t(result.message));
      }
    }
  };

  // room rate
  const handleRoomRateDropdownChange = (e, index, setFieldValue, data) => {
    // console.log(e?.target.value, 2883);
    const data1 = bookRoomInitialState?.bookingRateInfo?.find(
      (item, index) => index === parseInt(e.target.name.split(".")[1])
    );
    let dayDiff = daysBetween(
      new Date(searchFilters.startDate),
      new Date(searchFilters.endDate)
    );
    let disRate = data.discount_percentage;

    let flat = 0;
    if (+data?.discountType === 2) {
      disRate = 0;
      flat = +data?.discount;
    }

    let totalPrice =
      (+getExtraAdultById(data.rateDetails, data?.rateId, e?.target.value) *
        +data?.selectAdult +
        +getExtraChildById(data?.rateDetails, data?.rateId, e?.target.value) *
          +data?.selectChild +
        +getAveragePrice(
          data?.rateDetails,
          dayDiff,
          searchFilters.startDate,
          e?.target.value
        )) *
      dayDiff;

    data.price = +getAveragePrice(
      data?.rateDetails,
      dayDiff,
      searchFilters.startDate,
      e?.target.value
    );

    let QtPrice =
      +getAveragePrice(
        data?.rateDetails,
        dayDiff,
        searchFilters.startDate,
        e?.target.value
      ) * +data?.RoomQuantity;

    let AdultOnePrice =
      +getExtraAdultById(data.rateDetails, data?.rateId, e?.target.value) *
      +data?.selectAdult;

    let ChildOnePrice =
      +getExtraChildById(
        data.rateDetails,
        data.defaultSelectedRateId,
        e?.target.value
      ) * +data?.selectChild;
    data.PriceAdult = Number(AdultOnePrice) * data?.RoomQuantity;
    data.PriceChild = Number(ChildOnePrice) * data?.RoomQuantity;

    data.extraTotalPrice = +(
      QtPrice +
      data.PriceAdult +
      data.PriceChild
    ).toFixed(2);
    data.discountPrice = data.extraTotalPrice;
    data.totalRateOfRoom = +(
      dayDiff *
      (data.extraTotalPrice + data.addOnTotal)
    ).toFixed(2);
    data.totalRateWithTax = +(
      ((dayDiff * (data.extraTotalPrice + data.addOnTotal)) / 100) *
        (Number(taxRateData) - Number(taxExemptRateData)) +
      dayDiff * (data.extraTotalPrice + data.addOnTotal)
    ).toFixed(2);

    const totaldiscount = +((+data.price * disRate) / 100).toFixed(2) + flat;
    const Price = +((data.price * (100 - disRate)) / 100 - flat).toFixed(2);
    const DISCPrice = +((data.extraTotalPrice * (100 - disRate)) / 100).toFixed(
      2
    );
    const PriceAdults = +((data.PriceAdult * (100 - disRate)) / 100).toFixed(2);
    const PriceChilds = +((data.PriceChild * (100 - disRate)) / 100).toFixed(2);
    data.discountAdult = PriceAdults;

    data.discountChild = PriceChilds;

    const totalAdultdiscount = +((AdultOnePrice * disRate) / 100).toFixed(2);
    data.discountOneAdult = +totalAdultdiscount;
    const totalChilddiscount = +((ChildOnePrice * disRate) / 100).toFixed(2);

    data.discountOneChild = +totalChilddiscount;
    data.discountedPrice = Price;
    data.discountRoom = +(+Price * data.RoomQuantity).toFixed(2);
    data.discountPrice = DISCPrice - flat;
    data.totalRateOfRoom = +(
      dayDiff * (DISCPrice + data.addOnTotal) -
      flat
    ).toFixed(2);
    data.totalRateWithTax = +(
      ((dayDiff * (DISCPrice + data.addOnTotal) - flat) / 100) *
        (Number(taxRateData) - Number(taxExemptRateData)) +
      (dayDiff * (DISCPrice + data.addOnTotal) - flat)
    ).toFixed(2);
    data.extraTotalPrice = DISCPrice - flat;
    setFieldValue(`bookingRateInfo[${index}]['discountedPrice']`, Price);
    setFieldValue(
      `bookingRateInfo[${index}]['extraTotalPrice']`,
      data.extraTotalPrice
    );
    setFieldValue(
      `bookingRateInfo[${index}]['discountOnePrice']`,
      +totaldiscount
    );
    setFieldValue(
      `bookingRateInfo[${index}]['discountOneAdult']`,
      +totalAdultdiscount
    );
    setFieldValue(
      `bookingRateInfo[${index}]['discountOneChild']`,
      +totalChilddiscount
    );
    setFieldValue(`bookingRateInfo[${index}]['AdultOnePrice']`, +AdultOnePrice);
    setFieldValue(`bookingRateInfo[${index}]['PriceAdult']`, +data.PriceAdult);
    setFieldValue(`bookingRateInfo[${index}]['ChildOnePrice']`, +ChildOnePrice);
    setFieldValue(`bookingRateInfo[${index}]['PriceChild']`, +data.PriceChild);
    setFieldValue(
      `bookingRateInfo[${index}]['discountAdult']`,
      +data.discountAdult
    );
    setFieldValue(
      `bookingRateInfo[${index}]['discountChild']`,
      +data.discountChild
    );
    setFieldValue(`bookingRateInfo[${index}]['price']`, +data.price);
    setFieldValue(`bookingRateInfo[${index}]['QtPrice']`, +QtPrice);
    setFieldValue(
      `bookingRateInfo[${index}]['discount']`,
      flat === 0
        ? +(+totaldiscount * data.RoomQuantity).toFixed(2)
        : +totaldiscount.toFixed(2)
    );
    setFieldValue(
      `bookingRateInfo[${index}]['discountPrice']`,
      DISCPrice - flat
    );
    // setFieldValue(`bookingRateInfo[${index}]['discountValue']`, disRate);
    setFieldValue(
      `bookingRateInfo[${index}]['totalRateOfRoom']`,
      +(dayDiff * (DISCPrice - flat + data.addOnTotal)).toFixed(2)
    );
    setFieldValue(
      `bookingRateInfo[${index}]['totalRateWithTax']`,
      +(
        ((dayDiff * (DISCPrice - flat + data.addOnTotal)) / 100) *
          (Number(taxRateData) - Number(taxExemptRateData)) +
        dayDiff * (DISCPrice - flat + data.addOnTotal)
      ).toFixed(2)
    );
    setFieldValue(`bookingRateInfo[${index}]['rateTypeId']`, e?.target?.value);
    setFieldValue(
      `bookingRateInfo[${index}]['cancelation_policy']`,
      data?.rateDetails?.find(
        (el) => +el?.category_rate_type_id == +e?.target?.value
      )?.rateTypeDetail?.cancellationPolicyDetails?.name
    );
    setFieldValue(
      `bookingRateInfo[${index}]['cancelation_policy_desc']`,
      data?.rateDetails?.find(
        (el) => +el?.category_rate_type_id == +e?.target?.value
      )?.rateTypeDetail?.cancellationPolicyDetails?.description
    );

    // let dayDiff = daysBetween(
    //   new Date(searchFilters.startDate),
    //   new Date(searchFilters.endDate)
    // );
    // const data1 = bookRoomInitialState?.bookingRateInfo?.find(
    //   (item, index) => index === parseInt(e?.name.split(".")[1])
    // );
    // data1.price = getRateById(data1.rateDetails, e?.value);
    // data1.discountPrice = getRateById(data1.rateDetails, e?.value);
    // data1.totalRateOfRoom =
    //   dayDiff * Number(getRateById(data1.rateDetails, e?.value));
    // data1.totalRateWithTax =
    //   ((dayDiff * Number(getRateById(data1.rateDetails, e?.value))) / 100) *
    //     (Number(taxRateData) - Number(taxExemptRateData)) +
    //   dayDiff * Number(getRateById(data1.rateDetails, e?.value));
    // data1.defaultSelectedRateId = e?.value;
    // data1.discounted_selected = 0;
    // data1.mealOption = [];
    // data1.addOnPrice = 0;
    // data1.addOnTotal = 0;
    // data1.discount = 0;
    // data1.discountOnePrice = 0;
    // data1.discountValue = 0;
    // data1.discounted_rate = "";
    // data1.AdultOnePrice = 0;
    // data1.ChildOnePrice = 0;
    // data1.QtPrice = getRateById(data1.rateDetails, e?.value);
    // data1.discountAdult = 0;
    // data1.discountChild = 0;
    // data1.discountOneAdult = 0;
    // data1.discountOneChild = 0;
    // data1.extraTotalPrice = getRateById(data1.rateDetails, e?.value);
    // data1.selectAdult = 0;
    // data1.selectChild = 0;

    // setFieldValue(
    //   `bookingRateInfo[${index}]['defaultSelectedRateId']`,
    //   e?.value
    // );
    // setFieldValue(
    //   `bookingRateInfo[${index}]['price']`,
    //   getRateById(data1.rateDetails, e?.value)
    // );
    // setFieldValue(
    //   `bookingRateInfo[${index}]['discountPrice']`,
    //   getRateById(data1.rateDetails, e?.value)
    // );
    // setFieldValue(
    //   `bookingRateInfo[${index}]['totalRateOfRoom']`,
    //   dayDiff * Number(getRateById(data1.rateDetails, e?.value))
    // );
    // setFieldValue(
    //   `bookingRateInfo[${index}]['totalRateWithTax']`,
    //   ((dayDiff * Number(getRateById(data1.rateDetails, e?.value))) / 100) *
    //     (Number(taxRateData) - Number(taxExemptRateData)) +
    //     dayDiff * Number(getRateById(data1.rateDetails, e?.value))
    // );
  };

  // Policy
  const handleGuaranteePolicy = (e) => {
    setGuaranteePolicy(e.target.value);
  };

  const handleCancelPolicy = (e) => {
    setCancelPolicy(e.target.value);
  };

  // Address
  const handleRemoveAddress = (event, setFieldValue) => {
    if (event.target.value === "") {
      setFieldValue("address", "");
      setFieldValue("city_id", "");
      setFieldValue("state_id", "");
      setFieldValue("country_id", "");
      setFieldValue("postal_code", "");
      setGuestInfo((prevState) => ({
        ...prevState,
        city_id: "",
        postal_code: "",
        address: "",
        state_id: "",
        country_id: "",
      }));
    } else {
      setGuestInfo((prevState) => ({
        ...prevState,
        address: event.target.value,
      }));
      setFieldValue("address", event.target.value);
    }
  };

  const setPlace = (place, setFieldValue) => {
    setFieldValue("address", place?.formatted_address);
    setGuestInfo((prevState) => ({
      ...prevState,
      address: place?.formatted_address,
    }));
    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "locality":
          setGuestInfo((prevState) => ({
            ...prevState,
            city_id: component.short_name,
          }));
          break;
        case "postal_code": {
          setGuestInfo((prevState) => ({
            ...prevState,
            postal_code: component.short_name,
          }));
          break;
        }
        case "administrative_area_level_1": {
          setGuestInfo((prevState) => ({
            ...prevState,
            state_id: component.long_name,
          }));
          break;
        }
        case "country":
          setGuestInfo((prevState) => ({
            ...prevState,
            country_id: component.long_name,
          }));
          break;
      }
    }
  };

  // companis
  const [companiesList, setCompaniesList] = useState([
    {
      label: "",
      id: 0,
    },
  ]);

  const getCompaniesList = async () => {
    setLoading(true);
    const result = await fetchList(
      ApiEndPoints.COMPANY_RESOURCE_ROUTE +
        `/get-companies-for-select?search_text=`
    );
    const companiesList = [];
    if (result?.status === 200) {
      for (var j in result.data) {
        companiesList.push({
          label: result.data[j].company_profile_name,
          id: result.data[j].id,
        });
      }
      setCompaniesList(companiesList);
      setLoading(false);
    } else {
      setCompaniesList([]);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   getCompaniesList();
  // }, [hotelId]);
  const handleChangeCompani = (e, v, setFieldValue) => {
    setGuestInfo((prevState) => ({
      ...prevState,
      company_profile_name: v.label,
      corporateID: v.id,
    }));
  };

  // const [extraSelected, setExtraSelected] = useState("");

  // Extra Child
  const handleExtraChild = (e, setFieldValue, index, data) => {
    const value = e.target.value;
    let dayDiff = daysBetween(
      new Date(searchFilters.startDate),
      new Date(searchFilters.endDate)
    );
    // setExtraSelected(value);
    const data1 = data;
    // bookRoomInitialState?.bookingRateInfo?.find(
    //   (item, index) => index === parseInt(e.target.name.split(".")[1])
    // );

    const price =
      Number(
        getExtraChildById(
          data.rateDetails,
          data.defaultSelectedRateId,
          data?.rateTypeId
        )
      ) * value;
    data1.ChildOnePrice = Number(price);

    let disRate = data.discount_percentage;
    let flat = 0;
    if (+data?.discountType === 2) {
      disRate = 0;
      flat = +data?.discount;
    }

    const max = [];
    if (value === 0) {
      if (data.discount !== 0) {
        const childP = Number(price) * data?.RoomQuantity;
        const total = ((childP * disRate) / 100).toFixed(2);
        const discAd = Number(childP) - Number(total);

        const Price = (
          (data.price * (100 - data.discountValue) * data?.RoomQuantity) / 100 -
          flat
        ).toFixed(2);
        // const DISCPrice =
        //   (data.extraTotalPrice * (100 - data.discountValue)) / 100;
        const PriceAdults =
          (data.PriceAdult * (100 - data.discountValue) * data?.RoomQuantity) /
          100;
        // const PriceChilds =
        //   (data.PriceChild * (100 - data.discountValue) ) / 100;

        const childPrice = discAd;
        data1.discountPrice = childPrice + +Price + PriceAdults;
        // Number(data1.discountPrice) - Number(data1.discountChild);
        data1.totalRateOfRoom =
          dayDiff * (data1.discountPrice + data1.addOnTotal);
        data1.totalRateWithTax =
          ((dayDiff * (data1.discountPrice + data1.addOnTotal)) / 100) *
            (Number(taxRateData) - Number(taxExemptRateData)) +
          dayDiff * (data1.discountPrice + data1.addOnTotal);

        data1.extraTotalPrice =
          Number(data1.discountRoom) + Number(data1.discountAdult);
        data1.PriceChild = childP;
        data1.discountChild = discAd;
        const totalChilddiscount = ((price * disRate) / 100).toFixed(2);
        data1.discountOneChild = totalChilddiscount;
        setFieldValue(
          `bookingRateInfo.${index}.discountOneChild`,
          (+price * disRate) / 100
        );

        // console.log(price, "childprice0", PriceAdults,data.PriceAdult ,"handleChild")

        setFieldValue(`bookingRateInfo.${index}.PriceChild`, +price);

        setFieldValue(`bookingRateInfo.${index}.ChildOnePrice`, +price);
        setFieldValue(`bookingRateInfo.${index}.selectChild`, 0);
        setFieldValue(
          `bookingRateInfo.${index}.discountPrice`,
          +data1.discountPrice.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateOfRoom`,
          +data1.totalRateOfRoom.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateWithTax`,
          +data1.totalRateWithTax.toFixed(2)
        );
      } else {
        data1.discountPrice =
          Number(data1.discountPrice) - Number(data1.PriceChild);

        // Number(data1.extraTotalPrice) - Number(data1.PriceChild);
        data1.totalRateOfRoom =
          dayDiff * (Number(data1.discountPrice) + +data1.addOnTotal);
        // console.log(data1.totalRateOfRoom, 2818);

        data1.totalRateWithTax =
          (data1.totalRateOfRoom / 100) *
            (Number(taxRateData) - Number(taxExemptRateData)) +
          data1.totalRateOfRoom;
        data1.extraTotalPrice = Number(data1.discountPrice) + +data1.addOnTotal;
        data1.discountChild = 0;
        data1.ChildOnePrice = 0;
        data1.PriceChild = 0;
        setFieldValue(`bookingRateInfo.${index}.selectChild`, 0);
        setFieldValue(
          `bookingRateInfo.${index}.discountOneChild`,
          (+price * discount) / 100
        );

        // console.log(price, "childprice0" , data1.totalRateOfRoom );

        setFieldValue(`bookingRateInfo.${index}.PriceChild`, +price);

        setFieldValue(`bookingRateInfo.${index}.ChildOnePrice`, +price);
        setFieldValue(
          `bookingRateInfo.${index}.discountPrice`,
          +data1.discountPrice.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateOfRoom`,
          +data1.totalRateOfRoom.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateWithTax`,
          +data1.totalRateWithTax.toFixed(2)
        );
      }
      // else {
      //   data1.discountPrice =
      //     Number(data1.extraTotalPrice) - Number(data1.PriceChild);
      //   data1.totalRateOfRoom =
      //     dayDiff *
      //     (Number(data1.extraTotalPrice) -
      //       Number(data1.PriceChild) +
      //       +data1.addOnTotal);
      //   console.log(data1.totalRateOfRoom, 2818);

      //   data1.totalRateWithTax =
      //     (data1.totalRateOfRoom / 100) *
      //       (Number(taxRateData) - Number(taxExemptRateData)) +
      //     data1.totalRateOfRoom;
      //   data1.extraTotalPrice =
      //     Number(data1.extraTotalPrice) -
      //     Number(data1.PriceChild) +
      //     +data1.addOnTotal;
      //   data1.discountChild = 0;
      //   data1.ChildOnePrice = 0;
      //   data1.PriceChild = 0;
      //   setFieldValue(`bookingRateInfo.${index}.selectChild`, 0);
      //   setFieldValue(
      //     `bookingRateInfo.${index}.discountOneChild`,
      //     (+price * discount) / 100
      //   );

      //   // console.log(price, "childprice0" , data1.totalRateOfRoom );

      //   setFieldValue(`bookingRateInfo.${index}.PriceChild`, +price);

      //   setFieldValue(`bookingRateInfo.${index}.ChildOnePrice`, +price);
      //   setFieldValue(
      //     `bookingRateInfo.${index}.discountPrice`,
      //     +data1.discountPrice.toFixed(2)
      //   );
      //   setFieldValue(
      //     `bookingRateInfo.${index}.totalRateOfRoom`,
      //     +data1.totalRateOfRoom.toFixed(2)
      //   );
      //   setFieldValue(
      //     `bookingRateInfo.${index}.totalRateWithTax`,
      //     +data1.totalRateWithTax.toFixed(2)
      //   );
      // }
      for (let i = data.min_Occupancy; i < data.max_occupancy; i++) {
        max.push(i);
      }
      data1.extraAdult = max;
      data1.selectChild = value;
      data1.PriceChild = 0;
      setFieldValue(`bookingRateInfo.${index}.extraAdult`, max);
    } else {
      // if (
      //   +data?.selectDefaultChild + +data?.selectDefaultAdult <
      //   +data?.min_Occupancy
      // ) {
      //   toast.error("Please, First Select No Of Children");
      //   return;
      // }
      if (data.discount !== 0) {
        const childP = Number(price) * data?.RoomQuantity;
        const total = ((childP * disRate) / 100).toFixed(2);
        const discAd = Number(childP) - Number(total);

        const Price = (
          (data.price * (100 - data.discountValue) * data?.RoomQuantity) / 100 -
          flat
        ).toFixed(2);
        // const DISCPrice =
        //   (data.extraTotalPrice * (100 - data.discountValue)) / 100;
        const PriceAdults =
          (data.PriceAdult * (100 - data.discountValue) * data?.RoomQuantity) /
          100;
        // const PriceChilds =
        //   (data.PriceChild * (100 - data.discountValue) ) / 100;
        // console.log(data.PriceAdult, "priceAdult")

        const childPrice = discAd;
        data1.discountPrice = childPrice + +Price + PriceAdults;
        data1.totalRateOfRoom =
          dayDiff * (data1.discountPrice + data1.addOnTotal);
        data1.totalRateWithTax =
          ((dayDiff * (data1.discountPrice + data1.addOnTotal)) / 100) *
            (Number(taxRateData) - Number(taxExemptRateData)) +
          dayDiff * (data1.discountPrice + data1.addOnTotal);
        data1.extraTotalPrice =
          Number(data1.discountRoom) + Number(data1.discountAdult);
        data1.PriceChild = childP;
        data1.discountChild = discAd;
        // const totalAdultdiscount = ((Number(price) * disRate) / 100).toFixed(2);
        // data1.discountOneAdult = totalAdultdiscount
        const totalChilddiscount = ((price * disRate) / 100).toFixed(2);
        data1.discountOneChild = totalChilddiscount;

        setFieldValue(
          `bookingRateInfo.${index}.discountOneChild`,
          (+price * disRate) / 100
        );

        // console.log(price, "childPrice handle")
        // console.log(price, "childprice0", PriceAdults,data.PriceAdult, "handleChild")

        setFieldValue(`bookingRateInfo.${index}.PriceChild`, +price);

        setFieldValue(`bookingRateInfo.${index}.ChildOnePrice`, +price);
        setFieldValue(`bookingRateInfo.${index}.selectChild`, value);
        setFieldValue(
          `bookingRateInfo.${index}.discountPrice`,
          +data1.discountPrice.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateOfRoom`,
          +data1.totalRateOfRoom.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateWithTax`,
          +data1.totalRateWithTax.toFixed(2)
        );

        // console.log("child,2864")
      } else {
        const childP = Number(price) * data?.RoomQuantity;
        const childPrice =
          Number(childP) + Number(data1.PriceAdult) + +data1.QtPrice;
        // const childPrice = data?.RoomQuantity == 1 ? Number(childP) + Number(data1.PriceAdult) + Number(data1.price) : (Number(childP) + Number(data1.PriceAdult) + parseInt(data1.QtPrice))
        data1.discountPrice = childPrice;
        data1.totalRateOfRoom = dayDiff * (childPrice + data1.addOnTotal);
        data1.totalRateWithTax =
          ((dayDiff * (childPrice + data1.addOnTotal)) / 100) *
            (Number(taxRateData) - Number(taxExemptRateData)) +
          dayDiff * (childPrice + data1.addOnTotal);
        data1.extraTotalPrice = childPrice.toFixed(2);
        data1.PriceChild = childP;
        // console.log(price, "childprice1")

        setFieldValue(`bookingRateInfo.${index}.selectChild`, value);
        setFieldValue(
          `bookingRateInfo.${index}.discountOneChild`,
          (+price * discount) / 100
        );

        // console.log(price, "childprice0" , data1.totalRateOfRoom );

        setFieldValue(`bookingRateInfo.${index}.PriceChild`, +price);

        setFieldValue(`bookingRateInfo.${index}.ChildOnePrice`, +price);
        setFieldValue(
          `bookingRateInfo.${index}.discountPrice`,
          +data1.discountPrice.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateOfRoom`,
          +data1.totalRateOfRoom.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateWithTax`,
          +data1.totalRateWithTax.toFixed(2)
        );
        // console.log("wchild,2865")
      }
      for (let i = value; i < data.Adult.length; i++) {
        max.push(i);
      }
      data1.extraAdult = max;
      data1.selectChild = value;
      setFieldValue(`bookingRateInfo.${index}.extraAdult`, max);
    }
  };

  // Extra Adult
  const handleExtraAdult = (e, setFieldValue, index, data) => {
    const value = e.target.value;
    let dayDiff = daysBetween(
      new Date(searchFilters.startDate),
      new Date(searchFilters.endDate)
    );
    const data1 = data;
    const price =
      Number(
        getExtraAdultById(
          data.rateDetails,
          data.defaultSelectedRateId,
          data?.rateTypeId
        )
      ) * value;
    data1.AdultOnePrice = Number(price);
    let disRate = data.discount_percentage;
    let flat = 0;
    if (+data?.discountType === 2) {
      disRate = 0;
      flat = +data?.discount;
    }

    // console.log(data,disRate, flat, 1159)

    // setExtraSelected(value);
    const max = [];
    // const maxs = [];
    // console.log(data1.discount, data, value, 2785);
    if (value !== 0) {
      // if (
      //   +data?.selectDefaultChild + +data?.selectDefaultAdult <
      //   +data?.min_Occupancy
      // ) {
      //   toast.error("Please, First Select No Of Adults");
      //   return;
      // }
      if (data.discount !== 0) {
        const adultP = Number(price) * data?.RoomQuantity;
        const total = ((adultP * disRate) / 100).toFixed(2);
        const discAd = Number(adultP) - Number(total);

        const Price = (
          (data.price * (100 - data.discountValue) * data?.RoomQuantity) / 100 -
          flat
        ).toFixed(2);
        // const DISCPrice =
        //   (data.extraTotalPrice * (100 - data.discountValue)) / 100;
        // const PriceAdults =
        //   (data.PriceAdult * (100 - data.discountValue)) / 100;
        const PriceChilds =
          (data.PriceChild * (100 - data.discountValue) * data?.RoomQuantity) /
          100;
        // console.log(data.PriceChild, "priceChild")

        const adultPrice = discAd;
        // + Number(data1.discountRoom) + Number(data1.discountChild);
        data1.discountPrice = adultPrice + +Price + PriceChilds;
        data1.totalRateOfRoom =
          dayDiff * (data1.discountPrice + data1.addOnTotal);
        data1.totalRateWithTax =
          ((dayDiff * (data1.discountPrice + data1.addOnTotal)) / 100) *
            (Number(taxRateData) - Number(taxExemptRateData)) +
          dayDiff * (data1.discountPrice + data1.addOnTotal);
        data1.extraTotalPrice =
          Number(data1.discountRoom) + Number(data1.discountChild);
        data1.PriceAdult = adultP;
        data1.discountAdult = discAd;
        // const totalChilddiscount = ((data1.ChildOnePrice * disRate) / 100).toFixed(2);
        // data1.discountOneChild = totalChilddiscount
        const totalAdultdiscount = ((price * disRate) / 100).toFixed(2);
        data1.discountOneAdult = totalAdultdiscount;

        setFieldValue(
          `bookingRateInfo.${index}.discountOneAdult`,
          (+price * disRate) / 100
        );

        // console.log(price, "adultPrice0", PriceChilds,data.PriceChild, "handleAdult" )

        setFieldValue(`bookingRateInfo.${index}.PriceAdult`, +price);

        setFieldValue(`bookingRateInfo.${index}.AdultOnePrice`, +price);
        setFieldValue(`bookingRateInfo.${index}.selectAdult`, value);
        setFieldValue(
          `bookingRateInfo.${index}.discountPrice`,
          +data1.discountPrice.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateOfRoom`,
          +data1.totalRateOfRoom.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateWithTax`,
          +data1.totalRateWithTax.toFixed(2)
        );
      } else {
        const adultP = Number(price) * data?.RoomQuantity;
        const adultPrice =
          Number(adultP) + Number(data1.PriceChild) + +data1.QtPrice;
        // const adultPrice = data?.RoomQuantity == 1 ? Number(adultP) + Number(data1.PriceChild) + Number(data1.price) : (Number(adultP) + Number(data1.PriceChild) + parseInt(data1.QtPrice))
        data1.discountPrice = adultPrice;
        data1.totalRateOfRoom = dayDiff * (adultPrice + data1.addOnTotal);
        data1.totalRateWithTax =
          ((dayDiff * (adultPrice + data1.addOnTotal)) / 100) *
            (Number(taxRateData) - Number(taxExemptRateData)) +
          dayDiff * (adultPrice + data1.addOnTotal);
        data1.extraTotalPrice = adultPrice.toFixed(2);
        data1.PriceAdult = adultP;
        setFieldValue(
          `bookingRateInfo.${index}.discountOneAdult`,
          (+price * discount) / 100
        );

        // console.log(price, "adultPrice0", PriceChilds,data.PriceChild, "handleAdult" )

        setFieldValue(`bookingRateInfo.${index}.PriceAdult`, +price);

        setFieldValue(`bookingRateInfo.${index}.AdultOnePrice`, +price);
        setFieldValue(`bookingRateInfo.${index}.selectAdult`, value);
        setFieldValue(
          `bookingRateInfo.${index}.discountPrice`,
          +data1.discountPrice.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateOfRoom`,
          +data1.totalRateOfRoom.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateWithTax`,
          +data1.totalRateWithTax.toFixed(2)
        );
      }
      for (let i = value; i < data.Adult.length; i++) {
        max.push(i);
      }
      data1.extraChild = max;
      data1.selectAdult = value;
      setFieldValue(`bookingRateInfo.${index}.extraChild`, max);
    } else {
      // console.log(data1, data, price, 3653);
      setFieldValue(`bookingRateInfo.${index}.selectAdult`, value);
      if (data.discount !== 0) {
        const adultP = Number(price) * data?.RoomQuantity;
        const total = ((adultP * disRate) / 100).toFixed(2);
        const discAd = Number(adultP) - Number(total);

        const Price = (
          (data.price * (100 - data.discountValue) * data?.RoomQuantity) / 100 -
          flat
        ).toFixed(2);
        // const DISCPrice =
        //   (data.extraTotalPrice * (100 - data.discountValue)) / 100;
        // const PriceAdults =
        //   (data.PriceAdult * (100 - data.discountValue)) / 100;
        const PriceChilds =
          (data.PriceChild * (100 - data.discountValue) * data?.RoomQuantity) /
          100;
        // console.log(data.PriceChild,data.childOnePrice, "priceChild0")
        const adultPrice = discAd;
        // + Number(data1.discountRoom) + Number(data1.discountChild);
        data1.discountPrice = adultPrice + +Price + PriceChilds;
        data1.totalRateOfRoom =
          dayDiff * (data1.discountPrice + data1.addOnTotal);
        data1.totalRateWithTax =
          ((dayDiff * (data1.discountPrice + data1.addOnTotal)) / 100) *
            (Number(taxRateData) - Number(taxExemptRateData)) +
          dayDiff * (data1.discountPrice + data1.addOnTotal);
        data1.extraTotalPrice =
          Number(data1.discountRoom) + Number(data1.discountChild);
        data1.PriceAdult = adultP;
        data1.discountAdult = discAd;
        // const totalChilddiscount = ((data1.ChildOnePrice * disRate) / 100).toFixed(2);
        // data1.discountOneChild = totalChilddiscount
        const totalAdultdiscount = ((price * disRate) / 100).toFixed(2);
        data1.discountOneAdult = totalAdultdiscount;

        // data1.discountPrice = Number(data1.discountPrice) - data1.discountAdult;

        setFieldValue(
          `bookingRateInfo.${index}.discountOneAdult`,
          (+price * disRate) / 100
        );

        // console.log(price, "adultPrice0", PriceChilds,data.PriceChild, "handleAdult" )
        setFieldValue(`bookingRateInfo.${index}.PriceAdult`, +price);
        setFieldValue(`bookingRateInfo.${index}.AdultOnePrice`, +price);
        setFieldValue(`bookingRateInfo.${index}.selectAdult`, 0);
        setFieldValue(
          `bookingRateInfo.${index}.discountPrice`,
          +data1.discountPrice.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateOfRoom`,
          +data1.totalRateOfRoom.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateWithTax`,
          +data1.totalRateWithTax.toFixed(2)
        );
      }
      // else {
      //   data1.discountPrice =
      //     Number(data1.extraTotalPrice) - Number(data1.PriceAdult);
      //   data1.totalRateOfRoom = +(
      //     dayDiff *
      //     (Number(data1.extraTotalPrice) -
      //       Number(data1.PriceAdult) +
      //       +data1.addOnTotal)
      //   ).toFixed(2);
      //   // console.log(Number(data1.extraTotalPrice) - Number(data1.PriceAdult) + +data1.addOnTotal, 3145)
      //   data1.totalRateWithTax = +(
      //     ((dayDiff *
      //       (Number(data1.extraTotalPrice) -
      //         Number(data1.PriceAdult) +
      //         +data1.addOnTotal)) /
      //       100) *
      //       (Number(taxRateData) - Number(taxExemptRateData)) +
      //     dayDiff *
      //       (Number(data1.extraTotalPrice) -
      //         Number(data1.PriceAdult) +
      //         +data1.addOnTotal)
      //   ).toFixed(2);
      //   data1.extraTotalPrice = +(
      //     Number(data1.extraTotalPrice) -
      //     Number(data1.PriceAdult) +
      //     +data1.addOnTotal
      //   ).toFixed(2);
      //   data1.discountAdult = 0;

      //   data1.PriceAdult = 0;

      //   setFieldValue(`bookingRateInfo.${index}.PriceAdult`, +price);
      //   setFieldValue(`bookingRateInfo.${index}.AdultOnePrice`, +price);
      //   setFieldValue(`bookingRateInfo.${index}.selectAdult`, 0);
      //   setFieldValue(
      //     `bookingRateInfo.${index}.discountPrice`,
      //     +data1.discountPrice.toFixed(2)
      //   );
      //   setFieldValue(
      //     `bookingRateInfo.${index}.totalRateOfRoom`,
      //     +data1.totalRateOfRoom.toFixed(2)
      //   );
      //   setFieldValue(
      //     `bookingRateInfo.${index}.totalRateWithTax`,
      //     +data1.totalRateWithTax.toFixed(2)
      //   );
      // }
      else {
        data1.discountPrice =
          Number(data1.discountPrice) - Number(data1.PriceAdult);
        data1.totalRateOfRoom = +(
          dayDiff *
          (Number(data1.discountPrice) +
            //  -
            //   Number(data1.PriceAdult)
            +data1.addOnTotal)
        ).toFixed(2);
        // console.log(Number(data1.extraTotalPrice) - Number(data1.PriceAdult) + +data1.addOnTotal, 3145)
        data1.totalRateWithTax = +(
          ((dayDiff *
            (Number(data1.discountPrice) +
              // -
              //   Number(data1.PriceAdult)
              +data1.addOnTotal)) /
            100) *
            (Number(taxRateData) - Number(taxExemptRateData)) +
          dayDiff *
            (Number(data1.discountPrice) +
              //  -
              // Number(data1.PriceAdult)
              +data1.addOnTotal)
        ).toFixed(2);
        data1.extraTotalPrice = +(
          Number(data1.discountPrice) +
          // -
          // Number(data1.PriceAdult)
          +data1.addOnTotal
        ).toFixed(2);
        data1.discountAdult = 0;

        data1.PriceAdult = 0;

        setFieldValue(`bookingRateInfo.${index}.PriceAdult`, +price);
        setFieldValue(`bookingRateInfo.${index}.AdultOnePrice`, +price);
        setFieldValue(`bookingRateInfo.${index}.selectAdult`, 0);
        setFieldValue(
          `bookingRateInfo.${index}.discountPrice`,
          +data1.discountPrice.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateOfRoom`,
          +data1.totalRateOfRoom.toFixed(2)
        );
        setFieldValue(
          `bookingRateInfo.${index}.totalRateWithTax`,
          +data1.totalRateWithTax.toFixed(2)
        );
      }
      for (let i = data.min_Occupancy; i < data.max_occupancy; i++) {
        max.push(i);
      }
      data1.extraChild = max;
      data1.selectAdult = value;
      data1.PriceAdult = 0;
      setFieldValue(`bookingRateInfo.${index}.extraChild`, max);
    }
  };
  // default adult
  const handleDefaultAdult = (e, setFieldValue, index, data) => {

    const data1 = bookRoomInitialState?.bookingRateInfo?.find(
      (item, index) => index === parseInt(e.target.name.split(".")[1])
    );
    let value = e.target.value;
    // console.log()
    setFieldValue(
      `bookingRateInfo.${index}.selectCommonAdult`,
      e.target.value === "" ? 0 : e.target.value
    );

    if (String(e.target.value)?.includes("E")) {
      value =
        data?.selectDefaultChild === 0
          ? data?.defaultAdultArr?.length
          : data?.defaultAdultArr?.length - data?.selectDefaultChild;
      handleExtraAdult(
        { target: { value: +e.target.value?.split("E")[1] } },
        setFieldValue,
        index,
        data
      );
      // console.log("If", 4138);
    } else {
      handleExtraAdult({ target: { value: 0 } }, setFieldValue, index, data);
      // handleDefaultChild(e, setFieldValue, index, data);

      // console.log("else", value, 4138);
    }

    if (value !== 0) {
      const defaultPerson = [];
      for (let i = value; i < data?.defaultAdultArr?.length; i++) {
        defaultPerson.push(i);
      }
      data1.defaultChild = defaultPerson;
      data1.selectDefaultAdult = value;
      setFieldValue(`bookingRateInfo.${index}.selectDefaultAdult`, value);
      setFieldValue(`bookingRateInfo.${index}.defaultChild`, defaultPerson);
    } else {
      const defaultPerson = [];
      for (let i = 0; i < data.min_Occupancy; i++) {
        defaultPerson.push(i);
      }
      data1.defaultChild = defaultPerson;
      data1.selectDefaultAdult = value;
      setFieldValue(`bookingRateInfo.${index}.selectDefaultAdult`, 0);
      setFieldValue(`bookingRateInfo.${index}.defaultChild`, defaultPerson);
    }
  };


  const handleDefaultChild = (e, setFieldValue, index, data) => {
    const data1 = bookRoomInitialState?.bookingRateInfo?.find(
      (item, index) => index === parseInt(e.target.name.split(".")[1])
    );
    let value = e.target.value;
    setFieldValue(
      `bookingRateInfo.${index}.selectCommonChild`,
      e.target.value === "" ? 0 : e.target.value
    );
    // console.log(e.target.value, 4171);

    if (String(e.target.value)?.includes("E")) {
      value =
        data?.selectDefaultAdult === 0
          ? data?.defaultChildArr?.length
          : data?.defaultChildArr?.length - data?.selectDefaultAdult;
      handleExtraChild(
        { target: { value: +e.target.value?.split("E")[1] } },
        setFieldValue,
        index,
        data
      );
    } else {
      handleExtraChild({ target: { value: 0 } }, setFieldValue, index, data);
    }
    if (value !== 0) {
      const defaultPerson = [];
      for (let i = value; i < data?.defaultChildArr?.length; i++) {
        defaultPerson.push(i);
      }
      data1.defaultAdult = defaultPerson;
      data1.selectDefaultChild = value;
      setFieldValue(`bookingRateInfo.${index}.selectDefaultChild`, value);
      setFieldValue(`bookingRateInfo.${index}.defaultAdult`, defaultPerson);
    } else {
      const defaultPerson = [];
      for (let i = 0; i < data.min_Occupancy; i++) {
        defaultPerson.push(i);
      }
      data1.defaultAdult = defaultPerson;
      data1.selectDefaultChild = value;
      setFieldValue(`bookingRateInfo.${index}.selectDefaultChild`, 0);
      setFieldValue(`bookingRateInfo.${index}.defaultAdult`, defaultPerson);
    }
  };

  return (
    <div>
      {createPermission?.hasPermission !== null ? (
        <div className="main-layout-background h-auto w-100">
          {(loading || calcuLoading || bookingLoading) && <Loader />}
          <div className=" pl-2 pr-2 pb-2 h-auto">
            {/* <DialogContent> */}
            <div style={{ textAlign: "center" }}>
              <Typography
                variant="h4"
                style={{
                  fontSize: "20px",
                  lineHeight: "30px",
                  fontWeight: "600 !important",
                  padding: "20px",
                }}
              >
                {walkin ? t("Walk In") : t("New Booking")}
              </Typography>
            </div>
            <div className="tab-block">
              <Box
                className="mt-1"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label={t("Book a room")} value={BookingTabs.BookRoom} />
                  <Tab label={t("Add info")} value={BookingTabs.AddInfo} />
                  <Tab label={t("Confirm")} value={BookingTabs.Confirm} />
                </Tabs>
              </Box>
              <div className="tab-content">
                {/* Book a room */}

                <BookARoom
                  tabValue={tabValue}
                  bookingType={bookingType}
                  handleChangeBookingType={handleChangeBookingType}
                  walkin={walkin}
                  searchFilters={searchFilters}
                  handleCheckInDate={handleCheckInDate}
                  handleCheckOutDate={handleCheckOutDate}
                  handleGuestChange={handleGuestChange}
                  bookingDetailsStats={bookingDetailsStats}
                  handleChangeBooking={handleChangeBooking}
                  handleClearBookings={handleClearBookings}
                  bookRoomInitialState={bookRoomInitialState}
                  bookRoomHandleSubmit={bookRoomHandleSubmit}
                  handleRoomRateDropdownChange={handleRoomRateDropdownChange}
                  handleMaleOption={handleMaleOption}
                  addOnList={addOnList}
                  handleDiscountRate={handleDiscountRate}
                  discountedRate={discountedRate}
                  handledefaultSelectedRoom={handledefaultSelectedRoom}
                  handleExtraAdult={handleExtraAdult}
                  handleDefaultAdult={handleDefaultAdult}
                  handleExtraChild={handleExtraChild}
                  handleDefaultChild={handleDefaultChild}
                  handleAddRoomItem={handleAddRoomItem}
                  roomAvailableData={roomAvailableData}
                  handlePreviousTabChange={handlePreviousTabChange}
                  handleNextTabChange={handleNextTabChange}
                  // setCalcuLoading = {setCalcuLoading}
                  categoryList={categoryList}
                  roomRateId={roomRateId}
                  handleChangeRoomRate={handleChangeRoomRate}
                  bookingRates={bookingRates}
                  bookingDetails = {bookingDetails}
                />

                {/* add Info */}

                <AddInfo
                  tabValue={tabValue}
                  bookingDetailsStats={bookingDetailsStats}
                  handleChangeBooking={handleChangeBooking}
                  handleClearBookings={handleClearBookings}
                  bookingType={bookingType}
                  addGuestInitialState={addGuestInitialState}
                  addGuestHandleSubmit={addGuestHandleSubmit}
                  searchGroupResult={searchGroupResult}
                  selectedGroup={selectedGroup}
                  onSearchInputChangeGroup={onSearchInputChangeGroup}
                  handaleGroupinfo={handaleGroupinfo}
                  companiesList={companiesList}
                  guestInfo={guestInfo}
                  handleChangeCompani={handleChangeCompani}
                  searchUserResult={searchUserResult}
                  selectedGuest={selectedGuest}
                  onSearchInputChange={onSearchInputChange}
                  handleUserSelected={handleUserSelected}
                  setGuestInfo={setGuestInfo}
                  handleTaxSelected={handleTaxSelected}
                  handleTaxSelectedNew = {handleTaxSelectedNew}
                  searchTaxResult={searchTaxResult}
                  onSearchInputChangeTaxt={onSearchInputChangeTaxt}
                  handleRemoveAddress={handleRemoveAddress}
                  setPlace={setPlace}
                  idProofTypes={idProofTypes}
                  setIataNumber={setIataNumber}
                  // setTax_exempt_num={setTax_exempt_num}
                  handlePreviousTabChange={handlePreviousTabChange}
                  handleNextTabChange={handleNextTabChange}
                  
                />

                {/* confirm */}
                <Confirm
                  tabValue={tabValue}
                  bookingDetailsStats={bookingDetailsStats}
                  bookingDetailsInitialState={bookingDetailsInitialState}
                  handleClearBookings={handleClearBookings}
                  bookRoomHandleSubmit={bookRoomHandleSubmit}
                  handleRoomNumber={handleRoomNumber}
                  handleRemoveRoomItem={handleRemoveRoomItem}
                  paymentType={paymentType}
                  handalepaymentType={handalepaymentType}
                  card_present={card_present}
                  handleGuaranteePolicy={handleGuaranteePolicy}
                  handaleCardPresent={handaleCardPresent}
                  guaranteePolicy={guaranteePolicy}
                  cancelPolicy={cancelPolicy}
                  handleCancelPolicy={handleCancelPolicy}
                  setCradsDetail={setCradsDetail}
                  selectedCard={selectedCard}
                  cardDetail={cardDetail}
                  card={card}
                  handeleCardChange={handeleCardChange}
                  addCardDetail={addCardDetail}
                  handlePinpadDevice={handlePinpadDevice}
                  pinpadDevicesList={pinpadDevicesList}
                  handlePreviousTabChange={handlePreviousTabChange}
                  handleNextTabChange={handleNextTabChange}
                  bookingType={bookingType}
                  searchUserResult = {searchUserResult}
                  idProofTypes={idProofTypes}
                  onSearchInputChange={onSearchInputChange}
                  onSearchInputChangeTaxt = {onSearchInputChangeTaxt}
                  searchTaxResult = {searchTaxResult}
                  handleTaxSelected = {handleTaxSelected}
                  taxRateData={taxRateData}
                  setBookingDetails={setBookingDetails}
                  setBookingDetailsStats = {setBookingDetailsStats}
                  bookingDetails = {bookingDetails}
                  exemptTaxData = {exemptTaxData}
                />
              </div>
            </div>
            <div
              style={{
                background: "#FFFFFF",
                border: "1px solid #B4ADAD",
                boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
                alignContent: "center",
                // marginBottom: "-20px",
                marginTop: "20px",
              }}
            >
              <DialogActions>
                {tabValue !== BookingTabs.BookRoom ? (
                  <Button
                    variant="outlined"
                    title="Update"
                    // type="submit"
                    onClick={() => {
                      handlePreviousTabChange(tabValue);
                    }}
                    style={{
                      width: "140px",
                      height: "57px",
                      // background: "#B4ADAD",
                    }}
                  >
                    {t("Previous")}
                  </Button>
                ) : null}

                {tabValue !== BookingTabs.Confirm ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleNextTabChange(tabValue);
                    }}
                    title="Cancel"
                    style={{ width: "140px", height: "57px" }}
                  >
                    {t("Next")}
                  </Button>
                ) : (
                  <>
                    {createPermission?.hasPermission !== null && (
                      <Button
                        variant="contained"
                        onClick={() => setOpenSendSmsEmailDialog(true)}
                        title="Cancel"
                        // disabled={loadings}
                        style={{ width: "157px", height: "57px" }}
                      >
                        {t("Create Booking")}
                      </Button>
                    )}
                  </>
                )}
              </DialogActions>
            </div>
            {/* <D */}
            {/* </DialogContent> */}
          </div>

          {/* cancel reservation */}

          <ConfirmDialog
            scroll="paper"
            maxWidth="md"
            title={t("Confirm The Action")}
            message={t("Do you want to send sms/email to the user?")}
            cancelButtonText={t("No")}
            confirmButtonText={t("Yes")}
            openDialog={openSendSmsEmailDialog}
            handleDialogCloseAction={() => createBooking(0)}
            handleDialogClose={() => setOpenSendSmsEmailDialog(false)}
            handleDialogAction={() => createBooking(1)}
          />
        </div>
      ) : (
        <div className="mt-5">
          <NotExist />
        </div>
      )}
    </div>
  );
};

export default React.memo(NewBooking);
