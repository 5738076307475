import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchRoomCategorySelectList } from "../../apis/services/RoomCategoryApiService";
import {
  createRoomRate,
  fetchAccommodationsList,
} from "../../apis/services/RoomRateService";
import {
  roomCategoryTypesFailed,
  roomCategoryTypesSuccess,
} from "../../Reducer/roomCategory";
import {
  accommodationListFailed,
  accommodationListSuccess,
} from "../../Reducer/roomRateSlice";
import ShowInputError from "../../shared/components/ShowInputError";

import { CreateRoomRates } from "../../shared/validations/AdminValidator";
import {  priceDayNames } from "../../utils/JsonData";
import { DisplayFormikState } from "../../utils/helper";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Routing } from "../../shared/constants/routing";
import FormikInput1 from "../../shared/material-ui-formik/FormikInput1";
import FormikSelect from "../../shared/material-ui-formik/FormikSelect";
import Loader from "../../layout/Loader";
import { useTranslation } from "react-i18next";
import { fetchList } from "../../apis/services/CommonApiService";
import { ApiEndPoints } from "../../apis/ApiEndPoints";
const AddRoomRate = (props) => {
  const {
    roomCategoryTypes,
    accommodationList,
    roomCategoryTypesSuccess,
    roomCategoryTypesFailed,
    accommodationListSuccess,
    accommodationListFailed,
  } = props;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(true);
  const [alignment, setAlignment] = React.useState("left");
  const [timezon, setTimezone] = React.useState("Week");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleTimeZone = (time) => {
    console.log("handleTimeZone", time);
    setTimezone(time);
  };

  const switchHandler = (event) => {
    console.log("  ", event.target.checked);
    setChecked(event.target.checked);
    if (event.target.checked) {
      setTimezone("Week");
    } else {
      setTimezone("Monthly");
    }
  };

  const getRoomCategoryTypesForSelect = useCallback(async () => {
    const result = await fetchRoomCategorySelectList();
    if (result?.status === 200) {
      roomCategoryTypesSuccess(result.data);
    } else {
      roomCategoryTypesFailed();
    }
  }, []);

  const getAccommodationList = useCallback(async () => {
    const result = await fetchAccommodationsList();
    if (result?.status === 200) {
      accommodationListSuccess(result.data);
    } else {
      accommodationListFailed();
    }
  }, []);

  useEffect(() => {
    getRoomCategoryTypesForSelect();
    getAccommodationList();
  }, [getRoomCategoryTypesForSelect, getAccommodationList]);
  // console.log(accommodationList, 1238)
  const [data, setData] = useState([]);

  // prices.${index}.room_category_id
  // roomCategoryTypes.map((item, index)
  // accommodationList.map((acm, idx)
  // item.id
  // prices.${index}.pricing.${idx}.accomodation_id
  //acm.id

  var datanew = [];

  useEffect(() => {
    priceArr();
  }, [roomCategoryTypes, accommodationList]);

  const priceArr = () => {
    let data = [];
    for (var j = 0; j < roomCategoryTypes.length; j++) {
      var price_arr = [];
      for (var i = 0; i < accommodationList.length; i++) {
        price_arr.push({
          accomodation_id: accommodationList[i]?.id,
          rate: "",
        });
      }
      data.push({
        room_category_id: roomCategoryTypes[j]?.id,
        pricing: price_arr,
      });
    }
    setData(data);
  };

  // console.log("priceArr", data,roomCategoryTypes,accommodationList, );
  const initialState = {
    rate_name: "",
    // meal_price: "",
    cancellation_policy: "",
    prices: data,
  };

  const [loading, setLoading] = React.useState(false);
  const handleSubmit = async (values) => {
    setLoading(true);
    // eslint-disable-next-line no-console
    // console.log("hhh");
    // console.log("values", values);

    let bodyFormData = {
      rate_name: values.rate_name,
      // meal_price: 0,
      cancellation_policy: values.cancellation_policy,
      prices: values.prices,
    };
    const result = await createRoomRate(bodyFormData);
    console.log("result", result);
    console.log("message", result.message);
    if (result?.status === 200) {
      setLoading(false);
      toast.success(t(result.message));
      navigate(Routing.RoomRates);
    } else if (result === undefined) {
      setLoading(false);
      toast.error(t(result.message));
    } else {
      setLoading(false);
      toast.error(t(result.message));
    }
  };

  const handleDefaultPriceForAllDayChange = (
    value,
    priceIndex,
    accomIndex,
    setFieldValue
  ) => {
    priceDayNames.map((day, ind) => {
      setFieldValue(
        `prices[${priceIndex}]['pricing'][${accomIndex}][${day}]`,
        value
      );
    });
  };

  const [cancellationPolicyOption, setCancellationPolicyOption] = useState([]);

  const getCancellationPolicyOption = async () => {
    setLoading(true);
    const result = await fetchList(ApiEndPoints.GET_CANCELLATION_POLICY_SELECT);
    if (result?.status === 200) {
      setCancellationPolicyOption(result?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCancellationPolicyOption();
  }, []);

  return (
  
      <div className="main-layout-background h-auto pl-2 pr-2 pb-2">
        {loading && <Loader />}
        <div
          className="d-flex mt-2rem mb-2 pb-2"
          style={{ borderBottom: "2px solid", alignItems: "baseline" }}
        >
          <h1 className="pagename-heading ml-0">{t("Create room rate")}</h1>
          <p className="">
            {t("Home")} / {t("Room rates")} / {t("Create room rate")}
          </p>
        </div>
        <Formik
          initialValues={initialState}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
          validationSchema={()=>CreateRoomRates(t)}
        >
          {(props) => {
            const { handleSubmit } = props;
            return (
            
                <form onSubmit={handleSubmit} noValidate>
                  <div
                    className="mb-2"
                    style={{
                      width: "96%",
                      border: "1px solid gray",
                      // minHeight: "200px",
                      marginLeft: "25px",
                      marginTop: "10px",
                      // marginBottom: "10px",
                      // padding: "25px",
                    }}
                  >
                    <h6 style={{ paddingLeft: "25px", marginTop: "10px" }}>
                      {t("GENERAL RATE INFO")}
                    </h6>
                    <div className="mx-2 py-2">
                      <Grid container spacing={2}>
                        <Grid item lg={4}>
                          <Field
                            label={t("Name")}
                            placeHolder="Enter Name"
                            name="rate_name"
                            component={FormikInput1}
                          />
                          <ErrorMessage
                            name="rate_name"
                            component={ShowInputError}
                          />
                        </Grid>
                        {/* <Grid item lg={4}>
                          <Field
                            label={t("Meal price")}
                            placeHolder="Enter Meal price"
                            name="meal_price"
                            type="number"
                            component={FormikInput1}
                          />
                          <ErrorMessage
                            name="meal_price"
                            component={ShowInputError}
                          />
                        </Grid> */}
                        <Grid item lg={4}>
                          <Typography
                          id="font-600"
                            style={{
                              marginBottom: "11px",
                              color: "rgb(30, 30, 30)",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            {t("Cancellation")}
                          </Typography>
                          <Field
                            label={t("Cancellation")}
                            name="cancellation_policy"
                            options={cancellationPolicyOption?.map((data) => ({
                              title: data.name,
                              value: data.id,
                            }))}
                            component={FormikSelect}
                          />
                          <ErrorMessage
                            name="cancellation_policy"
                            component={ShowInputError}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div
                    className="mb-2"
                    style={{
                      width: "96%",
                      border: "1px solid gray",
                      minHeight: "200px",
                      marginLeft: "25px",
                      marginTop: "10px",
                    }}
                  >
                    <h6
                      className="mb-1"
                      style={{ paddingLeft: "25px", marginTop: "10px" }}
                    >
                      {t("ROOMS TO BE SOLD AT THIS RATE")}
                    </h6>
                    {/* <div className="ml-3">
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>Monthly</Typography>
                        <Switch checked={checked} onChange={switchHandler} />
                        <Typography>Weekly</Typography>
                      </Stack>
                    </div> */}

                    <div className="d-flex pl-2 pt-2 pr-2 pb-2">
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell id="font-600" className="text-center">
                                {t("Room Type")}
                              </TableCell>
                              <TableCell id="font-600" className="text-center">
                                {t("Accommodation")}
                              </TableCell>
                              <TableCell id="font-600" className="text-center">
                                {t("Default price for all days")}
                              </TableCell>
                              {/* <TableCell className="text-center">
                                {t("Monday")}
                              </TableCell>
                              <TableCell className="text-center">
                                {t("Tuesday")}
                              </TableCell>
                              <TableCell className="text-center">
                                {t("Wednesday")}
                              </TableCell>
                              <TableCell className="text-center">
                                {t("Thursday")}
                              </TableCell>
                              <TableCell className="text-center">
                                {t("Friday")}
                              </TableCell>
                              <TableCell className="text-center">
                                {t("Saturday")}
                              </TableCell>
                              <TableCell className="text-center">
                                {t("Sunday")}
                              </TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {roomCategoryTypes.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  {accommodationList.map((acm, idx) => {
                                    return (
                                      <FieldArray name="prices" key={idx}>
                                        {({ push, remove }) => (
                                          <TableRow>
                                            {idx == 0 && (
                                              <TableCell
                                                className="text-center"
                                                rowSpan={
                                                  accommodationList.length
                                                }
                                              >
                                                {/* <Checkbox />{" "}
                                                  {item.roomcategory_name} */}
                                                <label>
                                                  {/* <Field
                                                      className="solo-check"
                                                      name={`prices.${index}.room_category_id`}
                                                      type="checkbox"
                                                      value={item.id}
                                                      onChange={(event) => {
                                                        const {
                                                          checked,
                                                          name,
                                                          value,
                                                        } = event.target;
                                                        if (checked) {
                                                          props.setFieldValue(
                                                            `prices.${index}.room_category_id`,
                                                            value
                                                          );
                                                        } else {
                                                          props.setFieldValue(
                                                            `prices.${index}.room_category_id`,
                                                            0
                                                          );
                                                        }
                                                        console.log(props.values, 374)
                                                      }}
                                                      label={
                                                        item.roomcategory_name
                                                      }
                                                      component={Checkbox}
                                                    /> */}
                                                  {item.roomcategory_name}
                                                </label>
                                                <ErrorMessage
                                                  name={`prices.${index}.room_category_id`}
                                                  component={ShowInputError}
                                                />
                                              </TableCell>
                                            )}
                                            <TableCell className="text-center">
                                              {/* <Checkbox /> */}

                                              <label>
                                                {/* <Field
                                                    className="solo-check"
                                                    name={`prices.${index}.pricing.${idx}.accomodation_id`}
                                                    type="checkbox"
                                                    value={acm.id}
                                                    label={acm.name}
                                                    onChange={(event) => {
                                                      const {
                                                        checked,
                                                        name,
                                                        value,
                                                      } = event.target;
                                                      if (checked) {
                                                        props.setFieldValue(
                                                          `prices.${index}.pricing.${idx}.accomodation_id`,
                                                          value
                                                        );
                                                      } else {
                                                        props.setFieldValue(
                                                          `prices.${index}.pricing.${idx}.accomodation_id`,
                                                          0
                                                        );
                                                      }
                                                    }}
                                                    component={Checkbox}
                                                  /> */}
                                                {acm.name}
                                              </label>
                                              <ErrorMessage
                                                name={`prices.${index}.pricing.${idx}.accomodation_id`}
                                                component={ShowInputError}
                                              />
                                            </TableCell>
                                            {/* <TableCell className="text-center">
                                                <Grid container spacing={3}>
                                                  <Grid item xs={12}>
                                                    <Field
                                                      type="number"
                                                      name={`prices.${index}.pricing.${idx}.default_price_for_all_days`}
                                                      onBlur={async (e) => {
                                                        handleDefaultPriceForAllDayChange(
                                                          e.target.value,
                                                          index,
                                                          idx,
                                                          props.setFieldValue
                                                        );
                                                      }}
                                                      component={FormikInput1}
                                                    />
                                                    <ErrorMessage
                                                      name={`prices.${index}.pricing.${idx}.default_price_for_all_days`}
                                                      component={ShowInputError}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell> */}
                                            <TableCell className="text-center">
                                              <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                  <Field
                                                    type="number"
                                                    style={{
                                                      minWidth: "100px",
                                                    }}
                                                    name={`prices.${index}.pricing.${idx}.rate`}
                                                    component={FormikInput1}
                                                  />
                                                  <ErrorMessage
                                                    name={`prices.${index}.pricing.${idx}.rate`}
                                                    component={ShowInputError}
                                                  />
                                                </Grid>
                                              </Grid>
                                            </TableCell>
                                            {/* <TableCell className="text-center">
                                                <Grid container spacing={3}>
                                                  <Grid item xs={12}>
                                                    <Field
                                                      type="number"
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                      name={`prices.${index}.pricing.${idx}.tuesday_price`}
                                                      component={FormikInput1}
                                                    />
                                                    <ErrorMessage
                                                      name={`prices.${index}.pricing.${idx}.tuesday_price`}
                                                      component={ShowInputError}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                              <TableCell className="text-center">
                                                <Grid container spacing={3}>
                                                  <Grid item xs={12}>
                                                    <Field
                                                      type="number"
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                      name={`prices.${index}.pricing.${idx}.wednesday_price`}
                                                      component={FormikInput1}
                                                    />
                                                    <ErrorMessage
                                                      name={`prices.${index}.pricing.${idx}.wednesday_price`}
                                                      component={ShowInputError}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                              <TableCell className="text-center">
                                                <Grid container spacing={3}>
                                                  <Grid item xs={12}>
                                                    <Field
                                                      type="number"
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                      id={idx}
                                                      name={`prices.${index}.pricing.${idx}.thursday_price`}
                                                      component={FormikInput1}
                                                    />
                                                    <ErrorMessage
                                                      name={`prices.${index}.pricing.${idx}.thursday_price`}
                                                      component={ShowInputError}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                              <TableCell className="text-center">
                                                <Grid container spacing={3}>
                                                  <Grid item xs={12}>
                                                    <Field
                                                      type="number"
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                      id={idx}
                                                      name={`prices.${index}.pricing.${idx}.friday_price`}
                                                      component={FormikInput1}
                                                    />
                                                    <ErrorMessage
                                                      name={`prices.${index}.pricing.${idx}.friday_price`}
                                                      component={ShowInputError}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                              <TableCell className="text-center">
                                                <Grid container spacing={3}>
                                                  <Grid item xs={12}>
                                                    <Field
                                                      type="number"
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                      name={`prices.${index}.pricing.${idx}.saturday_price`}
                                                      component={FormikInput1}
                                                    />
                                                    <ErrorMessage
                                                      name={`prices.${index}.pricing.${idx}.saturday_price`}
                                                      component={ShowInputError}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell>
                                              <TableCell className="text-center">
                                                <Grid container spacing={3}>
                                                  <Grid item xs={12}>
                                                    <Field
                                                      type="number"
                                                      style={{
                                                        minWidth: "100px",
                                                      }}
                                                      name={`prices.${index}.pricing.${idx}.sunday_price`}
                                                      component={FormikInput1}
                                                    />
                                                    <ErrorMessage
                                                      name={`prices.${index}.pricing.${idx}.sunday_price`}
                                                      component={ShowInputError}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </TableCell> */}
                                          </TableRow>
                                        )}
                                      </FieldArray>
                                    );
                                  })}
                                </React.Fragment>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>

                    <div
                      className="d-flex"
                      style={{
                        width: "96%",
                        minHeight: "70px",
                        marginLeft: "25px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          padding: "0px",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                        className="d-flex"
                      >
                        <Button
                          className="mt-1 comman-button"
                          size="large"
                          variant="contained"
                          disableElevation
                          type="submit"
                          color="primary"
                          // style={{
                          //   justifyContent: "center",
                          //   width: "98px",
                          //   height: "44px",
                          //   textTransform: "capitalize",
                          //   background:
                          //     "linear-gradient(180deg, #255480 0%, #173450 100%)",
                          // }}
                        >
                          {t("Confirm")}
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* <DisplayFormikState {...props} /> */}
                </form>
              
            );
          }}
        </Formik>
      </div>
    
  );
};

export const mapStateToProps = (state, ownProps) => {
  return {
    roomCategoryTypes: state.roomCategory.roomCategoryTypes,
    accommodationList: state.roomRate.accommodationList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    roomCategoryTypesSuccess: (data) => {
      dispatch(roomCategoryTypesSuccess(data));
    },
    roomCategoryTypesFailed: () => dispatch(roomCategoryTypesFailed()),
    accommodationListSuccess: (data) => {
      dispatch(accommodationListSuccess(data));
    },
    accommodationListFailed: () => dispatch(accommodationListFailed()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRoomRate);
