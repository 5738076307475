import {
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  Autocomplete,
  TextField,
  Checkbox,
  Paper,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import React, { useState } from "react";
import { BookingTabs } from "../../shared/enums/new-booking";
import FormikSelect from "../../shared/material-ui-formik/FormikSelect";
import { ErrorMessage, Field, Formik } from "formik";
import FormikInput1 from "../../shared/material-ui-formik/FormikInput1";
import ShowInputError from "../../shared/components/ShowInputError";
import { AddBookingGuestInfoValidator } from "../../shared/validations/AdminValidator";
import { Routing } from "../../shared/constants/routing";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress } from "react-places-autocomplete";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }} className="pb-0">
          {children}
        </Box>
      )}
    </div>
  );
}

export const AddInfo = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let {
    tabValue,
    bookingDetailsStats,
    handleChangeBooking,
    handleClearBookings,
    bookingType,
    addGuestInitialState,
    addGuestHandleSubmit,
    searchGroupResult,
    selectedGroup,
    onSearchInputChangeGroup,
    handaleGroupinfo,
    companiesList,
    guestInfo,
    handleChangeCompani,
    searchUserResult,
    selectedGuest,
    onSearchInputChange,
    handleUserSelected,
    setGuestInfo,
    handleTaxSelected,
    handleTaxSelectedNew,
    searchTaxResult,
    onSearchInputChangeTaxt,
    // handleRemoveAddress,
    // setPlace,
    idProofTypes,
    setIataNumber,
    // setTax_exempt_num,
    noTaxExempt,
    handlePreviousTabChange,
    handleNextTabChange,
  } = props;

  const [address, setAddress] = useState("");

  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const handlePostalSearch = async (e) => {
    setGuestInfo((prevState) => ({
      ...prevState,
      postal_code: e.target.value,
    }));
    if (e.target.value?.length < 4) {
      return;
    } else {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            e.target.value
          )}&key=${process.env.REACT_APP_GOOGLE_PLACES_KEY}`
        );

        const data = await response.json();

        if (data.results && data.results.length > 0) {
          const result = data.results[0];

          // Extract city, state, and country from address components
          result.address_components.forEach((component) => {
            if (component.types.includes("locality")) {
              setGuestInfo((prevState) => ({
                ...prevState,
                city_id: component.long_name,
              }));
              // setCity(component.long_name);
            } else if (
              component.types.includes("administrative_area_level_1")
            ) {
              setGuestInfo((prevState) => ({
                ...prevState,
                state_id: component.long_name,
              }));
              // setState(component.long_name);
            } else if (component.types.includes("country")) {
              setGuestInfo((prevState) => ({
                ...prevState,
                country_id: component.long_name,
              }));
              // setCountry(component.long_name);
            }
          });
        }
      } catch (error) {
        console.error("Error fetching geocoding data:", error);
      }
    }
  };

  const handleSelectAddress = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      // const latLng = await getLatLng(results[0]);

      setAddress(selectedAddress);

      // Extract city, state, country, and postal code from address components
      results[0].address_components.forEach((component) => {
        // console.log(component, 171);

        if (component.types.includes("locality")) {
          setGuestInfo((prevState) => ({
            ...prevState,
            city_id: component.long_name,
          }));
        } else if (component.types.includes("administrative_area_level_1")) {
          setGuestInfo((prevState) => ({
            ...prevState,
            state_id: component.long_name,
          }));
        } else if (component.types.includes("country")) {
          setGuestInfo((prevState) => ({
            ...prevState,
            country_id: component.long_name,
          }));
        } else if (component.types.includes("postal_code")) {
          setGuestInfo((prevState) => ({
            ...prevState,
            postal_code: component.long_name,
          }));
        }
      });
      setGuestInfo((prevState) => ({
        ...prevState,
        address: selectedAddress,
      }));
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  return (
 
      <TabPanel value={tabValue} index={BookingTabs.AddInfo}>
        {/* <AddInfo /> */}
        <Grid container spacing={2} alignItems="end" sx={{position:"sticky", top:!noTaxExempt?"61px":'-15px', zIndex:'1111'}}>
          <Grid item md={12} sm={12} xs ={12}>
            <div
              style={{
                display: "flex",
                // background: "#38e078",
                background: "#382DAE",
                border: "1px solid #F2F2F2",
                borderRadius: "6px",
                // width: "97%",
                height: "auto",
                justifyContent: "space-between",
                alignItems: "center",
                // color: "#FFFFFF",
                padding: "10px 10px",
              }}
            >
              {tabValue !== BookingTabs.BookRoom ? <ArrowBackIosIcon
              onClick={()=>handlePreviousTabChange(tabValue)}
               style={{cursor:  "pointer",
                      backgroundColor:"#ffffff",
                      color:"#382DAE",
                      padding: "5px 0px 5px 10px",
                      borderRadius: "6px",
                      boxSizing:"content-box"

              }}
               />: <div></div>}
              <h1
                style={{
                  color: "#ffffff",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "unset"
                }}
              >
                {t("Booking details")}: {bookingDetailsStats.noOfRooms}{" "}
                {t("room(s)")} | {bookingDetailsStats.noOfAdults}{" "}
                {t("adult(s)")}, {bookingDetailsStats.noOfChild}{" "}
                {t("child(ren)")} | $
                {Number(bookingDetailsStats.finslTotal).toFixed(2)} |{" "}
                {Number(bookingDetailsStats.noOfRooms) > 0 && (
                  <>
                    <span
                      onClick={handleChangeBooking}
                      style={{
                        color: "#2499D1",
                        cursor: "pointer",
                      }}
                    >
                      {t("Change")}
                    </span>{" "}
                    |{" "}
                    <span
                      onClick={handleClearBookings}
                      style={{
                        color: "#DD4B39",
                        cursor: "pointer",
                      }}
                    >
                      {t("Clear")}
                    </span>
                  </>
                )}
              </h1>
              {tabValue !== BookingTabs.Confirm ? <ArrowForwardIosIcon
                onClick = {()=>handleNextTabChange(tabValue)}
               style={{cursor: "pointer", 
                      backgroundColor:"#ffffff",
                      color:"#382DAE",
                      padding:"5px",
                      borderRadius: "6px",
                      boxSizing:"content-box"
                    }}
                />:<div></div>}
            </div>
          </Grid>
        </Grid>
        <div className="p-2">
          <h6 className="mb-1">
            {/* {bookingType === 2 ? t("PRIMARY GROUP") : t("PRIMARY GUEST")} */}
            {t("PRIMARY GUEST")}
          </h6>
          <Formik
            initialValues={addGuestInitialState}
            onSubmit={addGuestHandleSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
            validationSchema={() => AddBookingGuestInfoValidator(t)}
          >
            {(props) => {
              const { handleSubmit } = props;
              return (
                
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2} alignItems="flex-start">
                      {bookingType === 3 ? (
                      // {bookingType === 2 ? (
                        <>
                          {searchGroupResult && (
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <FormControl fullWidth>
                                <Autocomplete
                                  id="combo-box-demo"
                                  options={searchGroupResult}
                                  value={selectedGroup}
                                  onInputChange={onSearchInputChangeGroup}
                                  onChange={(event, value) =>
                                    handaleGroupinfo(value)
                                  }
                                  getOptionLabel={(option) =>
                                    option?.group_name  || ''
                                  }
                                  renderOption={(props, option) => (
                                    // Use a unique key but don't display option.id
                                    <li {...props} key={option?.id}>
                                      {option?.group_name}
                                    </li>
                                  )}
                                  PaperComponent={CustomPaper}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t("Search by group name")}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item lg={3} md={6} sm={12} xs={12}>
                            <Typography
                              style={{
                                marginBottom: "11px",
                                color: "rgb(30, 30, 30)",
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              {t("Company Profile Name")}
                            </Typography>
                            <FormControl fullWidth>
                              <Autocomplete
                                id="combo-box-demo"
                                options={companiesList}
                                value={guestInfo.company_profile_name}
                                onChange={(e, v) =>
                                  handleChangeCompani(e, v, props.setFieldValue)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t("Search by Compani name")}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item lg={3} md={6} sm={12} xs={12}>
                            <Button
                              variant="contained"
                              onClick={() => {
                                navigate(Routing.NewCompanyProfile);
                              }}
                              title="Cancel"
                              style={{
                                width: "147px",
                                height: "60px",
                                marginTop: "32px",
                                marginLeft: "5px",
                              }}
                            >
                              {t("Add Company")}
                            </Button>
                          </Grid>
                          <Grid item lg={6} md={12} sm={12} xs={12}>
                            <Field
                              label={t("Corporete ID")}
                              name="corporateID"
                              placeHolder={t("Corporete ID")}
                              maxLength={150}
                              readOnly
                              component={FormikInput1}
                            />

                            <ErrorMessage
                              name="corporateID"
                              component={ShowInputError}
                            />
                          </Grid>
                        </>
                      ) : searchUserResult ? (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <FormControl fullWidth>
                            <Autocomplete
                              id="combo-box-demo"
                              options={searchUserResult}
                              value={selectedGuest}
                              onInputChange={onSearchInputChange}
                              // multiple
                              onChange={(event, value) =>
                                handleUserSelected(value)
                              }
                              getOptionLabel={(option) => (option?.name || "") }
                              renderOption={(props, option) => (
                                // Use a unique key but don't display option.id
                                <li {...props} key={option.id}>
                              {option.name + " - " + (option?.email || "") + " - " + (option?.mobile || "")}

                                </li>
                              )}
                              PaperComponent={CustomPaper}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("Search by name, email, mobile")}
                                  variant="outlined"
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      ) : (
                        <></>
                      )}
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Field
                          label={t("Full Name")}
                          name="name"
                          placeHolder={t("Firstname Lastname")}
                          maxLength={150}
                          style={{ width: "100%" }}
                          onBlur={async (e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              name: e.target.value,
                            }));
                          }}
                          component={FormikInput1}
                        />
                        <ErrorMessage name="name" component={ShowInputError} />
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={3}
                        sm={12}
                        xs={12}
                        style={{ top: "-3px" }}
                      >
                        <Typography
                        id="font-600"
                          style={{
                            marginBottom: "11px",
                            color: "rgb(30, 30, 30)",
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                        >
                          {t("Phone")}
                        </Typography>
                        <PhoneInput
                          country={"us"}
                          enableSearch={true}
                          value={guestInfo.phone_code}
                          onChange={(phone) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              phone_code: `+${phone}`,
                            }));
                          }}
                          specialLabel=""
                          inputStyle={{
                            width: "100%",
                            border: "1px solid #B4ADAD !important",
                            borderRadius: "12px !important",
                            height: "50px",
                          }}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} md={3} sm={12} xs={12}>
                        <Field
                          style={{ width: "100%",  }}
                          className={"mt-md-2"}
                          name="phone"
                          type="number"
                          placeHolder={t("Phone")}
                          maxLength={150}
                          onBlur={async (e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              phone: e.target.value,
                            }));
                          }}
                          component={FormikInput1}
                        />
                        <ErrorMessage name="phone" component={ShowInputError} />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Field
                          label={t("Email")}
                          type="email"
                          name="email"
                          placeHolder={t("Email")}
                          onBlur={async (e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              email: e.target.value,
                            }));
                          }}
                          maxLength={150}
                          component={FormikInput1}
                        />
                        <ErrorMessage name="email" component={ShowInputError} />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Field
                          label={t("Id Number")}
                          name="id_number"
                          placeHolder="KC 00000"
                          maxLength={150}
                          onBlur={async (e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              id_number: e.target.value,
                            }));
                          }}
                          component={FormikInput1}
                        />
                        <ErrorMessage
                          name="id_number"
                          component={ShowInputError}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography
                        id="font-600"
                          style={{
                            marginBottom: "11px",
                            color: "rgb(30, 30, 30)",
                            fontSize: "16px",
                            lineHeight: "24px",

                          }}
                        >
                          {t("ID Proof Type")}
                        </Typography>
                        <Field
                          label={t("ID Proof Type")}
                          name="id_proof_type"
                          defaultOption
                          options={idProofTypes?.map((type) => ({
                            title: t(type.value),
                            value: type.id,
                          }))}
                          onChange={async (e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              id_proof_type: e.target.value,
                            }));
                          }}
                          component={FormikSelect}
                        />
                        <ErrorMessage
                          name="id_proof_type"
                          component={ShowInputError}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Field
                          label={t("IATA")}
                          name="iata_number"
                          placeHolder="IATA"
                          maxLength={150}
                          onBlur={async (e) => {
                            setIataNumber(e.target.value);
                          }}
                          component={FormikInput1}
                        />
                        <ErrorMessage name="iata" component={ShowInputError} />
                      </Grid>
                      {!noTaxExempt && (
                        <Grid
                          item
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          style={{ marginTop: "30px" }}
                        >
                          <Field
                            label={t("Tax Exempt")}
                            name="tax_exempt"
                            id="tax_exempt"
                            type="checkbox"
                            onChange={async (e) => {
                              // setTax_exempt_num(0);
                              const { checked } = e.target;

                              if (checked) {
                                setGuestInfo((prevState) => ({
                                  ...prevState,
                                  tax_exempt: 1,
                                }));
                                props.setFieldValue(`tax_exempt`, 1);
                              } else {
                                setGuestInfo((prevState) => ({
                                  ...prevState,
                                  tax_exempt: 0,
                                }));
                                props.setFieldValue(`tax_exempt`, 0);
                                // handleTaxSelected([]);
                                handleTaxSelectedNew([]);
                                setGuestInfo((prevState) => ({
                                  ...prevState,
                                  tax_id_data: [],
                                }));
                              }
                            }}
                            checked={
                              props.values.tax_exempt === 1 ? true : false
                            }
                            component={Checkbox}
                          />
                          {t("Tax Exempt")}
                        </Grid>
                      )}
                      {guestInfo.tax_exempt === 1 && (
                        <>
                          <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Field
                              label={t("Tax Exempt code")}
                              name="tax_exempt_num"
                              placeHolder="Tax Exempt Code"
                              // maxLength={150}
                              onBlur={async (e) => {
                                setGuestInfo((prevState) => ({
                                  ...prevState,
                                  tax_exempt_num: e.target.value,
                                }));
                              }}
                              component={FormikInput1}
                              readOnly
                            />
                            <ErrorMessage
                              name="tax_exempt_num"
                              component={ShowInputError}
                            />
                          </Grid>
                          <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography
                            id="font-600"
                              style={{
                                marginBottom: "11px",
                                color: "rgb(30, 30, 30)",
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              {t("Tax ID")}
                            </Typography>
                            <FormControl fullWidth>
                              <Autocomplete
                                id="combo-box-demo"
                                size="small"
                                sx={{ padding: "3px" }}
                                // name= "tax_id"
                                options={searchTaxResult.filter(
                                  (item) => item?.is_exempt_tax === 1
                                )}
                                onClick={onSearchInputChangeTaxt}
                                multiple={true}
                                // readOnly={tax_exempt_num === 123456 ? false : true}
                                onChange={(event, value) => {
                                  handleTaxSelectedNew(value);
                                  setGuestInfo((prevState) => ({
                                    ...prevState,
                                    tax_id_data: value,
                                  }));
                                  // console.log(1791,'v', value)
                                }}
                                value={guestInfo?.tax_id_data  || []}
                                getOptionLabel={(option) => option?.name || ''}
                                PaperComponent={CustomPaper}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t("Exempt Tax")}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </FormControl>

                            <ErrorMessage
                              name="Tax Id"
                              component={ShowInputError}
                            />
                          </Grid>
                          {/* } */}
                        </>
                      )}
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            // marginRight: "-90px",
                            borderTop: "2px solid",
                          }}
                        ></div>
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Typography
                        id="font-600"
                          style={{
                            marginBottom: "11px",
                            color: "rgb(30, 30, 30)",
                            fontSize: "16px",
                            lineHeight: "24px",
                            fontWeight: 500,
                          }}
                        >
                          {t("Search By Google")}
                        </Typography>
                        <PlacesAutocomplete
                          value={address}
                          onChange={setAddress}
                          onSelect={handleSelectAddress}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: t("Search Places ..."),
                                  className: "location-search-input",
                                })}
                                style={{
                                  padding: '14px',
                                  border: "1px solid #B4ADAD",
                                  color: '#255480',
                                  borderRadius: '6px',
                                  width: '100%',
                                  position: 'relative'
                                }}
                              />
                              <div
                                className="autocomplete-dropdown-container"
                                style={{
                                  position: "absolute",
                                  outline: "none",
                                  zIndex: "1",
                                  listStyle: "none",
                                  padding: "0",
                                  margin: "10px 0 10px -1px",
                                  boxShadow: "1px 2px 18px rgba(0,0,0,0.25)",
                                  backgroundColor: "white",
                                  width: "100%",
                                  maxHeight: "220px",
                                  overflowY: "scroll",
                                  borderRadius: "7px",
                                }}
                              >
                                {loading && (
                                  <div
                                    style={{
                                      padding: "5px",
                                      borderBottom: "1px solid #fafafa",
                                    }}
                                  >
                                    {t("Loading...")}
                                  </div>
                                )}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                        padding: "5px",
                                        borderBottom: "1px solid #fafafa",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                        padding: "5px",
                                        borderBottom: "1px solid #fafafa",
                                      };
                                  return (
                                    <div
                                      key={suggestion.placeId}
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        {/* <Typography
                          style={{
                            marginBottom: "11px",
                            color: "rgb(30, 30, 30)",
                            fontSize: "16px",
                            lineHeight: "24px",
                            fontWeight: 500,
                          }}
                        >
                          {t("Address")}
                        </Typography> */}
                        <Field
                          label={t("Address")}
                          placeHolder={t("Enter Address")}
                          name="address"
                          onChange={(e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              address: e.target.value,
                            }));
                          }}
                          id="address"
                          component={FormikInput1}
                        />
                        {/* <AutocompleteGoogle
                          apiKey={process.env.REACT_APP_GOOGLE_PLACES_KEY}
                          style={{
                            width: "93%",
                            border: "1px solid #B4ADAD",
                            borderRadius: "6px",
                            height: "30px",
                            padding: "10px",
                          }}
                          defaultValue={guestInfo?.address}
                          onChange={(e) =>
                            handleRemoveAddress(e, props.setFieldValue)
                          }
                          onPlaceSelected={(place) => {
                            setPlace(place, props.setFieldValue);
                          }}
                          options={{
                            types: ["(regions)"],
                          }}
                        /> */}
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Field
                          label={t("Country")}
                          placeHolder={t("Enter Country")}
                          name="country_id"
                          onChange={(e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              country_id: e.target.value,
                            }));
                          }}
                          id="country_id"
                          component={FormikInput1}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Field
                          label={t("State")}
                          placeHolder={t("Enter State")}
                          name="state_id"
                          id="state_id"
                          onChange={(e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              state_id: e.target.value,
                            }));
                          }}
                          component={FormikInput1}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Field
                          label={t("City")}
                          placeHolder={t("Enter City")}
                          name="city_id"
                          onChange={(e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              city_id: e.target.value,
                            }));
                          }}
                          id="city_id"
                          component={FormikInput1}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Field
                          label={t("Postal Code")}
                          placeHolder={t("Enter Postal Code")}
                          name="postal_code"
                          // onChange={(e) => {
                          //   setGuestInfo((prevState) => ({
                          //     ...prevState,
                          //     postal_code: e.target.value,
                          //   }));
                          // }}
                          onChange={(e) => handlePostalSearch(e)}
                          id="postal_code"
                          component={FormikInput1}
                        />
                      </Grid>
                    </Grid>
                  </form>
                
              );
            }}
          </Formik>
        </div>
      </TabPanel>
    
  );
};

// import { Button, Checkbox, Grid, InputLabel, TextField } from "@mui/material";
// import { ErrorMessage, Field, Formik } from "formik";
// import React from "react";
// import { toast } from "react-toastify";
// import ShowInputError from "../../shared/components/ShowInputError";
// import FormikInput1 from "../../shared/material-ui-formik/FormikInput1";
// import FormikSelect from "../../shared/material-ui-formik/FormikSelect";
// import { AddBookingGuestInfoValidator } from "../../shared/validations/AdminValidator";

// const AddInfo = () => {
//   const initialState = {
//     first_name: "",
//     last_name: "",
//     phone: "",
//     email: "",
//     passport_no: "",
//     passport_from: "",
//     passport_expiry: "",
//     booking_comment: "",
//   };
//   const handleSubmit = async (values) => {
//     // eslint-disable-next-line no-console
//     console.log("hhh");
//     console.log("values", values);
//     let bodyFormData = {
//       first_name: values.first_name,
//       last_name: values.last_name,
//       phone: values.phone,
//       email: values.email,
//       passport_no: values.passport_no,
//       passport_from: values.passport_from,
//       passport_expiry: values.passport_expiry,
//       booking_comment: values.booking_comment,
//     };
//     console.log("bodyFormData", bodyFormData);
//     // const result = await createSingleRooms(bodyFormData);
//     // console.log("result", result);
//     // console.log("message", result.message);
//     // if (result?.status === 200) {
//     //   toast.success(result.message);
//     // } else {
//     //   toast.error(result.message);
//     // }
//   };
//   return (
//     <div className="p-2">
//       <h6 className="mb-1">PRIMARY GUEST</h6>
//       <Formik
//         initialValues={initialState}
//         onSubmit={handleSubmit}
//         validateOnBlur={false}
//         validateOnChange={true}
//         enableReinitialize={true}
//         validationSchema={AddBookingGuestInfoValidator}
//       >
//         {(props) => {
//           const { handleSubmit } = props;
//           return (
//             <>
//               <form onSubmit={handleSubmit} noValidate>
//                 <Grid container spacing={2} alignItems="flex-start">
//                   <Grid item lg={12}>
//                     <Field
//                       label="Search Name"
//                       name="search_text"
//                       placeHolder="Search by guest, email, room or reservation ID"
//                       maxLength={150}
//                       component={FormikInput1}
//                     />
//                   </Grid>

//                   <Grid item lg={4}>
//                     <Field
//                       label="First Name"
//                       name="first_name"
//                       placeHolder="First name"
//                       maxLength={150}
//                       component={FormikInput1}
//                     />
//                     <ErrorMessage
//                       name="first_name"
//                       component={ShowInputError}
//                     />
//                   </Grid>
//                   <Grid item lg={4}>
//                     <Field
//                       label="Last Name"
//                       name="last_name"
//                       placeHolder="Last name"
//                       maxLength={150}
//                       component={FormikInput1}
//                     />
//                     <ErrorMessage name="last_name" component={ShowInputError} />
//                   </Grid>
//                   <Grid item lg={4}>
//                     <Field
//                       label="Phone"
//                       name="phone"
//                       placeHolder="Phone"
//                       maxLength={150}
//                       component={FormikInput1}
//                     />
//                     <ErrorMessage name="phone" component={ShowInputError} />
//                   </Grid>
//                   <Grid item lg={4}>
//                     <Field
//                       label="Email"
//                       type="email"
//                       name="email"
//                       placeHolder="Email"
//                       maxLength={150}
//                       component={FormikInput1}
//                     />
//                     <ErrorMessage name="email" component={ShowInputError} />
//                   </Grid>
//                   <Grid item lg={4}>
//                     <Field
//                       label="Passport No"
//                       name="passport_no"
//                       placeHolder="KC 00000"
//                       maxLength={150}
//                       component={FormikInput1}
//                     />
//                     <ErrorMessage
//                       name="passport_no"
//                       component={ShowInputError}
//                     />
//                   </Grid>
//                   <Grid item lg={4}>
//                     <Field
//                       label="Passport from"
//                       type="date"
//                       name="passport_from"
//                       placeHolder="25-01-2023"
//                       maxLength={150}
//                       component={FormikInput1}
//                     />
//                     <ErrorMessage
//                       name="passport_from"
//                       component={ShowInputError}
//                     />
//                   </Grid>
//                   <Grid item lg={4}>
//                     <Field
//                       label="Passport Expiry"
//                       type="date"
//                       name="passport_expiry"
//                       placeHolder="25-01-2023"
//                       maxLength={150}
//                       component={FormikInput1}
//                     />
//                     <ErrorMessage
//                       name="passport_expiry"
//                       component={ShowInputError}
//                     />
//                   </Grid>
//                   <Grid item lg={8}>
//                     <Field
//                       label="Booking Comment"
//                       name="booking_comment"
//                       placeHolder="Booking Comment"
//                       maxLength={150}
//                       component={FormikInput1}
//                     />
//                     <ErrorMessage
//                       name="booking_comment"
//                       component={ShowInputError}
//                     />
//                   </Grid>
//                 </Grid>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "end",
//                     marginRight: "20px",
//                     marginBottom: "20px",
//                     borderTop: "2px solid",
//                     marginTop: "20px",
//                   }}
//                 >
//                   <Grid item xs={2}>
//                     <Button
//                       className="mt-1"
//                       size="large"
//                       variant="contained"
//                       disableElevation
//                       type="submit"
//                       color="primary"
//                       style={{
//                         justifyContent: "center",
//                         width: "98px",
//                         height: "44px",
//                         textTransform: "capitalize",
//                         background:
//                           "linear-gradient(180deg, #255480 0%, #173450 100%)",
//                       }}
//                     >
//                       Save
//                     </Button>
//                   </Grid>
//                 </div>
//               </form>
//             </>
//           );
//         }}
//       </Formik>
//     </div>
//   );
// };

// export default AddInfo;
